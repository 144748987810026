.all-super-admin-transfer-out-request-parent {
  position: relative;
  height: 100%;
}
.all-super-admin-transfer-out-request-parent * {
  font-family: var(--montserrat);
}
.all-super-admin-transfer-out-request-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.all-super-admin-transfer-out-request-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.all-super-admin-transfer-out-request-ticket-search {
  width: 750px;
  height: 50px;
  background: #ffffffb3 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.all-super-admin-transfer-out-request-options-btn {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.all-super-admin-transfer-out-request-wrapper {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.all-super-admin-transfer-out-request-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
}
.all-super-admin-transfer-out-request-all-active,
.all-super-admin-transfer-out-request-my-active {
  border-radius: 5px;
  background-color: white;
}
.all-super-admin-transfer-out-request-all-active {
  width: 100%;
  margin-top: 40px;
}
.all-super-admin-transfer-out-request-my-active {
  width: 42.5%;
  margin-left: 30px;
}
.all-super-admin-transfer-out-request-top input {
  width: 180px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #51595e;
  font-size: 14px;
  font-family: var(--lato);
  align-self: center;
  padding-left: 5px;
}
.all-super-admin-transfer-out-request-top {
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  border-bottom: 1px solid #e1e1e1;
}
.all-super-admin-transfer-out-request-top > div {
  display: flex;
  align-items: center;
}
.all-super-admin-transfer-out-request-top select {
  margin-left: 5px;
  width: 100px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #51595e;
  font-size: 14px;
  font-family: var(--lato);
}

/* head row */
.all-super-admin-transfer-out-request-row-wrapper {
  display: flex;
  background: #446fa2;
  color: white;
  margin: 0 22px;
}
.all-super-admin-transfer-out-request-row-wrapper
  .all-super-admin-transfer-out-request-head-row:first-child {
  width: 11%;
}
.all-super-admin-transfer-out-request-row-wrapper
  .all-super-admin-transfer-out-request-head-row {
  width: 11%;
  text-align: center;
  font-size: 14px;
  border-right: solid 0.5px #e1e1e1;
  border-left: solid 0.5px #e1e1e1;
  padding: 2.5px 0;
}
/* content row */
.all-super-admin-transfer-out-request-bottom {
  overflow-y: scroll;
  height: 500px;
  cursor: pointer;
}
.all-super-admin-transfer-out-request-content-wrapper {
  display: flex;
  margin: 0 22px;
}
.all-super-admin-transfer-out-request-content-wrapper
  .all-super-admin-transfer-out-request-content-row:first-child {
  width: 11%;
}
.all-super-admin-transfer-out-request-content-wrapper
  .all-super-admin-transfer-out-request-content-row {
  width: 11%;
  font-size: 14px;
  border-bottom: solid 1px #e1e1e1;
  padding: 10px 5px;
  color: #51595e;
  font-family: var(--lato);
  display: flex;
  text-transform: capitalize;
  justify-content: center;
  text-align: center;
}
.all-super-admin-transfer-out-request-content-wrapper
  .all-super-admin-transfer-out-request-content-row
  img {
  width: 20px;
  height: 18px;
  margin-right: 5px;
}
.all-super-admin-transfer-out-request-content-row label {
  padding: 2px 10px;
  border-radius: 6px;
  color: white;
  font-size: 13.7px;
  height: max-content;
}
.all-super-admin-transfer-out-request-msg {
  text-align: center;
  margin-top: 15px;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .all-super-admin-transfer-out-request-main {
    width: 97%;
    justify-content: flex-end;
  }
  .all-super-admin-transfer-out-request-row-wrapper,
  .all-super-admin-transfer-out-request-content-wrapper {
    margin: 0 5px;
  }
}

@media screen and (max-width: 1400px) {
  .all-super-admin-transfer-out-request-row-wrapper
    .all-super-admin-transfer-out-request-head-row,
  .all-super-admin-transfer-out-request-content-wrapper
    .all-super-admin-transfer-out-request-content-row {
    font-size: 12px;
  }
  .all-super-admin-transfer-out-request-content-row label {
    font-size: 11.7px;
  }
  .all-super-admin-transfer-out-request-wrapper {
    width: 88%;
  }
}
@media screen and (max-width: 1250px) {
  .all-super-admin-transfer-out-request-wrapper {
    flex-direction: column;
    margin-left: 10%;
  }
  .all-super-admin-transfer-out-request-all-active {
    width: 100%;
    margin-bottom: 15px;
  }
  .all-super-admin-transfer-out-request-my-active {
    width: 100%;
    margin: unset;
  }
  .all-super-admin-transfer-out-request-bottom {
    max-height: 350px;
    min-height: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .all-super-admin-transfer-out-request-main {
    justify-content: flex-end;
    width: 100%;
  }
  .all-super-admin-transfer-out-request-content-row.all-super-admin-transfer-out-request-date-row
    svg {
    top: 8px;
  }
  .all-super-admin-transfer-out-request-all-active {
    overflow-x: scroll;
  }
  .all-super-admin-transfer-out-request-top,
  .all-super-admin-transfer-out-request-row-wrapper,
  .all-super-admin-transfer-out-request-bottom {
    width: 940px;
  }
}

/* ipad air  */
@media screen and (max-width: 920px) {
  .all-super-admin-transfer-out-request-wrapper {
    width: 80%;
    margin-left: 15%;
  }
}
/* ipad air  */

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 700px) {
}

@media screen and (max-width: 600px) {
  .all-super-admin-transfer-out-request-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .all-super-admin-transfer-out-request-wrapper {
    width: 95%;
    margin: 0 auto;
  }
  .all-super-admin-transfer-out-request-heading {
    font-size: 25px;
  }
  .all-super-admin-transfer-out-request-bottom {
    max-height: 450px;
  }
}
@media screen and (max-width: 505px) {
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
