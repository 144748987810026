.rename-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 90%);
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  .rename-popup {
    width: 40%;
    margin: 0 auto;
    position: relative;
  }
  .rename-popup form {
    display: flex;
    flex-direction: column;
  }
  .rename-cross {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
    cursor: pointer;
    /* background: #446fa2; */
    padding: 5px 10px;
    color: black;
    border-radius: 5px;
  }
  .rename-popup span {
    display: block;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .rename-email {
    margin-bottom: 25px;
  }
  
  @media screen and (max-width: 1024px) {
    .rename-popup { 
      width: 60%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .rename-popup {
      width: 65%;
    }
  }
  
  @media screen and (max-width: 426px) {
    .rename-popup {
      width: 90%;
    }
  }
  