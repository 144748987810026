.email-popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.email-popup-popup {
  width: 40%;
  margin: 0 auto;
  position: relative;
  border: solid 1px;
  padding: 15px;
  background-color: white;
}
.email-popup-popup form {
  display: flex;
  flex-direction: column;
}
.email-together {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.email-popup-cross {
  font-weight: bold;
  cursor: pointer;
  /* background: #446fa2; */
  padding: 5px 10px;
  color: black;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
}
.email-popup-popup span.title {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}
form.email-popup-organization button {
  margin-top: 15px;
  width: fit-content;
  cursor: pointer;
}
form.email-popup-organization label span {
  display: inline-block;
  width: 85px;
  padding: 5px 0;
  text-align: center;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 2.5px;
  margin-right: 5px;
}
.email-popup-popup form label {
  display: flex;
  margin-bottom: 10px;
}
.email-popup-popup form input,
.email-popup-popup form textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(224, 224, 224);
  outline: none;
  font-family: var(--montserrat);
}
.email-popup-popup form .subject,
.email-popup-popup form .email-text {
  margin-bottom: 10px;
}
.email-popup-popup form .subject input {
  padding: 7.5px 2.5px;
}
.email-popup-popup form textarea {
  height: 200px;
  max-height: 200px;
  resize: none;
}
.email-popup-container button {
  border: unset;
  background: #446fa2 !important;
}
@media screen and (max-width: 1024px) {
  .email-popup-popup {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .email-popup-popup {
    width: 65%;
  }
}

@media screen and (max-width: 426px) {
  .email-popup-popup {
    width: 90%;
  }
}
