.file-view-documents {
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.pdf-viewer-container {
  display: flex;
  width: 100% !important;
  height: 100% !important;
  justify-content: center;
}

.react-pdf__Page {
  all: unset !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.pdf-canvas {
  display: flex !important;
  justify-content: center;
}

.pdf-canvas canvas {
  height: 100%;
}

.react-grid-HeaderCell {
  pointer-events: none;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Document {
  max-width: 100%;
}

.react-pdf__Page__annotations {
  height: 10px !important;
  pointer-events: none;
}
.empty-container {
  width: 100%;
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 28px;
}

@media screen and (max-width: 820px) {
  .pdf-canvas canvas {
    width: 100%;
  }
  .pg-viewer {
    width: 100% !important;
  }
  .document-container {
    width: 100% !important;
  }
}
