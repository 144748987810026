.completion-main {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.completion-main h1 {
  font-size: 40px;
}
