.mc-management-admin-wrapper {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
}
.mc-management-admin-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
}
.mc-management-admin-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  cursor: pointer;
}
.mc-management-admin-upper-tile-container {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}
.mc-management-admin-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 30px;
  margin-top: 30px;
}
.mc-management-admin-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.mc-management-admin-tile > span {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.mc-management-admin-tile span {
  color: #446fa2;
  font-weight: bold;
}
.mc-management-admin-tile span b {
  font-size: 22px;
}
.mc-management-admin-tile-header {
  display: flex;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 1080px) {
  .mc-management-admin-wrapper {
    margin-left: 15%;
  }
}

@media screen and (max-width: 730px) {
  .mc-management-admin-content {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 15px;
    margin-top: 15px;
    padding-bottom: 90px;
  }
  .mc-management-admin-tile {
    width: 100%;
  }
  .mc-management-admin-body {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .mc-management-admin-wrapper {
    margin-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin-top: 20px;
  }
  .mc-management-admin-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .mc-management-admin-heading {
    width: 80%;
    margin-left: 10%;
  }
  .mc-management-admin-tile {
    width: 80%;
    justify-self: center;
  }
  .mc-management-admin-upper-tile-container {
    margin-top: 25px;
  }
  .mc-management-admin-tile img {
    width: 70px !important;
    height: 70px !important;
  }
  .mc-management-admin-tile {
    height: 100px !important;
  }
  .mc-management-admin-heading {
    font-size: 25px;
  }
  .mc-management-admin-tile span {
    font-size: 14px;
  }
  .mc-management-admin-tile .moneyCreditManagement-info {
    width: 20px !important;
    height: 20px !important;
  }
}

@media screen and (max-width: 450px) {
}

@media screen and (max-width: 380px) {
  .mc-management-admin-heading {
    width: 90%;
    margin-left: 5%;
  }
  .mc-management-admin-tile {
    width: 90%;
  }
  .mc-management-admin-body {
    width: 90%;
  }
}
