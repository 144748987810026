.contact-support-heading h1 {
  color: #707070;
  text-transform: capitalize;
  font-size: 30px;
  text-align: center;
  margin-top: 50px;
}
.contact-support-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.contact-support-content {
  margin: 0 auto;
  width: 65%;
  background: white;
  border-radius: 5px;
  margin-top: 25px;
  text-align: center;
}
.contact-support-content-text-wrapper {
  display: flex;
  justify-content: center;
}
.contact-support-content-text-wrapper > div {
  width: 30%;
}
.contact-support-content-text-wrapper > div h4 {
  color: #4671a4;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;
}
.contact-support-content-text-wrapper > div p {
  color: #4671a4;
  font-size: 18px;
  margin-top: unset;
}
.contact-support-content-from-heading h3 {
  font-size: 20px;
  color: #4671a4;
  text-transform: uppercase;
}
.contact-support-content-from-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.contact-support-content-from-content input {
  width: 25%;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px 5px;
  color: #51595e;
  font-size: 15px;
  font-family: var(--montserrat);
}
.contact-support-content-from-content textarea {
  width: 87.5%;
  margin-top: 25px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px 5px;
  color: #51595e;
  font-size: 15px;
  font-family: var(--montserrat);
  height: 100px;
  resize: none;
}
.contact-support-content-from {
  padding-bottom: 50px;
}
.contact-support-content-from-button {
  margin-top: 25px;
}
.contact-support-content-from-button button {
  background-color: #5ca127;
  width: 174px;
}

@media screen and (max-width: 1024px) {
  .contact-support-heading h1 {
    font-size: 25px;
    width: 80%;
    margin: 0 auto;
    margin-top: 25px;
    margin-left: 15%;
  }
  .contact-support-content * {
    font-size: 16px !important;
  }
  .contact-support-content {
    width: 84%;
    margin-left: 15%;
  }
}

@media screen and (max-width: 600px) {
  .contact-support-heading h1 {
    font-size: 22px;
  }
  .contact-support-heading h1 {
    margin: 15px auto;
  }
  .contact-support-content {
    width: 95%;
    margin: 15px auto;
  }
  .contact-support-content-text-wrapper {
    flex-wrap: wrap;
  }
  .contact-support-content-text-wrapper > div {
    width: 100%;
  }
  .contact-support-content-from-content input {
    width: 87%;
    margin-top: 15px;
  }
  .contact-support-content * {
    font-size: 15px !important;
  }
  .contact-support-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .contact-support-main {
    padding-bottom: 100px;
  }
}
