.editAppointment-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 7;
  overflow-y: scroll;
}
.editAppointment-parent * {
  font-family: var(--montserrat);
}
.editAppointment-main {
  width: 100%;
  margin: 0 auto;
  padding: 5% 0;
}
.editAppointment-form-body {
  max-width: 800px;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
}
.editAppointment-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  border-bottom: solid 1px #e1e1e1;
}
.editAppointment-heading {
  position: relative;
}
.editAppointment-heading .rename-cross {
  top: 5%;
  right: 1%;
}
/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .editAppointment-form-body {
    max-width: 750px;
  }
}
/* ipad air  */

@media screen and (max-width: 740px) {
}
@media screen and (max-width: 600px) {
  .editAppointment-main {
    width: 100%;
    padding: 15% 0;
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
