.timezone-wrapper {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
select.timezone-selector {
  width: 50%;
  padding: 15px 5px;
  background: #f5f5f5;
  border: solid 2px #d1d1d1;
  border-radius: 5px;
  color: black;
  font-size: 16px;
  font-family: var(--lato);
}
.timezone-container {
  position: relative;
  margin-top: 10px;
}
.timezone-container label {
  font-size: 16px;
  font-family: var(--montserrat);
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  background: #f5f5f5;
  left: 2.5%;
}
.timezone-wrapper:hover,
select.timezone-selector:hover {
  outline: red;
}
