.stcreate-parent {
  position: relative;
  height: 100%;
}
.stcreate-parent * {
  font-family: var(--montserrat);
}
.stcreate-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.stcreate-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.stcreate-form-body {
  max-width: 800px;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
}
.stcreate-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  border-bottom: solid 1px #e1e1e1;
  margin-top: unset;
}
.stcreate-wrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 25px;
}
.stcreate-fields {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.stcreate-fields > div {
  width: 100%;
  position: relative;
}
.stcreate-fields .organization-list {
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  cursor: pointer;
  z-index: 1;
}
.stcreate-fields > div.hide-dropdown .organization-list {
  display: none;
}
.stcreate-fields .organization-list .data-list > div {
  flex: 1;
}
.stcreate-fields .organization-list .data-list {
  background: white;
}
.stcreate-fields select {
  width: 100%;
  height: 40px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  outline: none;
}
.stcreate-fields.stcreate-file-upload input {
  border: unset;
  height: fit-content;
}
.stcreate-fields:first-child input {
  color: #446fa2;
  font-weight: bold;
}
.stcreate-fields label {
  font-size: 14px;
  color: #51595e;
  font-weight: bold;
  flex-basis: 40%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.stcreate-fields input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
}
.stcreate-fields textarea {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  resize: none;
}
.stcreate-fields label span {
  color: #ff0000;
}
.stcreate-fields button {
  border: unset;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  padding: 10px 50px;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
.stcreate-fields .organization-list.folder-list span.multiple-line-text {
  margin-left: 10px;
}
.stcreate-fields .organization-list.folder-list img {
  border: unset;
}
.stcreate-fields .organization-list.folder-list span {
  font-weight: unset;
  font-size: 13px;
  margin-left: unset;
}
.stcreate-fields .organization-list.folder-list i {
  font-size: 10px;
  color: #7f8c8d;
}
.stcreate-fields .organization-img-parent {
  position: relative;
}
.ticket-files
{
  border: 1px solid #e1e1e1 !important;
  border-radius: 5px;
  width: 100%;
  height: 40px !important;
  padding: 0 10px;
  background-color: rgb(241, 242, 246) !important;
}
/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .stcreate-main {
    width: 97%;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
  .stcreate-main {
    justify-content: flex-end;
    width: 100%;
  }
  .stcreate-form-body {
    margin: unset;
    margin-right: 25px;
  }
  .stcreate-fields label {
    /* font-size: 12px; */
    white-space: unset;
  }
  .stcreate-main {
    display: flex;
    justify-content: flex-end;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .stcreate-form-body {
    max-width: 700px;
    margin-right: 50px;
  }
}
/* ipad air  */
@media screen and (max-width: 850px) {
  .stcreate-form-body {
    margin-right: 25px;
  }
}

@media screen and (max-width: 768px) {
  .stcreate-fields {
    flex-direction: column;
    align-items: flex-start;
  }
  .stcreate-fields label {
    padding-left: 10px;
  }
  .stcreate-form-body {
    max-width: 550px;
  }
}
@media screen and (max-width: 660px) {
  .stcreate-form-body {
    max-width: 500px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 600px) {
  .stcreate-header {
    height: 65px;
  }
  .stcreate-main {
    width: 100%;
    justify-content: center;
    margin-top: 0;
    height: unset;
    overflow-y: scroll;
  }
  .stcreate-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  form.stcreate-form-body {
    max-width: 500px;
    margin: unset;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
