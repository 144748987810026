/* Style the tab */
.tab {
  display: flex;
  flex-wrap: wrap;
}

/* Style the buttons inside the tab */
.tab .tablinks {
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  font-weight: bold;
  font-family: var(--montserrat);
  white-space: nowrap;
  position: relative;
}
.tablinks {
  background: #eeeeee;
  border: 1px solid #ececec;
  margin-right: 2.5px;
}
.tablinks > img {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
}
.category-dropdown > button.tablinks-button {
  background: #eeeeee;
}
.category-dropdown > button.locationlinks-button {
  background: #eeeeee;
}
.tab button {
  color: #51595e;
  border: unset;
  cursor: pointer;
  padding: 7.5px 25px;
  display: flex;
  align-items: center;
  background-color: transparent;
}
.category-dropdown button.tablinks-button {
  height: 100%;
  width: 100%;
}
.category-dropdown button.locationlinks-button {
  height: 100%;
  width: 100%;
}
.category-dropdown {
  position: relative;
}
.category-dropdown-list {
  position: absolute;
  z-index: 1;
  height: 185px;
  overflow-y: scroll;
  min-width: 170px;
}
.dropdown-arrow-btn.hide-dropdown .category-dropdown-list {
  display: none;
}
.MuiBox-root.css-ydmrf2 {
  position: absolute;
}
.tablinks button img {
  margin-right: 7px;
  width: 16px;
}
.tablinks .dropdown-menu {
  top: 10px;
  right: 20px;
}
.tablinks .dropdown-menu ul {
  padding: unset;
  margin: unset;
}

.tablinks .dropdown-menu ul li {
  border: solid 1px #606060;
  padding: 7.5px 10px;
  width: 135px;
  font-weight: normal;
  text-align: left;
}
.tablinks .dropdown-menu ul li:hover {
  background-color: #cecece;
  color: white;
}
.dropdown-menu.hide-dropdown {
  display: none;
}

/* Change background color of buttons on hover */
.tab .tablinks:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab .tablinks-button.active {
  background-color: #fff;
}

.tab .locationlinks-button.active {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  .category-dropdown-list {
    right: -2px;
  }
}

/* width */
/* ::-webkit-scrollbar {
  height: 7px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #ede6e6;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #e5d8d8;
} */
