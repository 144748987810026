.trash-file-container {
  position: relative;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  padding: 5px 0;
  height: fit-content;
}
button.trash-file-options,
button.trash-folder-options {
  position: absolute;
  top: 2.5%;
  right: 2.5%;
}
.trash-file-text {
  font-family: var(--montserrat);
  font-size: var(--primary-text);
  font-weight: bold;
  width: 100%;
}
.trash-file-icon {
  flex: 0 0 15%;
}
.trash-folder-single {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  padding: 5px 0;
  cursor: pointer;
  height: fit-content;
  width: 100%;
}
.trash-file-container > div,
.trash-folder-single > div {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.trash-file-container > div p,
.trash-folder-single > div p {
  font-family: var(--lato);
  color: #51595e;
  font-weight: bold;
  font-size: var(--primary-heading);
  margin-left: 10px;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trash-folder-single > div img {
  width: 75px;
}
.trash-file-icon img {
  height: 45px;
  object-fit: contain;
}

/* responsive  */
@media screen and (max-width: 1600px) {
  .trash-file-icon {
    flex: 0 0 10%;
  }
}

@media screen and (max-width: 1200px) {
  .trash-folder-single > div p {
    font-size: var(--primary-text);
  }
}
@media screen and (max-width: 850px) {
}
@media screen and (max-width: 600px) {
}
