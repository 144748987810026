.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 90%);
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.popup-popup {
  width: 40%;
  margin: 0 auto;
  position: relative;
}
.popup-popup form {
  display: flex;
  flex-direction: column;
}
.popup-cross {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  cursor: pointer;
  /* background: #446fa2; */
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
}
.popup-popup span {
  display: block;
  margin-bottom: 10px;
  margin-top: 25px;
}
.popup-email {
  margin-bottom: 25px;
}
.popup-email span {
  all: unset !important;
  background-color: white !important;
}

@media screen and (max-width: 1024px) {
  .popup-popup {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .popup-popup {
    width: 65%;
  }
}

@media screen and (max-width: 426px) {
  .popup-popup {
    width: 90%;
  }
}
