.mentions {
  /* margin: 1em 0; */
  margin: unset;
}
.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}
.mentions--multiLine .mentions__control {
  font-family: var(--montserrat);
  font-size: 13px;
  height: 100%;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  /* padding: unset; */
  /* min-height: 63px; */
  overflow-y: scroll !important;
  height: 50px !important;
}
.mentions--multiLine .mentions__input {
  /* border: 1px solid silver; */
  padding: 9px;
  /* padding: unset; */
  outline: 0;
  overflow-y: scroll !important;
  height: 50px !important;
}
.example_mentions__control__Kol3a + div {
  z-index: 100 !important;
}
.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 13px;
  height: 100px !important;
  overflow-y: scroll !important;
  display: flex;
  flex-direction: column !important;
}
.mentions__mention {
  position: relative;
  z-index: 1;
  color: #bf0000;
  font-family: var(--montserrat);
  font-weight: bold !important;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  /* text-decoration: underline; */
  pointer-events: none;
}

.mentions__suggestions__item {
  min-width: 100% !important;
  margin: 0 !important;
  padding: 5px 15px !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgb(0 0 0 / 0.15) !important;
  font-size: 13px !important;
  background-color: transparent !important;
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5 !important;
}

/* responsive */

@media screen and (max-width: 600px) {
  .mentions--multiLine .mentions__highlighter {
    height: 30px !important;
  }
  .mentions--multiLine .mentions__input {
    height: 30px !important;
  }
}
