.edit-workbook-parent {
  position: relative;
  height: 100%;
}
.edit-workbook-parent * {
  font-family: var(--montserrat);
}
.edit-workbook-main-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.edit-workbook-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}
.edit-workbook-main input[type="checkbox"] {
  cursor: pointer;
}
.edit-workbook-wrapper {
  width: 90%;
  max-width: 1195px;
  display: grid;
  gap: 30px;
  grid-template-columns: 40% 60%;
}
.edit-workbook-header {
  padding: 30px;
  text-align: center;
  grid-column: 1 / 3;
}
.edit-workbook-header-name-button {
  all: unset;
  color: #4671a4;
  text-decoration: underline;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-workbook-header > span {
  display: inline-block;
  margin-bottom: 20px;
}
.edit-workbook-header,
.edit-workbook-content-left,
.edit-workbook-content-right {
  border-radius: 5px;
  background-color: #fff;
}
.edit-workbook-content-left,
.edit-workbook-content-right {
  height: 512px;
  overflow: hidden;
}
.edit-workbook-header span {
  font-size: 30px;
  color: #707070;
  font-weight: normal;
  margin-top: 0;
}
.edit-workbook-header-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.edit-workbook-header-actions button {
  width: 289px;
  height: 39px;
  text-transform: capitalize;
  white-space: nowrap;
  box-shadow: none;
  font: normal normal 600 14px/16px var(--montserrat);
}
.edit-workbook-header-actions button:first-child {
  background-color: #446fa2;
  width: 177px;
  white-space: nowrap;
}
.edit-workbook-header-actions button:nth-child(4):not([disabled]) {
  background-color: #5ca127;
  color: #fff;
  width: 94px;
}
.edit-workbook-header-actions button:nth-child(4):is([disabled]) {
  background-color: #e6e6e6;
  color: #929292;
  width: 94px;
}
.edit-workbook-search-filter {
  width: 90%;
  max-width: 451px;
  position: relative;
  margin: auto;
  border-radius: 5px;
  margin-block: 13px;
  border: 1px solid #d5d5d5;
}
.edit-workbook-search-filter input {
  border: none;
  width: 100%;
  height: 31px;
  padding: 8px;
  border-radius: 5px;
  font: italic normal normal 12px/14px var(--lato);
}
.edit-workbook-search-filter svg {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #51595e;
}
.edit-workbook-divider-line {
  width: 100%;
  height: 2px;
  background-color: var(--border-color);
}
.edit-workbook-org {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 15px;
}
.edit-workbook-org > img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: block;
  object-fit: contain;
}
.edit-workbook-org-title span {
  font: normal normal bold 16px/19px var(--lato);
  letter-spacing: 0px;
  color: #51595e;
  text-transform: capitalize;
  line-height: 0;
}
.edit-workbook-org-label {
  display: flex;
  align-items: center;
  gap: 4px;
  font: normal normal normal 14px/19px var(--lato);
  letter-spacing: 0px;
  color: #647589;
  text-transform: capitalize;
}
.edit-workbook-org-label button {
  font: normal normal normal 14px/19px var(--lato);
  background-color: transparent;
  border: none;
  color: #647589;
  text-transform: capitalize;
}
.edit-workbook-org-label img {
  width: 25px;
  height: 25px;
}
.edit-workbook-org-folder-listings {
  padding: 10px 4px;
  overflow-y: scroll;
  height: 70%;
}
.edit-workbook-org-folder {
  display: flex;
  gap: 15px;
  padding: 0 13px;
  align-items: center;
  min-height: 82px;
}
.edit-workbook-org-folder span {
  font: normal normal bold 16px/19px var(--lato);
  cursor: pointer;
  color: #51595e;
}
.edit-workbook-org-visit-btn {
  width: 117px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #599b26;
  border-radius: 16px 5px 5px 16px;
  text-align: center;
  font: normal normal 600 11px/19px var(--montserrat);
  letter-spacing: 0px;
  color: #5ca127;
  text-transform: uppercase;
  display: flex;
  column-gap: 15px;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin-left: auto;
}
.loading-content-visit-button {
  width: 117px;
  height: 31px;
  border-radius: 16px 5px 5px 16px;
}
.edit-workbook-org-visit-btn img {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  display: block;
  object-fit: contain;
}
.edit-workbook-org-folder > img {
  width: 80px;
  height: 80px;
}
.edit-workbook-content-right > h2 {
  padding: 15px;
  margin: 0;
  font: normal normal bold 16px/14px var(--lato);
  color: #51595e;
}
.edit-workbook-internal-folder-path {
  background-color: #f5f5f5;
  font: normal normal normal 15px/21px var(--lato);
  letter-spacing: 0px;
  color: #51595e;
  padding: 5px 15px;
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  overflow: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.edit-workbook-internal-folder-path::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.edit-workbook-internal-folder-path button {
  border: none;
  padding: 0;
  color: #51595e;
  font: normal normal normal 15px/21px var(--lato);
  background-color: transparent;
  text-transform: capitalize;
}
.edit-workbook-folder-grid {
  display: grid;
  gap: 10px;
  padding: 15px;
  grid-template-columns: repeat(auto-fill, minmax(308px, 1fr));
  grid-template-rows: 83px;
  overflow-y: auto;
  height: 84%;
  align-content: flex-start;
}
.edit-workbook-folder-grid input {
  height: 20px;
  width: 20px;
  margin-right: -5px;
}
.edit-workbook-folder-grid button {
  display: flex;
  min-height: 82px;
  gap: 10px;
  color: #446fa2;
  border-radius: 5px;
  align-items: center;
  letter-spacing: 0px;
  cursor: pointer;
  border: 1px solid #4671a4;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal 600 18px/22px var(--montserrat);
}
.disabled {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.selected {
  background-color: #f5f5f5;
}
.edit-workbook-checked {
  border: 2px solid #5ca127 !important;
}
.edit-workbook-folder-grid button img {
  width: 80px;
  height: 80px;
}
.edit-workbook-no-data {
  text-align: center;
  font-size: 18px;
  width: 200%;
}
.edit-workbook-internal-folder-path button {
  all: unset;
  font-weight: bold;
  color: #51595e;
}
.edit-workbook-internal-folder-path button:last-child {
  font-weight: normal;
  pointer-events: none;
}
.edit-workbook-internal-folder-path button:not(:last-child):hover {
  cursor: pointer;
  color: #2ba5f2;
}
.edit-workbook-file-image {
  height: 65px !important;
  width: 65px !important;
  margin: 5px 7.5px;
  object-fit: contain;
}
.edit-workbook-table-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto 0 auto;
  display: flex;
  justify-content: center;
}
.edit-workbook-table-all-active {
  border-radius: 5px;
  width: 65%;
  background-color: white;
}
.edit-workbook-table-top p {
  color: #51595e;
  font-size: 18px;
  font-weight: bold;
}
.edit-workbook-table-top {
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  border-bottom: 1px solid #e1e1e1;
}
.edit-workbook-table-top-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.edit-workbook-table-top-action button {
  all: unset;
  color: #446fa2;
}
.edit-workbook-table-top-action-button {
  color: #5ca127 !important;
  border: 2px solid #5ca127 !important;
  border-radius: 5px !important;
  font: normal normal 600 11px Montserrat !important;
  padding: 3px 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 5px 0 -1px !important;
}
.save {
  color: white !important;
  min-width: 50px !important;
}

.edit-workbook-table-top-action button:hover {
  cursor: pointer;
}

/* head row */
.edit-workbook-table-row-wrapper {
  display: flex;
  background: #446fa2;
  color: white;
  margin: 0 22px;
}
.edit-workbook-table-row-wrapper .edit-workbook-table-head-row:first-child {
  width: 10%;
}
.edit-workbook-table-row-wrapper .edit-workbook-table-head-row {
  width: 22.5%;
  text-align: center;
  font-size: 14px;
  border-right: solid 0.5px #e1e1e1;
  border-left: solid 0.5px #e1e1e1;
  padding: 2.5px 0;
}
/* content row */
.edit-workbook-table-bottom {
  overflow-y: scroll;
  height: 300px;
}
.edit-workbook-table-content-wrapper {
  display: flex;
  margin: 0 22px;
}
.edit-workbook-table-content-wrapper
  .edit-workbook-table-content-row:first-child {
  width: 10%;
  color: #446fa2;
  font-weight: bold;
}
.edit-workbook-table-content-wrapper .edit-workbook-table-content-row {
  width: 22.5%;
  font-size: 14px;
  border-bottom: solid 1px #e1e1e1;
  padding: 10px 7px;
  color: #51595e;
  font-family: var(--lato);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  overflow: hidden;
}
.edit-workbook-table-content-row img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 5px;
}
.edit-workbook-table-msg {
  text-align: center;
  margin-top: 15px;
}
.edit-workbook-table-content-row input {
  width: 16px;
  height: 16px;
  background-color: #5ca127 !important;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
  .edit-workbook-table-row-wrapper,
  .edit-workbook-table-content-wrapper {
    margin: 0 5px;
  }
  .edit-workbook-table-row-wrapper .edit-workbook-table-head-row,
  .edit-workbook-table-content-wrapper .edit-workbook-table-content-row {
    font-size: 12px;
  }
  .edit-workbook-wrapper {
    max-width: 1000px;
  }
  .edit-workbook-no-data {
    width: 100%;
  }
}
@media screen and (max-width: 1250px) {
  .edit-workbook-table-wrapper {
    flex-direction: column;
    width: 60%;
  }
  .edit-workbook-table-all-active {
    width: 100%;
    margin-bottom: 15px;
  }
  .edit-workbook-table-bottom {
    height: 250px;
  }
}
@media screen and (max-width: 1200px) {
  .edit-workbook-wrapper {
    max-width: 885px;
    grid-template-columns: 50% 50%;
  }
}
@media screen and (max-width: 1080px) {
  .edit-workbook-wrapper {
    max-width: 735px;
  }
  .edit-workbook-org-folder {
    gap: 7px;
  }
  button.edit-workbook-org-visit-btn {
    flex-shrink: 0;
  }
}
@media screen and (max-width: 925px) {
  .edit-workbook-wrapper {
    grid-template-columns: 100%;
  }
  .edit-workbook-header {
    grid-column: unset;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .edit-workbook-wrapper {
    max-width: 535px;
  }
  .edit-workbook-header-actions button {
    width: fit-content;
  }
  .edit-workbook-header-actions {
    flex-wrap: wrap;
  }
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .edit-workbook-table-row-wrapper .edit-workbook-table-head-row {
    font-size: 8px;
  }

  .edit-workbook-wrapper {
    max-width: 435px;
  }
}
@media screen and (max-width: 700px) {
  .edit-workbook-wrapper {
    max-width: 425px;
  }
  .edit-workbook-header {
    padding: 16px;
  }
  .edit-workbook-header span {
    font-size: 22px;
  }
}
@media screen and (max-width: 600px) {
  .edit-workbook-main {
    padding-bottom: 80px;
  }
  .edit-workbook-wrapper {
    max-width: 500px;
  }
  .edit-workbook-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .edit-workbook-table-header {
    height: 65px;
  }
  .edit-workbook-table-wrapper {
    flex-direction: column;
    width: 95%;
  }
  .edit-workbook-table-top p {
    font-size: 14px;
  }
}
@media screen and (max-width: 505px) {
  .edit-workbook-table-row-wrapper {
    width: 510px;
  }
  .edit-workbook-table-bottom {
    width: 510px;
  }
  .edit-workbook-table-all-active {
    overflow-x: scroll;
  }
  .edit-workbook-table-top p {
    font-size: 12px;
  }
  .edit-workbook-table-top {
    padding: 0 5px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
