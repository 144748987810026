.share-folder-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.share-folder-parent * {
  font-family: var(--montserrat);
}
.share-folder-main {
  width: 95%;
  max-width: 850px;
  height: 70%;
  max-height: 370px;
  background-color: white;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.share-folder-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  border-bottom: solid 1px #e1e1e1;
}
.share-folder-bookmarkbar {
  display: flex;
  width: 98%;
  background-color: #f5f5f5;
  margin: auto;
  font-size: 14px;
  font-family: var(--lato);
  padding: 5px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  align-items: center;
}
.share-folder-bookmarkbar button {
  all: unset;
  cursor: pointer;
  padding-right: 5px;
}
.share-folder-bookmarkbar span {
  color: #2ba5f2;
}
.share-folder-bookmarkbar span:hover {
  color: #51595e;
}
.share-folder-wrapper {
  max-width: 95%;
  height: 100%;
  margin: 20px auto auto;
  display: grid;
  align-content: flex-start;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  overflow: scroll;
  padding-bottom: 110px;
}
.share-folder-wrapper > button {
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7.5px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
}
.share-folder-wrapper > button p {
  font-family: var(--lato);
  color: #51595e;
  font-weight: bold;
  font-size: var(--primary-heading);
  margin-left: 10px;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.share-folder-wrapper > button img {
  width: 75px;
}
.share-folder-heading {
  position: relative;
  margin-top: -14px;
}
.share-folder-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.share-folder-button-container {
  display: flex;
  width: 95%;
  max-width: 850px;
  height: max-content;
  justify-content: center;
  background-color: white;
  padding: 10px 0 20px 0;
  border-radius: 0 0 5px 5px;
}
.share-folder-button-container button {
  font-size: 12px;
  box-shadow: none;
  border-radius: 5px;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .share-folder-heading p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
  .share-folder-wrapper > div p {
    font-size: var(--primary-text);
  }
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */
@media screen and (max-width: 600px) {
  .share-folder-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .share-folder-wrapper > button img {
    width: 62px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
