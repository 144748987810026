.requestType-popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.9);
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.requestType-popup-popup {
  width: 25%;
  margin: 0 auto;
  position: relative;
  background-color: #f5f5f5;
  padding: 25px 25px;
}
.requestType-popup-cross {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  cursor: pointer;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
}
.requestType-popup-popup span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.requestType-popup-option-button {
  display: flex;
  justify-content: space-between;
  height: 42px;
}
.requestType-confirm-box {
  padding: 20px 25px;
  background: #f5f5f5;
  border-radius: 5px;
}
div.requestType-confirm-cross {
  top: 5%;
  right: 2.5%;
}
.requestType-confirm-cross + span {
  text-align: center;
}

.submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.buttonDisable {
  background: #c6c6c6;
}

.buttonActive {
  color: white;
  background: #5ca127 !important;
}

.requestType-active {
  border: 1px solid #5ca127 !important;
}
.external-rights {
  background: #c6c6c6 !important;
}
@media screen and (max-width: 1500px) {
  .requestType-popup-popup {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .requestType-popup-popup {
    width: 65%;
  }
}
@media screen and (max-width: 600px) {
  .requestType-popup-popup {
    width: 90%;
  }
}

@media screen and (max-width: 420px) {
  .requestType-popup-popup {
    width: 95%;
    padding: 15px;
  }
}
@media screen and (max-width: 360px) {
  .requestType-popup-popup {
    padding: 5px;
    width: 98%;
  }
}
