.all-refund-request-parent {
  position: relative;
  height: 100%;
}
.all-refund-request-parent * {
  font-family: var(--montserrat);
}
.all-refund-request-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.all-refund-request-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.all-refund-request-ticket-search {
  width: 750px;
  height: 50px;
  background: #ffffffb3 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.all-refund-request-ticket-search input {
  outline: none;
  border: none;
  background: transparent;
  letter-spacing: 0px;
  color: #446fa2;
  font-size: 15px;
  font-style: italic;
  width: 100%;
}
.all-refund-request-ticket-search svg {
  color: #446fa2;
}
.all-refund-request-ticket-search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #446fa2;
}
.all-refund-request-ticket-search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #446fa2;
}
.all-refund-request-ticket-search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #446fa2;
}
/* .all-refund-request-responsive-btn {
    display: flex;
    width: 250px;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 15px;
  }
  .all-refund-request-responsive-btn button {
    cursor: pointer;
  } */

.all-refund-request-options-btn {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.all-refund-request-options-btn button {
  margin-right: 20px;
  background: #5ca127;
  border-radius: 5px;
  color: white;
  border: unset;
  padding: 10px 50px;
  cursor: pointer;
  border: solid 1px #5ca127;
}
.all-refund-request-options-btn button:hover {
  background: white;
  color: #5ca127;
}
.all-refund-request-options-btn button:last-child {
  margin-right: unset;
}
button.all-refund-request-outline-btn {
  border: 1px solid #446fa2;
  border-radius: 5px;
  background: white;
  color: #446fa2;
  font-weight: bold;
  padding: 10px 25px;
}
button.all-refund-request-outline-btn:hover {
  background: #446fa2;
  color: white;
}
.all-refund-request-wrapper {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.all-refund-request-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
}
.all-refund-request-all-active,
.all-refund-request-my-active {
  border-radius: 5px;
  background-color: white;
}
.all-refund-request-all-active {
  width: 85%;
  margin-top: 40px;
}
.all-refund-request-my-active {
  width: 42.5%;
  margin-left: 30px;
}
.all-refund-request-top input {
  width: 160px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #51595e;
  font-size: 14px;
  font-family: var(--lato);
  align-self: center;
  padding-left: 5px;
}
.all-refund-request-top {
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  border-bottom: 1px solid #e1e1e1;
}
.all-refund-request-top > div {
  display: flex;
  align-items: center;
}
.all-refund-request-top select {
  margin-left: 5px;
  width: 100px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #51595e;
  font-size: 14px;
  font-family: var(--lato);
}

/* head row */
.all-refund-request-row-wrapper {
  display: flex;
  background: #446fa2;
  color: white;
  margin: 0 22px;
}
.all-refund-request-row-wrapper .all-refund-request-head-row:first-child {
  width: 17%;
}
.all-refund-request-row-wrapper .all-refund-request-head-row {
  width: 17%;
  text-align: center;
  font-size: 14px;
  border-right: solid 0.5px #e1e1e1;
  border-left: solid 0.5px #e1e1e1;
  padding: 2.5px 0;
}
/* content row */
.all-refund-request-bottom {
  overflow-y: scroll;
  height: 500px;
  cursor: pointer;
}
.all-refund-request-content-wrapper {
  display: flex;
  margin: 0 22px;
}
.all-refund-request-content-wrapper
  .all-refund-request-content-row:first-child {
  width: 17%;
}
.all-refund-request-content-wrapper .all-refund-request-content-row {
  width: 17%;
  font-size: 14px;
  border-bottom: solid 1px #e1e1e1;
  padding: 10px 5px;
  color: #51595e;
  font-family: var(--lato);
  display: flex;
  text-transform: capitalize;
  justify-content: center;
}
.all-refund-request-content-wrapper .all-refund-request-content-row img {
  width: 20px;
  height: 18px;
  margin-right: 5px;
}
.all-refund-request-msg {
  text-align: center;
  margin-top: 15px;
}
.all-refund-status-button-container {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
}
.all-refund-status-button {
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

/* right side */
.all-refund-request-right-side .all-refund-request-head-row:first-child {
  width: 16%;
}
.all-refund-request-right-side .all-refund-request-head-row {
  width: 28%;
}
.all-refund-request-right-side .all-refund-request-content-row:first-child {
  width: 16%;
}
.all-refund-request-right-side .all-refund-request-content-row {
  width: 28%;
}
.all-refund-request-content-row.all-refund-request-date-row svg {
  font-size: 18px;
  position: absolute;
  right: 20%;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .all-refund-request-main {
    width: 97%;
    justify-content: flex-end;
  }
  .all-refund-request-row-wrapper,
  .all-refund-request-content-wrapper {
    margin: 0 5px;
  }
}

@media screen and (max-width: 1400px) {
  .all-refund-request-row-wrapper .all-refund-request-head-row,
  .all-refund-request-content-wrapper .all-refund-request-content-row {
    font-size: 12px;
  }
  .all-refund-request-wrapper {
    width: 88%;
  }
}
@media screen and (max-width: 1250px) {
  .all-refund-request-wrapper {
    flex-direction: column;
    margin-left: 10%;
  }
  .all-refund-request-all-active {
    width: 100%;
    margin-bottom: 15px;
  }
  .all-refund-request-my-active {
    width: 100%;
    margin: unset;
  }
  .all-refund-request-bottom {
    max-height: 350px;
    min-height: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .all-refund-request-main {
    justify-content: flex-end;
    width: 100%;
  }
  .all-refund-request-ticket-search {
    width: 650px;
  }
  .all-refund-request-content-row.all-refund-request-date-row svg {
    top: 8px;
  }
}

/* ipad air  */
@media screen and (max-width: 920px) {
  .all-refund-request-wrapper {
    width: 80%;
    margin-left: 15%;
  }
  .all-refund-status-button-container {
    gap: 15px;
  }
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .all-refund-request-ticket-search {
    width: 450px;
  }
  .all-refund-request-options-btn button {
    font-size: 11px;
  }
  .all-refund-request-options-btn {
    width: 600px;
    margin: 0 auto;
    margin-top: 15px;
  }
  .all-refund-request-row-wrapper .all-refund-request-head-row {
    font-size: 8px;
  }
}
@media screen and (max-width: 700px) {
  .all-refund-request-options-btn {
    width: 450px;
  }
  .all-refund-request-options-btn button {
    font-size: 12px;
    padding: 7px 12px;
  }
}

@media screen and (max-width: 600px) {
  .all-refund-request-header {
    height: 65px;
  }
  .all-refund-request-main {
    width: 100%;
    justify-content: center;
    height: calc(100% - 130px);
    overflow-y: scroll;
    margin-top: 0;
  }
  .all-refund-request-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .all-refund-request-wrapper {
    flex-direction: column;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 25px;
  }
  .all-refund-request-my-active {
    margin-bottom: 50px;
  }
  .all-refund-request-top p {
    font-size: 14px;
  }
  .all-refund-status-button-container {
    gap: 8px;
  }
}
@media screen and (max-width: 505px) {
  .all-refund-request-row-wrapper {
    width: 510px;
  }
  .all-refund-request-bottom {
    width: 510px;
    max-height: 500px;
  }
  .all-refund-request-top {
    width: 510px;
    padding: 0;
  }
  .all-refund-request-all-active,
  .all-refund-request-my-active {
    overflow-x: scroll;
  }
  .all-refund-request-top p {
    font-size: 12px;
  }
  .all-refund-request-ticket-search {
    width: 300px;
  }
  .all-refund-request-ticket-search input {
    font-size: 10px;
  }
  .all-refund-request-options-btn {
    width: 300px;
  }
  .all-refund-request-options-btn button {
    font-size: 10px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
