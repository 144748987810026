.stusermng-heading p {
  color: #707070;
  text-align: center;
  margin-top: 25px;
  font-size: 30px;
  font-weight: bolder;
}

.stusermng-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.stusermng-parent {
  position: relative;
  height: 100%;
  font-family: var(--montserrat);
}

.stusermng-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}

.stusermng-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}

.stusermng-btn-wrapper > div {
  display: flex;
  flex-direction: column;
  width: 135px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #446fa2;
  background: white;
  height: 85px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.stusermng-btn-wrapper > div:hover {
  border: 2px solid #446fa2;
}

.stusermng-btn-wrapper {
  display: grid;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.stusermng-btn-wrapper > div span {
  text-align: center;
  font-family: var(--montserrat);
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0px;
  color: #446fa2;
  width: 80%;
}

.stusermng-add-user {
  width: 145px;
  height: 40px;
  display: flex;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
  margin-bottom: 50px;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.stusermng-add-user.hide-dropdown .stusermng-user-list {
  display: none;
}

.stusermng-user-list {
  position: absolute;
  top: 72px;
  background: #efefef;
  width: 250px;
  border-radius: 5px;
  font-size: 14px;
  max-height: 300px;
  z-index: 1;
  overflow-y: scroll;
  color: black;
}

.stusermng-user-data {
  display: flex;
  align-items: center;
  padding: 5px 5px;
  border: solid 1px #51595e;
  border-bottom: unset;
  border-radius: 5px;
  justify-content: space-between;
  cursor: pointer;
}

.stusermng-user-data:hover {
  background-color: #b9b9b9;
}

.stusermng-user-data div {
  display: flex;
  align-items: center;
}

.stusermng-user-data:last-child {
  border-bottom: solid 1px #51595e;
}

.stusermng-user-data img {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  object-fit: contain;
}

div.user-management-active {
  background-color: #efefef;
}

.stusermng-user-list .stusermng-msg {
  margin: unset;
  padding: 15px 0px;
}

/* .stusermng-add-user:hover {
  
} */
.stusermng-add-user img {
  width: 22px;
  height: 29px;
  margin-right: 5px;
}

.stusermng-user-table {
  width: 55%;
  background: white;
  height: 475px;
  overflow-y: scroll;
  cursor: pointer;
  position: relative;
}

.stusermng-head-wrapper {
  display: flex;
  justify-content: space-between;
  background: #446fa2;
  color: white;
  font-size: 10px;
  padding: 0 5px;
  border-radius: 5px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  width: 55%;
}

.stusermng-head-row:first-child {
  width: 31%;
  border-left: unset;
}

.stusermng-head-row:last-child {
  border-right: unset;
}

.stusermng-head-row {
  text-align: center;
  width: 23%;
  border-right: solid 1px white;
  border-left: solid 1px white;
  padding: 8px 0;
}

.stusermng-head-rows:first-child {
  width: 31%;
  border-left: unset;
}

.stusermng-head-rows:last-child {
  border-right: unset;
}

.stusermng-head-rows {
  text-align: center;
  width: 35%;
  border-right: solid 1px white;
  border-left: solid 1px white;
  padding: 8px 0;
}

.stusermng-body-wrapper {
  display: flex;
  border-bottom: solid 1.5px #ececec;
}

.stusermng-body-row:first-child {
  width: 31%;
}

.stusermng-body-row {
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.stusermng-body-row .stusermng-body-internal img {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  object-fit: contain;
}

.stusermng-body-row .stusermng-body-internal svg {
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.stusermng-body-row span {
  font-size: 14px;
  font-family: var(--lato);
  color: #51595e;
}

.stusermng-body-row > div {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
  padding: 0 3px 0 5px;
}

.stusermng-body-row-subscribe:first-child {
  width: 31%;
}

.stusermng-body-row-subscribe {
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.stusermng-body-row-subscribe .stusermng-body-internal img {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  object-fit: contain;
}

.stusermng-body-row-subscribe .stusermng-body-internal svg {
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.stusermng-body-row-subscribe span {
  font-size: 14px;
  font-family: var(--lato);
  color: #51595e;
}

.stusermng-body-row-subscribe > div {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
  padding: 0 3px 0 5px;
}

.stusermng-body-rows:first-child {
  width: 31%;
}

.stusermng-body-rows {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.stusermng-body-rows .stusermng-body-internal img {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  object-fit: contain;
}

.stusermng-body-rows .stusermng-body-internal svg {
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.stusermng-body-rows span {
  font-size: 14px;
  font-family: var(--lato);
  color: #51595e;
}

.stusermng-body-rows > div {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
  padding: 0 3px 0 5px;
}

.stusermng-add-user.show-input-box input {
  display: block;
}

.stusermng-add-user input {
  display: none;
  height: 30px;
  width: 250px;
  font-size: 14px;
  border-radius: 5px;
  border: solid 1px #efefef;
  outline: none;
  font-family: var(--montserrat);
  position: absolute;
  top: 40px;
}

.stusermng-msg {
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
}

.change-selected-tab-button {
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.change-selected-tab-button:hover {
  cursor: pointer;
}

.subscription-head-wrapper {
  display: flex;
  justify-content: space-between;
  background: #446fa2;
  color: white;
  font-size: 10px;
  padding: 0 5px;
  border-radius: 5px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.subscription-head {
  width: 55%;
}
.subscription-user-table {
  background: white;
  height: 475px;
  overflow-y: scroll;
  cursor: pointer;
  position: relative;
}

.stusermng-head-row:first-child {
  width: 31%;
  border-left: unset;
}

.stusermng-head-row:last-child {
  border-right: unset;
}

.stusermng-head-row {
  text-align: center;
  width: 23%;
  border-right: solid 1px white;
  border-left: solid 1px white;
  padding: 8px 0;
}

.stusermng-head-rows:first-child {
  width: 31%;
  border-left: unset;
}

.stusermng-head-rows:last-child {
  border-right: unset;
}

.stusermng-head-rows {
  text-align: center;
  width: 35%;
  border-right: solid 1px white;
  border-left: solid 1px white;
  padding: 8px 0;
}
.relative-container {
  position: relative;
}
.user-management-info {
  position: absolute;
  top: 3px;
  right: 5px;
  z-index: 3;
  background: #446fa2;
  border-radius: 50%;
  color: #fff;
  font-size: 18px !important;
}
/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .stusermng-main {
    width: 97%;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1300px) {
  .stusermng-wrapper {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: unset;
  }
}

@media screen and (max-width: 1024px) {
  .stusermng-main {
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (min-width: 921px) and (max-width: 1224px) {
  .stusermng-btn-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 940px) {
  .stusermng-user-table {
    width: 70%;
  }

  .stusermng-head-wrapper {
    width: 70%;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
}

/* ipad air  */

@media screen and (max-width: 920px) {
  .stusermng-btn-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .stusermng-heading p {
    font-size: 25px;
  }
  .subscription-head {
    width: 70%;
    overflow: scroll;
  }
  .subscription-Body {
    width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .stusermng-btn-wrapper {
    margin-bottom: 30px;
  }

  .stusermng-header {
    height: 65px;
  }

  .stusermng-main {
    width: 100%;
    justify-content: center;
    margin-top: 0;
    height: calc(100% - 130px);
    overflow-y: scroll;
  }

  .stusermng-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }

  .stusermng-user-table {
    width: 96%;
  }

  .stusermng-head-wrapper {
    width: 96%;
  }

  .stusermng-add-user {
    margin-bottom: 25px;
    height: 35px;
    width: 120px;
  }

  .stusermng-add-user img {
    width: 17px;
    height: 24px;
  }

  .subscription-head {
    width: 95%;
    overflow: scroll;
  }
}

@media screen and (max-width: 460px) {
  .stusermng-btn-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 376px) {
}

@media screen and (max-width: 330px) {
}

@media screen and (max-width: 1400px) {
}
