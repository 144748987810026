.super-admin-transfer-credits-wrapper {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 35%);
  z-index: 9;
  display: grid;
  place-items: center;
}
.super-admin-transfer-credits-inner {
  max-width: 640px;
  width: 96%;
  background-color: #fff;
  padding: 50px 35px;
  border-radius: 5px;
  position: relative;
}
.super-admin-transfer-credits-inner > button {
  position: absolute;
  top: 15px;
  right: 15px;
  font-weight: bold;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  font-size: 16px;
}
.super-admin-transfer-credits-inner .transfer-credit-pay-button {
  min-width: 174px;
  background-color: rgb(68, 111, 162);
  display: block;
  margin: auto;
}

.super-admin-transfer-credits-inner .transfer-credit-pay-button:hover {
  background-color: rgb(68, 111, 162);
}
