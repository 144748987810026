.add-web-link-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-web-link-parent * {
  font-family: var(--montserrat);
}
.add-web-link-main {
  width: 100%;
  margin: 0 auto;
}
.add-web-link-form-body {
  width: 90%;
  max-width: 450px;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
}
.add-web-link-wrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 1px;
}
.add-web-link-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}
.add-web-link-fields label {
  font-size: 22px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}
.add-web-link-fields input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 15px;
}
.add-web-link-fields button {
  border: unset;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  padding: 10px 50px;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
.add-web-link-heading {
  position: relative;
}
.add-web-link-heading .rename-cross {
  top: 5%;
  right: 1%;
}
