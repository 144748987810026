.date-input-selection-wrapper{
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    margin-bottom: 10px;
    margin-top: 4px;
}

.date-input-selection-wrapper select{
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    border-radius: 4px;
    padding: 16.5px 14px;
    letter-spacing: 0.00938em;
    border: solid 1px #c4c4c4;
    font-size: 14px;
    background-color: #fff;
}