.credit-history-transcation-main-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.credit-history-transcation-content {
  width: 86%;
  max-width: 1100px;
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 25px;
}
.credit-history-transcation-header {
  font-size: 30px;
  color: #707070;
  font-weight: bold;
  font-family: var(--montserrat);
  text-align: center;
  margin-top: 50px;
}
.credit-history-transcation-top-part {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
  border-bottom: solid 1px #f2f2f2;
}
.credit-history-transcation-body-row {
  color: #51595e;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  word-break: break-all;
}
.credit-history-transcation-search {
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: flex;
  height: 26px;
}
.credit-history-transcation-search input {
  border: unset;
  color: #51595e;
  font-family: var(--montserrat);
  font-size: 12px;
  outline: none;
}
.credit-history-transcation-balance span {
  font-family: var(--lato);
  color: #446fa2;
  font-size: 12px;
  font-weight: bold;
}
.credit-history-transcation-balance {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.credit-history-transcation-balance b {
  color: #446fa2;
  font-family: var(--lato);
  font-size: 40px;
}
.credit-history-transcation-bottom-part {
  padding: 20px 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  padding-top: unset;
  min-height: 360px;
  max-height: 600px;
  overflow-y: scroll;
}
.credit-history-transcation-card {
  height: fit-content;
}
.credit-history-transcation-balance {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.credit-history-transcation-card-content {
  flex: 1;
}
.credit-history-transcation-card-wrapper {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap;
}
.credit-history-transcation-card-expanded-head-row {
  display: flex;
  background-color: #4671a4;
  padding: 3px 10px;
}
.credit-history-transcation-card-expanded-body-row {
  display: flex;
  padding: 5px 10px;
  border-bottom: solid 1px #f2f2f2;
  align-items: center;
}
.credit-history-transcation-card-expanded-head-row > div {
  color: white;
  font-family: var(--montserrat);
  font-size: 14px;
}
.credit-history-transcation-card-expanded-body-row > div {
  font-size: 14px;
  font-family: var(--lato);
}
.credit-history-transcation-card-logo img {
  width: 68px;
  height: 68px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 10px;
}
.credit-history-transcation-card-content h5 {
  margin: unset;
  font-size: 16px;
  font-family: var(--lato);
  color: #51595e;
}
.credit-history-transcation-card-content span {
  color: #647589;
  font-size: 14px;
}
.credit-history-transcation-card-button {
  width: 100px;
}
.credit-history-transcation-card-button span {
  display: flex;
  justify-content: flex-end;
  color: #4671a4;
  font-size: 12px;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.credit-history-transcation-card-button span img {
  width: 18px;
  margin-left: 5px;
}

@media screen and (max-width: 1280px) {
  .credit-history-transcation-content,
  .credit-history-transcation-header {
    margin-left: 12%;
  }
  .credit-history-transcation-balance {
    left: 55%;
  }
  .credit-history-transcation-card-content h5 {
    font-size: 14px;
  }
  .credit-history-transcation-card-content span {
    font-size: 12px;
  }
  .credit-history-transcation-balance b {
    font-size: 35px;
  }
}
@media screen and (max-width: 900px) {
  .credit-history-transcation-bottom-part {
    grid-template-columns: repeat(1, 1fr);
    max-height: 450px;
  }
  .credit-history-transcation-card-content h5 {
    font-size: 16px;
  }
  .credit-history-transcation-card-content span {
    font-size: 15px;
  }
  .credit-history-transcation-balance {
    transform: unset;
    position: unset;
  }
  .credit-history-transcation-top-part {
    padding: 5px 20px;
  }
  .credit-history-transcation-content,
  .credit-history-transcation-header {
    margin-left: 14%;
  }
  .credit-history-transcation-content {
    width: 84%;
  }
}
@media screen and (max-width: 600px) {
  .credit-history-transcation-navigation {
    position: fixed;
    width: 100%;
    z-index: 1;
    bottom: 0;
  }
  .credit-history-transcation-content,
  .credit-history-transcation-header {
    margin: 0 auto;
    margin-top: 25px;
  }
  .credit-history-transcation-content {
    width: 97%;
  }
  .credit-history-transcation-balance b {
    font-size: 25px;
  }
  .credit-history-transcation-balance span {
    font-size: 10px;
  }
  .credit-history-transcation-bottom-part {
    max-height: 500px;
  }
  .credit-history-transcation-header {
    font-size: 25px;
  }
  .credit-history-transcation-card-expanded {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 500px) {
  .credit-history-transcation-card-expanded-head-row,
  .credit-history-transcation-card-expanded-head-row + div {
    width: 600px;
  }
  .credit-history-transcation-card-expanded {
    width: 425px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 475px) and (min-width: 451px) {
  .credit-history-transcation-card-expanded {
    width: 405px;
  }
}
@media screen and (max-width: 450px) and (min-width: 430px) {
  .credit-history-transcation-card-expanded {
    width: 382px;
  }
}
@media screen and (max-width: 450px) {
  .credit-history-transcation-top-part {
    display: flex;
    flex-direction: column;
  }
  .credit-history-transcation-card-content h5 {
    font-size: 14px;
  }
  .credit-history-transcation-card-content span {
    font-size: 13px;
  }
  .credit-history-transcation-balance {
    margin-top: 10px;
  }
}
@media screen and (max-width: 440px) and (min-width: 421px) {
  .credit-history-transcation-card-expanded {
    width: 364px;
  }
}
@media screen and (max-width: 420px) and (min-width: 401px) {
  .credit-history-transcation-card-expanded {
    width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .credit-history-transcation-card-button {
    width: 100%;
  }
  .credit-history-transcation-card-expanded {
    width: 334px;
  }
}
@media screen and (max-width: 375px) {
  .credit-history-transcation-card-expanded {
    width: 312px;
  }
}
@media screen and (max-width: 350px) {
  .credit-history-transcation-card-expanded {
    width: 280px;
  }
}
@media screen and (max-width: 325px) {
  .credit-history-transcation-card-expanded {
    width: 264px;
  }
}
@media screen and (max-width: 300px) {
  .credit-history-transcation-card-expanded {
    width: 200px;
  }
}
