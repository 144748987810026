.all-credit-card-details-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}
.all-credit-card-details-parent * {
  font-family: var(--montserrat);
}
.all-credit-card-details-main {
  width: 95%;
  max-width: 500px;
  max-height: 75%;
  background-color: white;
  border-radius: 5px;
  overflow: scroll;
}
.all-credit-card-details-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  border-bottom: solid 1px #e1e1e1;
}
.all-credit-card-details-heading {
  position: relative;
  margin-top: -20px;
}
.all-credit-card-details-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.all-credit-card-details-add-new {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}
.all-credit-card-details-add-new button {
    border: unset;
    border-radius: 5px;
    background: #5ca127;
    color: white;
    padding: 10px 30px;
    width: fit-content;
    cursor: pointer;
}
.all-credit-card-details-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    overflow-y: scroll;
 }
.all-credit-card-details-content {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   border: 1px solid #4671a4;
   border-radius: 5px;
   box-shadow: 0px 3px 6px #00000029;
   padding: 10px 20px;
   position: relative;
}
.all-credit-card-details-content label {
  font-size: 16px;
  color: #4671A4;
  font-weight: bold;
}
.all-credit-card-details-content p {
  all: unset;
  font-size: 14px;
  color: #C50000;
  font-weight: normal;
}