.super-admin-storage-dashboard-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.super-admin-storage-dashboard-wrapper {
  height: 100%;
  width: 100%;
  padding-left: 9.5%;
}
.super-admin-storage-dashboard-wrapper-content {
  display: flex;
  gap: 20px;
  width: 95%;
  max-width: 1400px;
  margin-top: 37px;
}
.super-admin-storage-dashboard-wrapper-content label {
  font-size: 18px;
  font-family: var(--montserrat);
  font-weight: bold;
  letter-spacing: 0px;
  color: #51595e;
}
.super-admin-storage-dashboard-wrapper-content button {
  font-size: 12px;
  font-family: var(--montserrat);
  font-weight: bold;
  width: 136px;
  height: 34px;
  white-space: nowrap;
}
.super-admin-storage-dashboard-wrapper aside {
  background-color: white;
  width: 40%;
  height: 70%;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  height: max-content;
  position: relative;
}
.super-admin-storage-dashboard-pie-chat-center-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 41%;
  left: 51%;
  transform: translate(-50%);
}
.super-admin-storage-dashboard-pie-chat-center-label label:first-child {
  font-weight: bolder;
  font-size: 30px;
  letter-spacing: 2px;
}
.super-admin-storage-dashboard-pie-chat-center-label label:last-child {
  font-weight: bolder;
}
.super-admin-storage-dashboard-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 2px solid #f5f5f5;
  height: 56px;
}
.super-admin-storage-dashboard-wrapper section {
  width: 60%;
  background-color: white;
  border-radius: 5px;
  height: max-content;
}
.super-admin-storage-dashboard-pie-chat-center-label label {
  align-self: center;
  color: #4671a4;
}
.super-admin-storage-dashboard-aside-body-content-cards-container {
  display: grid;
  padding: 0px 10px 20px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 15px;
}
.super-admin-storage-dashboard-aside-body-content-cards {
  display: flex;
  gap: 8px;
  padding: 0;
  align-items: center;
}
.super-admin-storage-dashboard-cards-color {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}
.super-admin-storage-dashboard-aside-body-content-cards span,
.super-admin-storage-dashboard-section-body-content-cards span {
  font-size: 12px;
  font-family: var(--montserrat);
  color: #51595e;
  font-weight: bold;
}
.super-admin-storage-dashboard-section-body-content-cards-container {
  display: grid;
  padding: 20px 20px;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  max-height: 577px;
  overflow: scroll;
}
.super-admin-storage-dashboard-section-body-content-cards {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707021;
}
.super-admin-storage-dashboard-section-body-content-cards img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: contain;
}
.super-admin-storage-dashboard-section-body-content-cards-label-container {
  display: flex;
  flex-direction: column;
}
.super-admin-storage-dashboard-section-body-content-cards label {
  font-size: 16px;
}
.super-admin-storage-dashboard-section-body-first-content,
.super-admin-storage-dashboard-section-body-second-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.super-admin-storage-dashboard-section-body-content-cards-container a {
  color: #4671a4;
  font-size: 12px;
  font-family: var(--lato);
  align-self: flex-end;
  cursor: pointer;
  margin-right: 15px;
}
.expanded-org-storage-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 5px 0;
  min-height: 162px;
}
.expanded-org-storage-details-container p {
  all: unset;
  color: #51595e;
  font-family: var(--montserrat);
  font-size: 12px;
  font-weight: bold;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding: 5px 12px;
}
.expanded-org-storage-details-container p:first-child {
  border-top: 2px solid #f5f5f5;
}
.expanded-org-storage-details-container p:last-child {
  border-bottom: 2px solid #f5f5f5;
}

.expanded-org-storage-details-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
@media screen and (max-width: 1320px) {
  .super-admin-storage-dashboard-wrapper aside {
    width: 35%;
  }
  .super-admin-storage-dashboard-wrapper section {
    width: 65%;
  }
  .super-admin-storage-dashboard-aside-body-content-cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .super-admin-storage-dashboard-pie-chat-center-label {
    top: 34%;
  }
}
@media screen and (max-width: 1220px) {
  .super-admin-storage-dashboard-wrapper aside {
    width: 45%;
  }
  .super-admin-storage-dashboard-wrapper section {
    width: 55%;
  }
  .super-admin-storage-dashboard-pie-chat-center-label {
    top: 34%;
  }
  .super-admin-storage-dashboard-section-body-content-cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1080px) {
}
@media screen and (max-width: 900px) {
  .super-admin-storage-dashboard-wrapper {
    padding-left: 10%;
  }
}

@media screen and (max-width: 870px) {
  .super-admin-storage-dashboard-wrapper {
    padding-left: 15%;
  }
  .super-admin-storage-dashboard-wrapper-content {
    flex-direction: column;
  }
  .super-admin-storage-dashboard-wrapper aside {
    width: 100%;
  }
  .super-admin-storage-dashboard-wrapper section {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .super-admin-storage-dashboard-wrapper {
    padding-left: 4%;
  }
  .super-admin-storage-dashboard-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .super-admin-storage-dashboard-section-body-content-cards-container {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 450px) {
  .super-admin-storage-dashboard-wrapper-content button {
    font-size: 10px;
    width: 110px;
    height: 30px;
  }
}

@media screen and (max-width: 380px) {
  
}
