.chatconnection-container {
  background: white;
  border-radius: 5px;
}
.chatconnection-upper {
  padding: 1px 15px;
  border-bottom: 1px solid #0000001a;
}
.chatusercard-container .chat-screen-logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
}
.chatusercard-container .chat-screen-status-dot svg {
  font-size: 15px;
}
.chatusercard-container {
  display: flex;
  padding: 7px 20px;
  justify-content: space-between;
  border: solid 1px #f5f5f5;
  cursor: pointer;
}
.chatusercard-container > div {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.chatusercard-container > div:first-child {
  flex-basis: 85%;
  overflow: hidden;
}
.chatusercard-container h5 {
  font-size: 16px;
  font-weight: normal;
}
.chatusercard-container .chat-screen-box {
  width: 20px;
  height: 20px;
  border: 1px solid #cecece;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatusercard-container .chat-screen-profile img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: contain;
}
.chat-screen-time {
  font-family: var(--lato);
  font-size: 12px;
  color: #51595e;
  flex-basis: 10%;
  flex-direction: column;
  justify-content: center;
}
.chatconnection.lower {
  height: 365px;
  overflow-y: scroll;
}
.chatconnection-wrapper {
  height: 100%;
}
.chatusercard-container.recent-msg {
  background: #b4c9ed;
}
.chatusercard-container.recent-msg .chat-screen-profile h5 {
  font-weight: bold;
}
.chatusercard-container.recent-msg .chat-screen-profile p {
  font-weight: bold;
}
.chatusercard-container.recent-msg .chat-screen-time {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .chatusercard-container .chat-screen-box {
    width: 17px;
    height: 17px;
  }
  .chatusercard-container .chat-screen-profile img {
    width: 17px;
    height: 17px;
  }
}
