.selected-appointment-popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 6;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-appointment-popup-content {
  width: 90%;
  max-width: 550px;
  height: max-content;
  position: relative;
  background-color: white;
  border-radius: 5px;
}
.selected-appointment-details-heading {
  position: relative;
  margin-top: -10px;
  color: #51595e;
}
.selected-appointment-details-heading p {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.selected-appointment-details-heading .rename-cross {
  top: -5px;
  right: 1%;
  font-family: var(--montserrat);
}
.selected-appointment-popup-body {
  padding: 0 20px 10px;
}
.selected-appointment-popup-body b {
  font-size: 15px;
  margin-right: 5px;
}
.selected-appointment-popup-body span {
  font-size: 13px;
}
.selected-appointment-body-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -15px;
}
.selected-appointment-body-top label {
  font-size: 16px;
  font-family: var(--montserrat);
  font-weight: bold;
}
.selected-appointment-body-top button {
  padding: unset;
}
