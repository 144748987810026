.create-chat-room-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-chat-room-container button:hover {
  cursor: pointer;
}

.create-chat-room-dialog {
  width: 386px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.create-chat-room-icon {
  width: 110%;
  display: flex;
  justify-content: flex-end;
}

.create-chat-room-close-button {
  background-color: transparent !important;
  border-width: 0px !important;
}

.create-chat-room-dialog span {
  font: normal normal bold 16px/21px Lato;
  letter-spacing: 0px;
  color: #51595e;
  opacity: 1;
  margin-top: -10px;
}

.create-chat-room-image {
  background: transparent;
  border: 1px solid #e6e6e6;
  opacity: 1;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-top: 10px;
}

.create-chat-room-image img {
  width: 64px;
  height: 64px;
  border-radius: 50px;
  object-fit: contain;
}

.create-chat-room-dialog input {
  width: 343px;
  height: 44px;
  background: #ffffffb3 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  opacity: 1;
  padding-left: 15px;
  padding-right: 15px;
  font: normal normal 15px/18px Lato;
  letter-spacing: 0px;
  color: #919191;
  margin-top: 10px;
}

.create-chat-room-select-members-container {
  width: 343px;
  max-height: 160px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #446fa2;
  border-radius: 5px;
  opacity: 1;
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: scroll;
}

.create-chat-room-select-members {
  display: flex;
  flex-direction: column;
  width: 316px;
  height: 50px;
  opacity: 1;
  margin-top: 8px;
}

.create-chat-room-select-no-members {
  display: flex;
  width: 316px;
  height: max-content;
  justify-content: center;
  align-items: center;
  opacity: 1;
  margin-top: 8px;
}

.create-chat-room-select-members-content {
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: transparent !important;
  border-width: 0px !important;
}

.create-chat-room-select-members-content img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: contain;
}

.create-chat-room-members-container {
  width: 343px;
  height: 117px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #446fa2;
  border-radius: 5px;
  opacity: 1;
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: scroll;
}

.create-chat-room-members {
  display: flex;
  flex-direction: column;
  width: 316px;
  height: 50px;
  opacity: 1;
  margin-top: 8px;
}

.create-chat-room-create-button {
  width: 174px;
  height: 39px;
  background: #5ca127 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-top: 20px;
  text-align: center;
  font: normal normal bold 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  border-width: 0px !important;
}

.create-chat-room-members-content {
  display: flex;
  gap: 20px;
  align-items: center;
}

.create-chat-room-members-content img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: contain;
}

.create-chat-room-online-icon {
  position: absolute;
  right: -5%;
  top: -10%;
}
