.stphome-heading p {
  color: #707070;
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
  font-weight: bolder;
}
.stphome-parent {
  position: relative;
  height: 100%;
}
.stphome-parent * {
  font-family: var(--montserrat);
}
.stphome-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.stphome-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.stphome-ticket-wrapper {
  width: 750px;
  height: 50px;
  background: #ffffffb3 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  position: relative;
}
.stphome-ticket-search input {
  outline: none;
  border: none;
  background: transparent;
  letter-spacing: 0px;
  color: #446fa2;
  font-size: 15px;
  font-style: italic;
  width: 100%;
}
.stphome-ticket-search .organization-list {
  position: absolute;
  top: 50px;
  width: 100%;
  left: 0;
  background: white;
}
.stphome-ticket-wrapper.hide-dropdown .organization-list {
  display: none;
}
.stphome-ticket-search .organization-list button {
  font-family: var(--montserrat);
  background-color: #5ca127;
}
.stphome-ticket-search svg {
  color: #446fa2;
}
.stphome-ticket-search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #446fa2;
}
.stphome-ticket-search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #446fa2;
}
.stphome-ticket-search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #446fa2;
}

.stphome-options-btn {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.stphome-options-btn > button {
  margin-right: 20px;
  background: #5ca127;
  border-radius: 5px;
  color: white;
  border: unset;
  padding: 10px 50px;
  cursor: pointer;
  border: solid 1px #5ca127;
}
.stphome-options-btn > button:hover {
  background: white;
  color: #5ca127;
}
.stphome-options-btn > button:last-child {
  margin-right: unset;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .stphome-main {
    width: 97%;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
  .stphome-main {
    justify-content: flex-end;
    width: 100%;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .stphome-ticket-wrapper {
    width: 550px;
  }
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .stphome-ticket-wrapper {
    width: 500px;
  }
}
@media screen and (max-width: 600px) {
  .stphome-header {
    height: 65px;
  }
  .stphome-main {
    width: 100%;
    justify-content: center;
    margin-top: 0;
    height: calc(100% - 130px);
    overflow-y: scroll;
  }
  .stphome-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .stphome-ticket-search .organization-list button {
    font-size: 7px;
  }
}
@media screen and (max-width: 500px) {
  .stphome-ticket-wrapper {
    width: 300px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
