.storage-limit-reached-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.storage-limit-reached-parent * {
  font-family: var(--montserrat);
}
.storage-limit-reached-main {
  width: 100%;
  margin: 0 auto;
}
.storage-limit-reached-form-body {
  width: 90%;
  max-width: 450px;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
}
.storage-limit-reached-wrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 1px;
}
.storage-limit-reached-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}
.storage-limit-reached-fields label {
  font-size: 22px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}
.storage-limit-reached-fields span {
  font-size: 16px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
}
.storage-limit-reached-heading {
  position: relative;
}
.storage-limit-reached-heading .rename-cross {
  top: 5%;
  right: 1%;
}

@media screen and (max-width: 600px) {
  .storage-limit-reached-fields button {
    font-size: 12px;
  }
}
