.dashboard-parent {
  position: relative;
  height: 100%;
}
.dashboard-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.dashboard-main {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.dashboard-left {
  width: 35%;
  margin-right: 1%;
}
.dashboard-right {
  width: 55%;
  margin-left: 1%;
}
.notif-head,
.profile-head,
.appoint-head {
  font-family: var(--montserrat);
  font-weight: bold;
}
.dashboard-main button {
  font-family: var(--montserrat);
}
.dashboard-main p {
  font-size: 14px;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .dashboard-main button {
    font-size: 12px;
  }
  .notif-upper,
  .profile-header-section,
  .appoint-upper.appoint-flex {
    padding: 15px 30px;
  }
  div.notification-type,
  div.notification-data h3 {
    font-size: 14px;
  }
  .dashboard-main {
    width: 97%;
    justify-content: flex-end;
  }
  .dashboard-main .notif-card-wrapper {
    padding: 5px 15px;
  }
  .dashboard-main .notification-data p {
    width: 85%;
  }
  .dashboard-main .notif-logo img {
    object-fit: contain;
  }
}

@media screen and (max-width: 1400px) {
  .dashboard-main p {
    font-size: 12px;
  }
  .notif-upper,
  .profile-header-section {
    padding: 15px 15px;
  }
  .appoint-upper {
    padding: 20px 15px !important;
  }
  .notif-card-wrapper {
    padding: 5px 15px;
    padding-top: 15px;
  }
  .notif-type {
    font-size: 12px;
  }
  .card-container {
    width: 49%;
  }
  .card-text {
    overflow: hidden;
  }
  .notif-logo {
    width: 50px;
    height: 50px;
  }
  .profile-detials {
    padding: 20px 15px;
    padding-top: 0;
  }
  .appoint-card-wrapper {
    padding: 0 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .dashboard-left {
    display: none;
  }
  .dashboard-right {
    width: 92%;
    margin-left: unset;
  }
  .dashboard-main {
    justify-content: flex-end;
    width: 100%;
  }
  .notif-card-wrapper {
    padding: 5px;
    padding-top: 15px;
  }
  .appoint-card-wrapper {
    padding: 0 5px !important;
  }
  .profile-detials {
    padding: 20px 5px;
    padding-top: 0;
  }
  .notif-logo {
    margin-right: 2.5px;
  }
  .card-text {
    white-space: nowrap;
  }
  .profile-details:last-child .profile-with-card .profile-card-wrapper {
    max-height: 400px;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .dashboard-right {
    width: 87.5%;
    margin-left: unset;
  }
}
/* ipad air  */

@media screen and (max-width: 769px) {
  .card-container {
    width: 95%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .dashboard-header {
    height: 65px;
  }
  .dashboard-navigation {
    height: 60px;
  }
  .dashboard-main {
    width: 100%;
    justify-content: center;
    margin-top: 0;
    height: calc(100% - 130px);
    overflow-y: scroll;
  }
  .dashboard-right {
    width: 100%;
  }
  .profile-url span {
    font-size: 12px;
  }
  .dashboard-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .profile-lower {
    margin-bottom: 60px;
  }
  .profile-view_all button {
    font-size: 10px;
  }
  .profile-detials {
    padding: 20px 2.5px;
    padding-top: 0;
  }
}
@media screen and (max-width: 376px) {
  .profile-text p {
    font-size: 12px;
  }
  .profile-text h3 {
    font-size: 16px;
  }
  .profile-view_all button {
    font-size: 7.5px;
  }
  .dashboard-main button {
    font-size: 7.5px;
    font-weight: normal;
  }
  button.tablinks-button {
    font-size: 12px;
  }
  button.locationlinks-button {
    font-size: 12px;
  }
}
@media screen and (max-width: 330px) {
  .dashboard-main p {
    font-size: 9px;
  }
}
