.filePreview-Popup-parent {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 8 !important;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
}
.filePreview-Popup-body {
  background-color: white;
  border-radius: 5px;
  width: auto;
  padding: 5px 10px 20px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 65%;
}
.filePreview-Popup-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  cursor: pointer;
}
.image-file {
  height: 90%;
  width: auto;
}
.video-file {
  height: 90%;
  width: auto;
}
@media only screen and (max-width: 900px) {
  .filePreview-Popup-body {
    height: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .filePreview-Popup-body {
    height: 45%;
  }
}
@media only screen and (max-width: 610px) {
  .filePreview-Popup-body {
    height: 40%;
  }
}

@media only screen and (max-width: 500px) {
  .filePreview-Popup-body {
    height: 35%;
  }
}

@media only screen and (max-width: 450px) {
  .filePreview-Popup-body {
    height: 30%;
  }
}
@media only screen and (max-width: 400px) {
  .filePreview-Popup-body {
    height: 25%;
  }
}
