.streassign-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 90%);
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.streassign-popup {
  width: 40%;
  margin: 0 auto;
  position: relative;
}
.streassign-popup form {
  display: flex;
  flex-direction: column;
}
.streassign-cross {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  cursor: pointer;
  background: #446fa2;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
}
.streassign-popup span {
  display: block;
  margin-bottom: 10px;
  margin-top: 25px;
}
.streassign-email {
  margin-bottom: 25px;
}
.streassign-email .hide-dropdown .organization-list {
  display: none;
}
.streassign-email input {
  width: 100%;
  border-radius: 5px;
  height: 45px;
  padding: 0 15px;
}
.category-name {
  width: 100%;
  border-radius: 5px;
  height: 45px;
}
.assign-tech {
  width: 100%;
  border-radius: 5px;
  height: 45px;
}
.streassign-email > div {
  position: relative;
}
.streassign-email .organization-list {
  position: absolute;
  top: 45px;
  width: 100%;
  z-index: 1;
  background: white;
  cursor: pointer;
  max-height: 280px;
  overflow-y: scroll;
}
.streassign-email span {
  margin: unset;
}
label#category-label {
  display: flex;
}
label#assignTech-label {
  display: flex;
}
@media screen and (max-width: 1024px) {
  .streassign-popup {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .streassign-popup {
    width: 65%;
  }
}

@media screen and (max-width: 426px) {
  .streassign-popup {
    width: 90%;
  }
}
