.video-calling-rate-parent {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-calling-rate-parent * {
    font-family: var(--montserrat);
  }
  .video-calling-rate-main {
    width: 100%;
    margin: 0 auto;
  }
  .video-calling-rate-form-body {
    width: 80%;
    max-width: 300px;
    min-width: 270px;
    background-color: white;
    border-radius: 5px;
    margin: 0 auto;
  }
  .video-calling-rate-wrapper {
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 1px;
  }
  .video-calling-rate-fields {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 25px;
  }
  .video-calling-rate-fields label {
    font-size: 14px;
    color: #51595E;
    font-weight: bold;
    text-align: center;
  }
  .video-calling-rate-fields input {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
  }
  .video-calling-rate-fields button {
    border: unset;
    border-radius: 5px;
    background: #5ca127;
    color: white;
    padding: 10px 50px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
  }
  .video-calling-rate-heading {
    position: relative;
  }
  .video-calling-rate-heading .rename-cross {
    top: 5%;
    right: 1%;
  }