.accept-chat-room-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accept-chat-room-container button:hover {
  cursor: pointer;
}

.accept-chat-room-container p {
  text-align: center;
  font: normal normal bold 16px/21px Lato;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-top: -3px;
  color: #51595e;
}

.accept-chat-room-container a {
  text-decoration: underline;
  color: #446fa2;
}

.accept-chat-room-container a:hover {
  cursor: pointer;
}

.accept-external-chat-room-dialog {
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 22px;
  padding-top: 10px;
}

.accept-external-chat-room-icon {
  width: 112%;
  display: flex;
  justify-content: flex-end;
}

.accept-chat-room-close-button {
  background-color: transparent !important;
  border-width: 0px !important;
}

.accept-chat-room-accept-button {
  width: 330px;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #446fa2;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #446fa2;
  text-transform: uppercase;
  margin-top: 3px;
}
