.close-button {
    height: max-content;
    width: max-content;
    padding: 5px;
    background-color: transparent;
    border-width: 0px;
    color: white;
  }
  
  .close-button:hover {
     background-color: #607d8b;
  }
  
  .setting-container {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 5,
  }
  
  .setting-content {
    width: 90%;
    max-width: 400px;
    background-color: #262626;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 45px;
    border-radius: 5px
  }
  
  .setting-header-section {
    width: 100;
    display: flex;
    justify-content: space-between;
    margin-top: -5;
    align-items: center;
  }
  
  .setting-select {
    width: 100%;
    height: 40px;
    font-size: 18px;
    border-radius: 5px
  }