.workbook-files-parent {
  position: relative;
  height: 100%;
}
.workbook-files-parent * {
  font-family: var(--montserrat);
}
.workbook-files-main-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.workbook-files-wrapper {
  width: 90%;
  max-width: 1320px;
  margin: 30px auto auto auto;
  padding-bottom: 75px;
}
.workbook-files-content-name {
  display: flex;
  justify-content: center;
}
.workbook-files-content-name button {
  all: unset;
  color: #4671a4;
  text-decoration: underline;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
  padding: 0 10px;
}
.workbook-files-content-name button:hover {
  cursor: pointer;
}
.workbook-files-slide {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 40px;
}
.workbook-files-slide-header {
  padding: 21px 15px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}
.workbook-files-slide-header-left label {
  font: normal normal bold 14px/21px var(--lato);
  color: #446fa2;
  white-space: nowrap;
}
.workbook-files-slide-header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.workbook-files-slide-header-left button {
  display: flex;
  align-items: center;
  border-radius: 13px;
  padding: 0;
  border: 2px solid #446fa2;
  background-color: transparent;
  max-width: 250px;
  overflow: hidden;
}
.workbook-files-slide-header-left button img {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: block;
  object-fit: contain;
}
.workbook-files-slide-header-left button span {
  padding: 0 9px;
  font: normal normal normal 14px/21px var(--lato);
  letter-spacing: 0px;
  color: #51595e;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.workbook-files-slide-header-middle {
  display: flex;
  gap: 10px;
}
.workbook-files-slide-header-middle button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  border: none;
  background-color: #5ca127;
}
.workbook-files-slide-header-middle img {
  height: 18px;
  width: 18px;
}
.workbook-files-slide-header a {
  all: unset;
  background-color: #5ca127;
  width: 56px;
  border-radius: 5px;
  padding: 3px;
  text-align: center;
  font: normal normal bold 11px/21px var(--lato);
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
}
.workbook-files-slide-header a:hover {
  cursor: pointer;
}
.workbook-files-slide-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.workbook-files-slide-header-right button:not([disabled]) {
  background-color: #5ca127;
}
.workbook-files-slide-header-right button {
  width: 70px;
  border-radius: 5px;
  padding: 5px;
  border: none;
  text-align: center;
  font: normal normal bold 11px/21px var(--lato);
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
}
.workbook-files-slide-header-right button[disabled],
.workbook-files-slide-header-middle button[disabled] {
  background-color: #dcdcdc;
  color: #949494;
}
.workbook-files-slide-header-right > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.workbook-files-slide-header-right label {
  height: 23px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  padding: 0 17px;
  border: 2px solid #446fa2;
  background-color: transparent;
  font: normal normal bold 14px/21px var(--lato);
  letter-spacing: 0px;
  color: #446fa2;
  cursor: pointer;
  white-space: nowrap;
}
.workbook-files-slide-header-right label input[type="checkbox"] {
  cursor: pointer;
}
.workbook-files-slide-body {
  width: 100%;
  aspect-ratio: 2.2;
  border-top: 1px solid #f5f5f5;
  overflow: auto;
  scrollbar-color: #a3c1f5 #e3ecfc;
}
.workbook-files-slide-body::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
.workbook-files-slide-body::-webkit-scrollbar-track {
  background: #e3ecfc;
}
.workbook-files-slide-body::-webkit-scrollbar-thumb {
  background-color: #a3c1f5;
  border-radius: 6px;
  border: 3px solid #e3ecfc;
  height: 30px;
  width: auto;
}
.workbook-files-slide-report-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 90%;
  max-width: 400px;
  height: max-content;
  text-align: center;
  margin: 35px auto auto auto;
}
.workbook-files-slide-report-section label {
  font: normal normal bold 20px var(--montserrat);
  color: #707070;
}
.workbook-files-slide-report-section button {
  width: 90px;
  border-radius: 5px;
  padding: 10px 5px;
  border: none;
  text-align: center;
  font: normal normal bold 14px var(--lato);
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  background-color: #446fa2;
}
.animation {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  animation: 1.5s shine linear infinite;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
@media screen and (max-width: 1550px) {
  .workbook-files-wrapper {
    margin-left: 8%;
  }
}
@media screen and (max-width: 1210px) {
  .workbook-files-slide-header {
    flex-wrap: wrap;
    justify-content: center;
  }
  .workbook-files-wrapper {
    margin-left: 12%;
    width: 85%;
  }
  .workbook-files-slide-body {
    aspect-ratio: 2.1;
  }
}
@media screen and (max-width: 991px) {
  .workbook-files-slide-body {
    aspect-ratio: 1.7;
  }
}
@media screen and (max-width: 750px) {
  .workbook-files-slide-header-left {
    justify-content: center;
  }
  .workbook-files-wrapper {
    margin-left: 14%;
    width: 83%;
  }
  .workbook-files-slide-header {
    gap: 10px;
  }
  .workbook-files-slide-body {
    aspect-ratio: 1.4;
  }
  .workbook-files-slide-header-left button {
    max-width: 232px;
  }
}
@media screen and (max-width: 671px) {
  .workbook-files-slide-header-left button {
    max-width: 210px;
  }
}
@media screen and (max-width: 600px) {
  .workbook-files-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .workbook-files-wrapper {
    margin: auto;
    width: 90%;
  }
  .workbook-files-slide-body {
    aspect-ratio: 1.15;
  }
  .workbook-files-slide-header-left button {
    max-width: 190px;
  }
}
@media screen and (max-width: 520px) {
  .workbook-files-wrapper {
    width: 95%;
  }
}
@media screen and (max-width: 490px) {
  .workbook-files-slide-header-left {
    flex-wrap: wrap;
  }
  .workbook-files-slide-header-left button {
    max-width: 300px;
  }
}
@media screen and (max-width: 480px) {
  .workbook-files-slide-header-right {
    flex-wrap: wrap;
    justify-content: center;
  }
  .workbook-files-slide-body {
    aspect-ratio: 1;
  }
}

@media screen and (max-width: 375px) {
  .workbook-files-slide-body {
    aspect-ratio: 0.9;
  }
}
