.super-admin-transfer-and-banking-wrapper {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
}
.super-admin-transfer-and-banking-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
}
.super-admin-transfer-and-banking-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  cursor: pointer;
}
.super-admin-transfer-and-banking-lower-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  cursor: pointer;
  height: 155px;
  width: 50%;
}
.super-admin-transfer-and-banking-upper-tile-container {
  display: flex;
  margin-top: 40px;
  column-gap: 30px;
  justify-content: center;
}
.super-admin-transfer-and-banking-lower-tile-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.super-admin-transfer-and-banking-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 30px;
  margin-top: 20px;
}
.super-admin-transfer-and-banking-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.super-admin-transfer-and-banking-tile > span {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.super-admin-transfer-and-banking-tile span {
  color: #446fa2;
  font-weight: bold;
}
.super-admin-transfer-and-banking-tile span b {
  font-size: 22px;
}
.super-admin-transfer-and-banking-lower-tile span {
  color: #446fa2;
  font-weight: bold;
  padding-left: 20px;
}

@media screen and (max-width: 1080px) {
  .super-admin-transfer-and-banking-wrapper {
    margin-left: 15%;
  }
}

@media screen and (max-width: 730px) {
  .super-admin-transfer-and-banking-content {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
    column-gap: 20px;
    margin-top: 10px;
  }
  .super-admin-transfer-and-banking-lower-tile-container {
    margin-top: 10px;
  }
  .super-admin-transfer-and-banking-lower-tile {
    width: 100%;
  }
  .super-admin-transfer-and-banking-tile {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .super-admin-transfer-and-banking-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .super-admin-transfer-and-banking-upper-tile-container {
    flex-direction: column;
    row-gap: 10px;
  }
  .super-admin-transfer-and-banking-wrapper {
    margin-left: 10%;
  }
  .super-admin-transfer-and-banking-tile img,
  .super-admin-transfer-and-banking-lower-tile img {
    width: 50px !important;
    height: 50px !important;
  }
  .super-admin-transfer-and-banking-tile,
  .super-admin-transfer-and-banking-lower-tile {
    height: 90px !important;
  }
  .super-admin-transfer-and-banking-tile span {
    font-size: 12px;
  }
  .super-admin-transfer-and-banking-heading {
    font-size: 25px;
  }
  .super-admin-transfer-and-banking-lower-tile-container {
    padding-bottom: 100px;
  }
  .super-admin-transfer-and-banking-tile .moneyCreditManagement-info {
    height: 20px !important;
    width: 20px !important;
  }
}
