.chatorganization-lower
  .chatorganization-search.hide-dropdown
  .result-container {
  display: none;
}
.chatorganization-container {
  background: white;
  border-radius: 5px;
  margin-bottom: 15px;
}
.chatorganization-upper {
  padding: 1px 15px;
  border-bottom: 1px solid #0000001a;
}
.chatorganization-search label {
  font-family: var(--montserrat);
}
.chatorganization-search {
  position: relative;
}
.chatorganization-search .result-button button {
  font-size: 8px;
  font-weight: normal;
}
.chatorganization-search .search-bar-container {
  position: sticky;
  background: white;
}
.chatorganization-search .search-bar-container div {
  background-color: white;
}
.chatorganization-search .result-container {
  z-index: 1;
  max-height: 400px;
  background: #a3c1f5;
}
.chatorganization-search .result-text h3 {
  color: white;
}
.chatorganization-search .result-text span {
  color: white;
}
.chatorganization-wrapper .result-logo img {
  height: 40px;
  object-fit: contain;
}
.chatorganization-wrapper .result-container {
  position: unset;
  height: 250px;
}
.chatorganization-search .result-wrapper,
.chatorganization-wrapper .result-wrapper {
  padding: 10px 20px;
}
.chatorganization-search .result-text h3,
.chatorganization-wrapper .result-text h3 {
  font-size: 16px;
}
.chatorganization-search .result-text span,
.chatorganization-wrapper .result-text span {
  font-size: 12px;
}
.chatorganization-wrapper .result-button button {
  font-size: 12.5px;
}
.chatorganization-search .result-logo img,
.chatorganization-wrapper .result-logo img {
  width: 40px;
}
.result-text > * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 326px) {
}
