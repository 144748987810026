.notification-container {
  height: 100%;
}
.notification-wrapper {
  width: 70%;
  margin: 0 auto;
  background: white;
  border-radius: 5px;
  height: fit-content;
  margin-top: 25px;
}
.notification-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.notification-lower .footer-para {
  margin-left: 25px;
}
.notification-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification-card-info.notification-grid {
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 0.35fr 2fr;
}
.notification-head {
  color: #51595e;
  font-weight: bold;
  font-size: 18px;
  font-family: var(--montserrat);
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}
.notification-upper {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear-action-button {
  padding: 0px 10px;
  background: #5cbb55 !important;
  text-transform: capitalize;
  box-shadow: none;
}
.notification-logo {
  border-radius: 50%;
  max-width: 60px;
  height: 60px;
  overflow: hidden;
}
.notification-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.notification-data h3 {
  margin-bottom: unset;
  margin-top: 5px;
  font-family: var(--lato);
  font-weight: bold;
  font-size: 16px;
  color: #51595e;
}
.notification-data p {
  font-family: var(--lato);
  margin-top: unset;
  color: #647589;
  font-size: var(--secondary-text);
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  width: 100%;
  /* overflow: hidden; */
}
.notification-card-wrapper {
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707021;
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;
  align-items: center;
}
.delete-notification-ui {
  position: absolute;
  top: 0.5%;
  right: 0.5%;
}
.delete-notification-ui button {
  color: #c0392b;
}
.notification-lower {
  height: 725px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 30px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  align-content: flex-start;
  border-top: solid 2px var(--border-color);
}
.notification-accept {
  margin-left: 10px;
}
.notification-options > div {
  cursor: pointer;
}
.notification-card-info {
  overflow: hidden;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}
.notification-options {
  flex: 0 0 15%;
  justify-content: center;
}
.notification-data {
  width: 100%;
  overflow: hidden;
}
.video-chat-room-created-tag {
  display: flex;
  gap: 20px;
  margin-left: -35%;
  align-self: center;
}
.video-chat-room-created-tag button {
  width: 70px;
  height: 25px;
  border: 2px solid;
  border-radius: 5px;
  opacity: 1;
  background-color: transparent;
  text-align: center;
  font: normal normal 600 11px/21px Montserrat;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.video-chat-room-created-tag button:hover {
  cursor: pointer;
}
.video-chat-room-requested-tag {
  display: flex;
  align-self: center;
  height: 100%;
  align-items: center;
  gap: 12px;
}
.video-chat-room-requested-tag button {
  width: fit-content;
  border: 2px solid;
  opacity: 1;
  background-color: transparent;
  text-align: center;
  font: normal normal 600 11px Montserrat;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.video-chat-room-requested-tag button:hover {
  cursor: pointer;
}
.video-chat-room-delete-button {
  background-color: transparent;
  border-width: 0px;
  height: max-content;
  width: max-content;
}
.video-chat-room-delete-button:hover {
  cursor: pointer;
}
.service-raise-request-icon {
  flex: 0 0 15%;
  display: flex;
  align-items: center;
  position: relative;
}
.service-raise-request-icon a {
  /* position: absolute;
  top: 6px;
  right: 22px; */
  text-decoration: none;
}
.notif-card-wrapper.notif-flex .service-raise-request-icon a {
  top: 20px;
}
.service-raise-request-icon a button {
  font-size: 10px;
  white-space: nowrap;
}
.service-raise-request-icon svg {
  color: gray;
}
.notification-types button {
  box-shadow: none;
}
span.notification-card-timestamp {
  color: rgb(171, 171, 171);
  font-size: 12px;
  text-transform: lowercase;
}
span.notification-types {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0px 30px;
}
span.notification-types *,
span.notification-types {
  overflow: unset;
}
.notification-badge .MuiBadge-badge {
  background-color: #c23616;
  color: #fff;
}
/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .notification-main {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
  }
  .notification-wrapper {
    width: 87.5%;
    margin: unset;
    margin-top: 25px;
  }
  .notification-card-info .notification-logo {
    border: unset;
  }
  span.notification-types button,
  .notification-upper button {
    white-space: nowrap;
    padding-inline: 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1300px) {
  .video-chat-room-created-tag {
    flex-direction: column;
    gap: 5px;
    margin-left: -25%;
  }
  .video-chat-room-requested-tag button {
    font-size: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .notification-lower {
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  }
}

/* tablet */
@media screen and (max-width: 920px) {
  .notification-card-wrapper {
    width: 100%;
  }
  .video-chat-room-created-tag {
    flex-direction: row;
    gap: 20px;
    margin-left: -35%;
  }
}

/* mobile  */
@media screen and (max-width: 600px) {
  .notification-main {
    margin-right: 0;
  }
  .notification-header {
    height: 65px;
  }
  .notification-navigation {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 60px;
  }
  .notification-main {
    height: calc(100% - 130px);
  }
  .notification-wrapper {
    width: 100%;
    margin: unset;
    height: 100%;
    overflow-y: scroll;
  }
  .notification-card-info {
    justify-content: flex-start;
  }
  .notification-card-info .notification-logo {
    width: 50px;
    height: 60px;
  }
  .notification-card-info .notification-content {
    flex: 0 0 75%;
    width: 100%;
    overflow: hidden;
  }
  /* .notification-card-info .notification-content * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } */
  .notification-lower {
    height: unset;
    overflow-y: unset;
    padding: 10px;
  }
  .notification-upper {
    padding: 15px 20px;
  }
  span.notification-types {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 500px) {
  .video-chat-room-created-tag {
    flex-direction: column;
    gap: 5px;
    margin-left: -25%;
  }
  .notification-logo img {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
  .notification-lower {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  }
  .approve-reject-organisation-actions {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 376px) {
  .notification-lower {
    grid-template-columns: 1fr;
  }
  .service-raise-request-icon a button {
    font-size: 10px;
    padding: 5px 8px;
  }
  span.notification-card-timestamp {
    font-size: 10px;
  }
  .notification-card-wrapper {
    padding-right: 5px;
  }
}
@media only screen and (max-width: 376px) {
  .notification-card-info .notification-content * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
