.accept-request-transfer-out-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accept-request-transfer-out-parent * {
  font-family: var(--montserrat);
}
.accept-request-transfer-out-form-body {
  width: 95%;
  max-width: 500px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
}
.accept-request-transfer-out-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 15px 0 20px 0;
}
.accept-request-transfer-out-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 25px;
  gap: 25px;
  margin-top: -15px;
}
.accept-request-transfer-out-content input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  font-size: 16px;
  text-align: left;
}
.accept-request-transfer-out-content button {
  border: unset;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  padding: 10px 50px;
  width: fit-content;
  cursor: pointer;
  align-self: center;
}
.accept-request-transfer-out-heading {
  position: relative;
}
.accept-request-transfer-out-heading .rename-cross {
  top: 5%;
  right: 1%;
}

/* responsive  */

@media screen and (max-width: 320px) {
  .accept-request-transfer-out-content {
    padding: 0 15px;
  }
}
