.rates-and-subscription-wrapper {
  width: 80%;
  max-width: 565px;
  margin: 0 auto;
}
.rates-and-subscription-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
}
.rates-and-subscription-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  cursor: pointer;
  justify-content: center;
  gap: 10px;
  height: 110px;
}
div.active {
  background-color: #efefef;
}
.rates-and-subscription-lower-tile-content-container {
  max-height: 300px;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.rates-and-subscription-lower-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: white;
  cursor: pointer;
  min-height: 92px;
  width: 100%;
  max-width: 424px;
  margin-bottom: 20px;
}
.rates-and-subscription-upper-tile-container {
  display: flex;
  margin-top: 40px;
  column-gap: 30px;
  justify-content: center;
}
.rates-and-subscription-lower-tile-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}
.rates-and-subscription-lower-tile-header-container {
  display: flex;
  width: 75%;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}
.rates-and-subscription-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  margin-top: 40px;
}
.rates-and-subscription-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}

.rates-and-subscription-tile > span {
  display: flex;
  flex-direction: column;
}
.rates-and-subscription-tile span {
  color: #446fa2;
  font-weight: bold;
}
.rates-and-subscription-tile span b {
  font-size: 22px;
}
.rates-and-subscription-lower-tile span {
  color: #446fa2;
  font-weight: bold;
  padding-left: 15px;
}
.rates-and-subscription-lower-tile-header-container span {
  color: #446fa2;
  font-weight: bold;
}
.rates-and-subscription-lower-tile label {
  font-size: 14px;
  letter-spacing: 0px;
  color: #446fa2;
  padding-left: 15px;
}
.rates-and-subscription-lower-tile-button {
  all: unset;
  background-color: #5ca127;
  color: white;
  font-size: 10px;
  padding: 8px 20px 8px 20px;
  border-radius: 7px;
  text-align: center;
  margin-left: 5px;
  white-space: nowrap;
}
.rates-and-subscription-rate-loading-span {
  height: 16px;
  width: 200px;
}
.rates-and-subscription-rate-loading-label {
  height: 16px;
  width: 130px;
}

.rates-and-subscription-subscriptions-loading-span {
  height: 16px;
  width: 180px;
}
.rates-and-subscription-subscriptions-loading-label {
  height: 16px;
  width: 230px;
}
.rates-and-subscription-bottom-subscriptions-loading-top-label {
  height: 13px;
  width: 130px;
}
.rates-and-subscription-bottom-subscriptions-loading-mid-label {
  height: 13px;
  width: 230px;
}
.rates-and-subscription-bottom-subscriptions-loading-bottom-label {
  height: 13px;
  width: 150px;
}
.rates-and-subscription-bottom-subscriptions-loading-span {
  height: 13px;
  width: 185px;
}
.rates-and-subscription-subscribers-loading-span {
  height: 18px;
  width: 180px;
  margin-left: 15px;
}
.rates-and-subscription-subscribers-loading-button {
  height: 27px;
  width: 84px;
  border-radius: 7px;
}
.rates-and-subscription-infoText
{
  position: relative;
}

@media screen and (max-width: 1080px) {
  .rates-and-subscription-wrapper {
    margin-left: 15%;
  }
}

@media screen and (max-width: 730px) {
  .rates-and-subscription-content {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
    column-gap: 20px;
    margin-top: 23px;
  }
  .rates-and-subscription-lower-tile-container {
    margin-top: 10px;
  }
  .rates-and-subscription-lower-tile {
    width: 100%;
  }
  .rates-and-subscription-lower-tile-header-container {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .rates-and-subscription-upper-tile-container {
    flex-direction: column;
    row-gap: 10px;
  }
  .rates-and-subscription-header {
    height: 60px;
  }
  .rates-and-subscription-wrapper {
    width: 90%;
    margin-left: 5%;
    height: calc(100vh - 130px);
    overflow-y: scroll;
    padding-bottom: 10px;
  }
  .rates-and-subscription-heading {
    margin-top: 25px;
  }
  .rates-and-subscription-navigation {
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
}

@media screen and (max-width: 480px) {
  .rates-and-subscription-subscribers-loading-span {
    width: 150px;
  }
  .rates-and-subscription-subscribers-loading-button {
    height: 23px;
    width: 70px;
  }
}

@media screen and (max-width: 445px) {
  .rates-and-subscription-subscriptions-loading-span {
    width: 120px;
  }
  .rates-and-subscription-subscriptions-loading-label {
    width: 170px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-top-label {
    width: 90px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-mid-label {
    width: 170px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-bottom-label {
    width: 120px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-span {
    width: 145px;
  }
}

@media screen and (max-width: 410px) {
  .rates-and-subscription-lower-tile {
    height: unset;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 400px) {
  .rates-and-subscription-rate-loading-span {
    width: 150px;
  }
  .rates-and-subscription-rate-loading-label {
    width: 110px;
  }
  .rates-and-subscription-subscribers-loading-span {
    width: 130px;
  }
  .rates-and-subscription-subscribers-loading-button {
    height: 20px;
    width: 55px;
  }
}

@media screen and (max-width: 378px) {
  .rates-and-subscription-subscriptions-loading-span {
    width: 80px;
  }
  .rates-and-subscription-subscriptions-loading-label {
    width: 120px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-top-label {
    width: 75px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-mid-label {
    width: 120px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-bottom-label {
    width: 80px;
  }
  .rates-and-subscription-bottom-subscriptions-loading-span {
    width: 100px;
  }
}

@media screen and (max-width: 350px) {
  .rates-and-subscription-rate-loading-span {
    width: 130px;
  }
  .rates-and-subscription-rate-loading-label {
    width: 90px;
  }
  .rates-and-subscription-subscribers-loading-span {
    width: 120px;
  }
  .rates-and-subscription-subscribers-loading-button {
    height: 18px;
    width: 45px;
  }
}
