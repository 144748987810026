.manage-storage-plan-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.manage-storage-plan-parent * {
  font-family: var(--montserrat);
}
.manage-storage-plan-main {
  width: 90%;
  max-width: 810px;
  max-height: 85%;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 20px;
}
.manage-storage-plan-main p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}
.manage-storage-plan-body {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.manage-storage-plan-wrapper {
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  max-height: 90%;
  gap: 20px;
  padding: 0 5px 8px;
  overflow: scroll;
}
.manage-storage-plan-heading {
  position: relative;
  margin-top: -14px;
}
.manage-storage-plan-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.manage-storage-plan-wrapper-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 190px;
  gap: 5px;
}
.manage-storage-plan-wrapper-content label {
  font-family: var(--lato);
  font-size: 16px;
  font-weight: bold;
  color: #51595e;
}
.manage-storage-plan-wrapper-content-card {
  all: unset;
  display: flex;
  height: 68px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  padding-left: 5px;
  position: relative;
  margin-top: 30px;
}
.manage-storage-plan-wrapper-content-card-buttons {
  display: flex;
  position: absolute;
  top: -30px;
  right: 0;
}
.manage-storage-plan-edit-icon {
  font-size: 16px !important;
  color: #4671a4;
}
.manage-storage-plan-delete-icon {
  font-size: 16px !important;
  color: #cb1212;
}
.manage-storage-plan-wrapper-content-card-buttons button {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 5px;
  color: #434343;
}
.manage-storage-plan-wrapper-content-card-buttons button:last-child {
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 5px;
  color: #434343;
}
.manage-storage-plan-wrapper-content-card-buttons button:last-child:hover {
  background: rgba(229, 229, 229, 0.5) 0% 0% no-repeat padding-box;
}
.manage-storage-plan-wrapper-content-card-labels {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.manage-storage-plan-wrapper-content:first-child {
  width: 126px;
}
.manage-storage-plan-wrapper-content-card label {
  font-family: var(--montserrat);
  font-size: 20px;
  font-weight: bold;
  color: #446fa2;
  letter-spacing: 1px;
}
.manage-storage-plan-wrapper-content-card span {
  font-family: var(--montserrat);
  font-size: 16px;
  font-weight: bold;
  color: #51595e;
}
.manage-plan-create-button {
  all: unset;
  color: white;
  width: 176px;
  height: 39px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  background-color: rgb(92, 161, 39);
  margin-top: 15px;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
  .manage-storage-plan-main p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 467px) {
  .manage-storage-plan-main {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 456px) {
  .manage-storage-plan-main {
    padding-bottom: 75px;
  }
}
@media screen and (max-width: 330px) {
}
