.organisation-info-popup-wrapper {
  display: grid;
  place-content: center;
  position: fixed;
  inset: 0;
  z-index: 9;
  background-color: rgb(0 0 0 / 50%);
}
.organisation-info-popup-main {
  padding: 25px 50px;
  width: min(96vw, 768px);
  border-radius: 5px;
  background-color: #fff;
}
.organisation-info-popup-flex button {
  display: block;
  margin-left: auto;
  width: max-content;
  border: none;
  padding: 0;
  background-color: transparent;
  color: #828282;
}
.organisation-info-popup-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 600px){
  .organisation-info-popup-main {
    padding: 25px;
  }
}
