.agreement-popup-wrapper {
  display: grid;
  place-items: center;
  place-content: center;
  position: fixed;
  inset: 0;
  z-index: 9;
  background: #00000040 0% 0% no-repeat padding-box;
}
.agreement-popup-card {
  max-width: 554px;
  width: 90%;
  min-height: 176px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 11px;
  position: relative;
}
.agreement-popup-card-header button {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--lato);
  transition: opacity 300ms ease;
  position: absolute;
  right: 11px;
  top: 11px;
}
.agreement-popup-card-header button:hover {
  opacity: 0.5;
}
.agreement-popup-card-body label {
  display: block;
  text-align: center;
  font: normal normal 600 22px/27px var(--montserrat);
  letter-spacing: 0px;
  color: #707070;
}
.agreement-popup-card-body p {
  text-align: center;
  font: normal normal normal 18px/24px var(--lato);
  letter-spacing: 0px;
  color: #51595e;
  margin-top: 10px;
  margin-bottom: 0;
}
.agreement-popup-card-actions {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
  margin-bottom: 23px;
}
.agreement-popup-card-actions input{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.agreement-popup-card-actions button {
  width: 174px;
  height: 39px;
  background: #dcdcdc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border-width: 0;
  text-align: center;
  font: normal normal 600 14px/16px var(--montserrat);
  letter-spacing: 0px;
  color: #949494;
}
.agreement-popup-card-actions button:not([disabled]) {
  background-color: #5ca127;
  color: #fff;
}
