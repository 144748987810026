@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px !important;
  height: 50px !important;
  border: 10px solid #E3ECFC !important;
  border-top: 10px solid #446FA2 !important;
  border-radius: 50% !important;
  animation: spinner 0.8s linear infinite !important;
}
.loading-wrapper {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 10 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  top: 0 !important;
  left: 0 !important;
}
