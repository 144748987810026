.share-folder-link-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.share-folder-link-parent * {
  font-family: var(--montserrat);
}
.share-folder-link-main {
  width: 95%;
  max-width: 550px;
  height: max-content;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}
.share-folder-link-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}
.share-folder-link-wrapper {
  max-width: 95%;
  margin: 20px auto auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.share-folder-link-wrapper input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
}
.share-folder-link-heading {
  position: relative;
  margin-top: -14px;
}
.share-folder-link-heading .rename-cross {
  top: 5%;
  right: 1%;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .share-folder-link-heading p {
    font-size: 16px !important;
  }
  .share-folder-link-wrapper button {
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
