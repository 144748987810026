.transfer-and-banking-wrapper {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}
.transfer-and-banking-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
}
.transfer-and-banking-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  cursor: pointer;
  position: relative;
}
.transfer-and-banking-tile-internal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.transfer-and-banking-tile-internal-wrapper > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #4671a4;
  width: 47.5%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}
.transfer-and-banking-lower-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  cursor: pointer;
  height: 92px;
  width: 50%;
}
.transfer-and-banking-lower-tile-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.transfer-and-banking-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 30px;
  margin-top: 20px;
}
.transfer-and-banking-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.transfer-and-banking-tile > span {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.transfer-and-banking-tile span {
  color: #446fa2;
  font-weight: bold;
}
.transfer-and-banking-tile span b {
  font-size: 22px;
}
.transfer-and-banking-lower-tile span {
  color: #446fa2;
  font-weight: bold;
  padding-left: 20px;
}

.transfer-banking-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.transfer-and-banking-lower-tile-text {
  display: flex;
  align-items: center;
}
.transfer-and-banking-info {
  position: absolute;
  top: 3px;
  right: 5px;
  z-index: 3;
  background: #446fa2;
  border-radius: 50%;
  color: #fff;
  font-size: 18px !important;
}
@media screen and (max-width: 1080px) {
  .transfer-and-banking-wrapper {
    margin-left: 15%;
  }
}

@media screen and (max-width: 730px) {
  .transfer-and-banking-content {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
    column-gap: 20px;
    margin-top: 10px;
  }
  .transfer-and-banking-lower-tile-container {
    margin-top: 10px;
  }
  .transfer-and-banking-lower-tile {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .transfer-and-banking-header {
    height: 60px;
  }
  .transfer-and-banking-wrapper {
    width: 90%;
    margin-left: 5%;
    height: calc(100vh - 130px);
    overflow-y: scroll;
    padding-bottom: 10px;
  }
  .transfer-and-banking-heading {
    margin-top: 25px;
  }
  .transfer-and-banking-navigation {
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
}

@media screen and (max-width: 355px) {
  .transfer-and-banking-tile {
    min-height: 110px;
    height: max-content !important;
  }
  .transfer-and-banking-tile-internal-wrapper > div {
    padding: 10px 0;
  }
}
