.money-credit-transaction-receipt-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  overflow-y: scroll;
}
.money-credit-transaction-receipt-parent * {
  font-family: var(--montserrat);
}
.money-credit-transaction-receipt-main {
  width: 100%;
  margin: 0 auto;
  padding: 5% 0;
}
.money-credit-transaction-receipt-form-body {
  max-width: 30%;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
}
.money-credit-transaction-receipt-wrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 25px;
}
.money-credit-transaction-receipt-heading {
  position: relative;
  border-bottom: solid 1px #c1c1c1;
  text-align: center;
  padding: 5px 0;
}
.money-credit-transaction-receipt-heading .rename-cross {
  top: 22.5%;
  right: 1%;
}
.money-credit-transaction-receipt-top h3 {
  color: #32325d;
  font-size: 24px;
  font-weight: normal;
  margin-top: 25px;
  margin-bottom: 10px;
}
.money-credit-transaction-receipt-top span {
  color: #8898aa;
  font-size: 15px;
}
.money-credit-transaction-receipt-mid {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.money-credit-transaction-receipt-mid-content span {
  color: #525f7f;
  font-size: 15px;
}
.money-credit-transaction-receipt-mid-content h6 {
  color: #8898aa;
  font-size: 12px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.money-credit-transaction-receipt-bot {
  padding-bottom: 50px;
  border-bottom: solid 1px #c1c1c1;
}
.money-credit-transaction-receipt-img img {
  width: 100%;
}
.money-credit-transaction-receipt-bot h4 {
  color: #687385;
}
.money-credit-transaction-receipt-bot-box {
  background: #f6f9fc;
  height: 120px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
}
.money-credit-transaction-receipt-bot-box div:first-child {
  border-bottom: solid 1px #c1c1c1;
}
.money-credit-transaction-receipt-bot-box div:last-child {
  color: #525f7f;
  font-weight: bold;
}
.money-credit-transaction-receipt-bot-box div {
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.money-credit-transaction-receipt-email-box {
  padding-top: 50px;
  padding-bottom: 25px;
}
.money-credit-transaction-receipt-email-box span {
  color: #414552;
}
.money-credit-transaction-receipt-email-box a {
  color: #625afa;
  font-weight: bold;
  text-decoration: none;
  display: block;
}
/* responsive  */

/* laptop */
@media screen and (max-width: 1600px) {
  .money-credit-transaction-receipt-form-body {
    max-width: 45%;
  }
}

@media screen and (max-width: 1075px) {
  .money-credit-transaction-receipt-form-body {
    max-width: 65%;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */

@media screen and (max-width: 700px) {
  .money-credit-transaction-receipt-form-body {
    max-width: 85%;
  }
}
@media screen and (max-width: 600px) {
  .money-credit-transaction-receipt-main {
    width: 100%;
    padding: 15% 0;
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 476px) {
  .money-credit-transaction-receipt-form-body {
    max-width: 95%;
  }
  .money-credit-transaction-receipt-mid {
    flex-wrap: wrap;
  }
  .money-credit-transaction-receipt-mid-content {
    width: 100%;
  }
}
