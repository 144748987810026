.qr-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 6;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.qr-popup {
  width: 96%;
  max-width: 725px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  padding: 50px 0 25px 0;
  border-radius: 5px;
}
.qr-cross {
  position: absolute;
  top: 2.5%;
  right: 2.5%;
  font-weight: bold;
  cursor: pointer;
  /* background: #446fa2; */
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
}
.qr-body img {
  width: 75px;
  height: 75px;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: contain;
}
.qr-body .amnius-logo {
  width: 100px;
  height: 75px;
  aspect-ratio: 1;
  border-radius: 0;
  object-fit: contain;
}
.qr-title + img {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: contain;
}
.qr-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: var(--montserrat);
}
.qr-pdf {
  justify-self: end;
}
.qr-body {
  padding-block: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 100%;
}
.qr-row {
  width: 100%;
  display: flex;
  margin-top: 30px;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.qr-grid .qr-title {
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  /* justify-self: flex-end; */
}
.qr-print-btn {
  position: absolute;
  right: 30px;
  bottom: 18%;
}

@-moz-document url-prefix() {
  .qr-code-connected-folder {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 600px) {
  .qr-print-btn {
    bottom: 5%;
  }
  .qr-body img {
    width: 50px !important;
    height: 50px !important;
  }
}

@media screen and (max-width: 426px) {
}
