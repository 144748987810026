.edit-profile-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-profile-container > div {
  width: 55%;
  margin: 0 auto;
  height: fit-content;
  max-height: 80%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  padding: 25px 50px;
  overflow-y: scroll;
}
.edit-profile-head {
  font-family: var(--montserrat);
  font-weight: 700;
}
.edit-profile-top-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-profile-logo {
  display: flex;
  flex-direction: column;
}
.edit-profile-logo input {
  padding: unset;
}
.edit-profile-logo label {
  margin-bottom: 15px;
  margin-top: 10px;
}
button.form-button {
  margin-top: 25px;
}
.edit-profile-office {
  display: flex;
  justify-content: space-between;
}
.edit-profile-office > div {
  width: 74%;
}
.edit-profile-office > div:last-child {
  width: 24%;
}
.edit-user-scope select {
  width: 100%;
  border: solid 1px #c4c4c4;
  height: 42.5px;
  border-radius: 5px;
  padding: 0 10px;
  color: #5f5f5f;
  font-size: 16px;
  outline: unset;
  background-color: #fff;
}
.edit-user-scope label {
  display: block;
  margin-bottom: 2.5px;
  margin-top: 10px;
}
.edit-profile-form input {
  padding: 12px 10px;
}

.edit-profile-category.hide-dropdown .category-list-container {
  display: none;
}
.edit-profile-category {
  position: relative;
}
.category-list-container {
  position: absolute;
  width: 100%;
  z-index: 2;
}
.category-list-wrapper {
  background: #a3c1f5;
  border: solid 1px #c4c4c4;
  padding: 10px;
  color: white;
  cursor: pointer;
}
.category-list-wrapper:hover {
  background: #446fa2;
}

.error {
  color: #cb1212;
}

.edit-profile-name {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1300px) {
  .edit-profile-container > div {
    width: 75%;
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .edit-profile-container > div {
    width: 95%;
  }
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .edit-profile-form input {
    padding: 12.5px 10px;
    font-family: var(--montserrat);
    color: black;
    font-size: 14px;
  }
  .edit-profile-form label {
    top: -3px;
    font-family: var(--lato);
    color: black;
    font-size: 18px;
  }
  .edit-profile-logo label {
    margin-bottom: unset;
  }
  .edit-profile-office {
    flex-direction: column;
  }
  .edit-profile-office > div {
    width: 100% !important;
  }
  .add-user-office {
    flex-direction: column;
  }
  div.add-user-office > div:last-child {
    width: unset;
  }
}
@media screen and (max-width: 400px) {
}
