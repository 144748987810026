.file_explorer_folder_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 35px;
  cursor: pointer;
}
.file_explorer_folder_wrapper > div {
  display: flex;
  align-items: center;
}
.file_explorer_folder_wrapper img {
  width: 57px;
  height: fit-content;
}
.file_explorer_folder_wrapper p {
  font-family: var(--lato);
  font-size: var(--primary-text);
  font-weight: bold;
  color: #51595e;
  text-transform: capitalize;
  margin: unset;
  margin-left: 15px;
}
.file_explorer_folder_wrapper button img {
  width: 20px;
}
.visit-page-cta {
  color: #5ca127 !important;
  font-family: var(--montserrat) !important;
  border-color: #5ca127 !important;
}
.file_explorer_folder_wrapper > div:first-child {
  flex: 0 0 60%;
  overflow: hidden;
}
.file_explorer_folder_wrapper > div:first-child p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
}
.file_explorer_folder_wrapper > div button {
  font-size: 10px;
  font-weight: bold;
}
.visit-icon {
  display: flex;
  align-items: center;
  border: solid 2px #5ca127;
  margin-right: 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: max-content;
}
.visit-icon img {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  object-fit: contain;
}
.alias-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
span.hover-text {
  font-size: 10px;
  color: #51595e;
  border: solid 1px #51595e;
  border-radius: 10px;
  padding: 2.5px 5px;
  width: fit-content;
  max-width: 65%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 15px;
}
.selected_folder {
  background-color: #ecf0f1;
}
