.desktop-footer {
  display: block;
}
.mobile-footer {
  display: none;
}

.navigation-container {
  position: fixed;
  top: 110px;
  left: 2.5%;
}
.navigation-wrapper {
  background: #a3c1f5;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 15px;
  border-radius: 5px;
}
.card-box {
  background: #e4edfc;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.card-box .sms-alert {
  font-size: 20px;
  color: #c23616;
  position: absolute;
  top: 2.5%;
  right: 0;
}
.card-box svg {
  color: #446fa2;
  font-size: 27.5px;
}
.card-box:last-child {
  margin-bottom: unset;
}
.user-profile {
  border-bottom: solid 1px white;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.user-profile img {
  border-radius: 50%;
  object-fit: cover;
}
.nav-item {
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .navigation-container {
    left: 1%;
  }
}

@media screen and (max-width: 1300px) {
  .navigation-container {
    left: 0.5%;
  }
}

@media screen and (max-width: 1024px) {
  .navigation-container {
    left: 0;
  }
}

@media screen and (max-width: 900px) {
  .navigation-container {
    left: 1%;
  }
}

@media screen and (max-width: 768px) {
  .card-box {
    background: #e4edfc;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 10px 0;
  }
  .user-profile {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .desktop-footer {
    display: none;
  }
  .mobile-footer {
    display: block;
  }
  .navigation-container {
    position: unset;
    top: 0;
    left: 0;
  }
  .navigation-wrapper {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    margin: 0 auto;
  }
  .nav-item.user-profile {
    border: unset;
    padding: 0 0;
  }
  .nav-item.card-box {
    margin: 10px 10px;
  }
  .nav-item.card-box {
    margin: 10px 5px;
  }
  .nav-item.user-profile {
    display: flex;
  }
  .nav-item-drop-up {
    position: absolute;
    bottom: 45px;
    background: #a3c1f5;
    border-radius: 5px;
    padding: 0 3px;
  }
  .nav-item.card-box.hide-dropdown .nav-item-drop-up {
    display: none;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 326px) {
  .nav-item.card-box {
    margin: 10px 2.5px;
  }
}
