.default-payment-parent {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
.default-payment-parent * {
  font-family: var(--montserrat);
}
.default-payment-main {
  width: 95%;
  max-width: 500px;
  background-color: white;
  border-radius: 5px;
}
.default-payment-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  border-bottom: solid 1px #e1e1e1;
}
.default-payment-wrapper {
  max-width: 100%;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.default-payment-card {
  width: 90%;
  height: max-content;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #4671a4;
}
.default-payment-card div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.default-payment-card img {
  height: 45px;
  width: 45px;
}
.default-payment-card label {
  font-size: 16px;
  color: #51595e;
  font-weight: bold;
}
.default-payment-card button {
  all: unset;
  color: white;
  height: 28px;
  width: 81px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}
.default-payment-card button:hover {
  cursor: pointer;
}
.default-payment-heading {
  position: relative;
  margin-top: -20px;
}
.default-payment-heading .rename-cross {
  top: 5%;
  right: 1%;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */

@media screen and (max-width: 740px) {
}
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
