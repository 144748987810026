.popup-container-delete {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.popup-popup {
  width: 40%;
  margin: 0 auto;
  position: relative;
}
.popup-cross {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  cursor: pointer;
  /* background: #446fa2; */
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
}
.popup-popup span {
  display: block;
  margin-bottom: 10px;
  margin-top: 25px;
}
.popup-option-button {
  display: flex;
  justify-content: space-between;
}
.confirm-box {
  padding: 50px 25px;
  background: #f5f5f5;
  border-radius: 5px;
}
div.confirm-cross {
  top: 5%;
  right: 2.5%;
}
.confirm-cross + span {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .popup-popup {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .popup-popup {
    width: 65%;
  }
}
@media screen and (max-width: 600px) {
  .confirm-box {
    padding: 25px;
  }
  .popup-popup span {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 426px) {
  .popup-popup {
    width: 90%;
  }
}
