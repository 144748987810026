.add-amnius-admin-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: flex;
  align-items: center;
}
.add-amnius-admin-container > div {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  padding: 25px;
}
.add-amnius-admin-head {
  font-size: 18px;
  font-family: var(--montserrat);
  font-weight: 700;
}
.add-amnius-admin-top-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -10px;
}
.add-amnius-admin-form input {
  font-size: 16px;
}
.add-amnius-admin-form button {
  all: unset;
  background-color: #446FA2;
  padding: 9px 17px;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  font-family: var(--montserrat);
  margin-top: 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
button:hover {
  cursor: pointer;
}