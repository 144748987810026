.forget-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(255 254 254 / 99%);
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.forget-popup {
  width: 40%;
  margin: 0 auto;
  position: relative;
}
.forget-popup form {
  display: flex;
  flex-direction: column;
}
.forget-cross {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  cursor: pointer;
  /* background: #446fa2; */
  padding: 5px 10px;
  color: black;
  border-radius: 5px;
}
.forget-popup span {
  display: block;
  margin-bottom: 10px;
  margin-top: 25px;
}
.forget-email {
  margin-bottom: 25px;
}

.forget-email span {
  all: unset !important;
  background-color: white !important;
}

@media screen and (max-width: 1024px) {
  .forget-popup {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .forget-popup {
    width: 65%;
  }
}

@media screen and (max-width: 426px) {
  .forget-popup {
    width: 90%;
  }
}
