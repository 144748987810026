.org-storage-dashboard-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.org-storage-dashboard-wrapper {
  height: 100%;
  width: 100%;
  padding-left: 9.5%;
}
.org-storage-dashboard-wrapper-content {
  display: flex;
  gap: 20px;
  width: 80%;
  margin-top: 37px;
}
.org-storage-dashboard-wrapper-content label {
  font-size: 18px;
  font-family: var(--montserrat);
  font-weight: bold;
  letter-spacing: 0px;
  color: #51595e;
}
.org-storage-dashboard-wrapper-content button {
  font-size: 12px;
  font-family: var(--montserrat);
  font-weight: bold;
  width: 136px;
  height: 34px;
  white-space: nowrap;
}
.org-storage-dashboard-wrapper aside {
  background-color: white;
  width: 40%;
  height: 70%;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  height: max-content;
  position: relative;
}
.org-storage-dashboard-pie-chat-ceter-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.org-storage-dashboard-pie-chat-ceter-label label:first-child {
  font-weight: bolder;
  font-size: 30px;
  letter-spacing: 2px;
}
.org-storage-dashboard-pie-chat-ceter-label label:last-child {
  font-weight: bolder;
}
.org-storage-dashboard-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 2px solid #f5f5f5;
  height: 56px;
}
.org-storage-dashboard-wrapper section {
  width: 60%;
}
.org-storage-dashboard-section-header-content {
  display: flex;
  gap: 20px;
  background-color: #f5f5f5;
}
.org-storage-dashboard-section-header-left-side {
  width: 50%;
  border-radius: 5px;
  background-color: white;
}
.org-storage-dashboard-section-header-right-side {
  width: 50%;
  border-radius: 5px;
  background-color: white;
}
.org-storage-dashboard-section-header-right-side-body {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: 10px;
}
.org-storage-dashboard-section-header-right-side-body div {
  display: flex;
  align-content: center;
  gap: 10px;
}
.org-storage-dashboard-section-header-left-side-body {
  display: flex;
  align-content: center;
  gap: 10px;
  padding: 0px 20px;
  margin-top: 10px;
}
.org-storage-dashboard-section-header-left-side span,
.org-storage-dashboard-section-header-right-side span {
  text-align: center;
  font-family: var(--montserrat);
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  background-color: #446fa2;
  padding: 2px 5px;
  margin-left: 20px;
}
.org-storage-dashboard-section-header-left-side label,
.org-storage-dashboard-section-header-right-side label,
.org-storage-dashboard-pie-chat-ceter-label label {
  align-self: center;
  color: #4671a4;
}
.org-storage-dashboard-section-header-left-side p,
.org-storage-dashboard-section-header-right-side p {
  align-self: center;
  color: #51595e;
  font-size: 12px;
  font-family: var(--montserrat);
  font-weight: bold;
}
.org-storage-dashboard-section-body-content {
  border-radius: 5px;
  margin-top: 25px;
  background-color: white;
}
.org-storage-dashboard-section-body-content-cards-container {
  display: grid;
  padding: 20px 20px 36.5px;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.org-storage-dashboard-section-body-content-cards {
  display: flex;
  gap: 15px;
  padding: 15px 20px;
  align-items: center;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
}
.org-storage-dashboard-cards-color {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}
.org-storage-dashboard-section-body-content-cards span {
  font-size: 12px;
  font-family: var(--montserrat);
  color: #51595e;
  font-weight: bold;
}

@media screen and (max-width: 1680px) {
  .org-storage-dashboard-wrapper-content {
    width: 90%;
  }
}
@media screen and (max-width: 1480px) {
  .org-storage-dashboard-wrapper-content {
    width: 97%;
  }
}
@media screen and (max-width: 1370px) {
  .org-storage-dashboard-section-header-right-side-body {
    flex-direction: column;
  }
}
@media screen and (max-width: 1270px) {
  .org-storage-dashboard-section-body-content-cards-container {
    grid-template-columns: repeat(1, 1fr);
    height: 314px;
    overflow: scroll;
  }
}
@media screen and (max-width: 1170px) {
  .org-storage-dashboard-section-header-content {
    flex-direction: column;
  }
  .org-storage-dashboard-section-header-left-side,
  .org-storage-dashboard-section-header-right-side {
    width: 100%;
  }
  .org-storage-dashboard-section-header-right-side-body {
    flex-direction: row;
  }
}
@media screen and (max-width: 1045px) {
  .org-storage-dashboard-wrapper aside {
    width: 45%;
  }
  .org-storage-dashboard-wrapper section {
    width: 55%;
  }
}
@media screen and (max-width: 900px) {
  .org-storage-dashboard-wrapper {
    padding-left: 10%;
  }
}

@media screen and (max-width: 870px) {
  .org-storage-dashboard-wrapper {
    padding-left: 15%;
  }
  .org-storage-dashboard-wrapper-content {
    flex-direction: column;
    width: 96%;
  }
  .org-storage-dashboard-wrapper aside,
  .org-storage-dashboard-wrapper section {
    width: 100%;
  }
  .org-storage-dashboard-section-body-content-cards-container {
    height: unset;
    overflow: unset;
  }
}

@media screen and (max-width: 600px) {
  .org-storage-dashboard-wrapper {
    padding-left: 4%;
  }
  .org-storage-dashboard-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .org-storage-dashboard-section-body-content {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 450px) {
  .org-storage-dashboard-wrapper-content button {
    font-size: 10px;
    width: 110px;
    height: 30px;
  }
}

@media screen and (max-width: 380px) {
  .org-storage-dashboard-section-header-right-side-body {
    flex-direction: column;
  }
}
