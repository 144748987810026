.refund-request-form-super-admin-wrapper {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .refund-request-form-super-admin-header {
    position: sticky;
    top: 0;
    z-index: 5;
    height: 75px;
  }
  .refund-request-form-super-admin-form-body {
    max-width: 800px;
    background-color: white;
    border-radius: 5px;
    margin: 0 auto;
  }
  .refund-request-form-super-admin-form-heading p {
    font-size: 20px;
    color: #51595e;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
    border-bottom: solid 1px #e1e1e1;
  }
  .refund-request-form-super-admin-form-wrapper {
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .refund-request-form-super-admin-fields {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .refund-request-form-super-admin-fields label {
    font-size: 14px;
    color: #51595e;
    font-weight: bold;
    flex-basis: 40%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .refund-request-form-super-admin-fields input {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
  }
  .refund-request-form-super-admin-fields button {
    border: unset;
    border-radius: 5px;
    background: #5ca127;
    color: white;
    padding: 10px 50px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
  }
  .refund-request-form-super-admin-form-heading{
    position: relative;
  }
  
  @media screen and (max-width: 1080px) {
    .refund-request-form-super-admin-wrapper {
      margin-left: 15%;
    }
  }

  @media screen and (max-width: 940px) {
    .refund-request-form-super-admin-fields {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 900px) {
    .refund-request-form-super-admin-fields label {
      padding-left: 10px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .refund-request-form-super-admin-upper-tile-container {
      flex-direction: column;
      row-gap: 10px;
    }
    .refund-request-form-super-admin-wrapper {
      margin-left: 10%;
    }
  }

  @media screen and (max-width: 420px) {
    .refund-request-form-super-admin-wrapper {
        max-width: 90%;
        margin-left: 5%;
    }
  }

  
  