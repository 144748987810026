.money-credit-transcation-main-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.money-credit-transcation-content {
  width: 68.5%;
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 25px;
}
.money-credit-transcation-header {
  font-size: 30px;
  color: #707070;
  font-weight: bold;
  font-family: var(--montserrat);
  text-align: center;
  margin-top: 50px;
}
.money-credit-transcation-top-part {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}
.money-credit-transcation-head-row-wrapper {
  display: flex;
  font-size: 10px;
  font-family: var(--montserrat);
  background: #4671a4;
  color: white;
  padding: 15px 0;
}
.money-credit-transcation-head-row {
  text-align: center;
}
.money-credit-transcation-body-row {
  color: #51595e;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  word-break: break-all;
}
.money-credit-transcation-body-row-wrapper {
  display: flex;
  font-size: 14px;
  font-family: var(--lato);
  padding: 15px 0;
  border-bottom: 1px solid #e1e1e1;
}
.money-credit-transcation-filter {
  display: flex;
  align-items: center;
}
.money-credit-transcation-filter p {
  color: #51595e;
  font-family: var(--lato);
  font-size: 14px;
  font-weight: bold;
}
.money-credit-transcation-body-row img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
span.money-credit-transcation-body-credit {
  color: #4acb12;
  font-weight: bold;
}
span.money-credit-transcation-body-debit {
  color: #cb1212;
  font-weight: bold;
}
.money-credit-transcation-filter select {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #51595e;
  font-family: var(--lato);
  font-size: 14px;
  height: 30px;
  margin-left: 5px;
  width: 85px;
  outline: none;
}
.money-credit-transcation-search {
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: flex;
  height: 26px;
}
.money-credit-transcation-search input {
  border: unset;
  color: #51595e;
  font-family: var(--montserrat);
  font-size: 12px;
  outline: none;
}
.money-credit-transcation-balance span {
  font-family: var(--lato);
  color: #446fa2;
  font-size: 12px;
  font-weight: bold;
}
.money-credit-transcation-balance b {
  color: #446fa2;
  font-family: var(--lato);
  font-size: 40px;
}
.money-credit-transcation-body-row-scroll {
  max-height: 600px;
  min-height: 100px;
  overflow-y: scroll;
}
.money-credit-transcation-wrapper {
  padding-bottom: 50px;
}

@media screen and (max-width: 1600px) {
  .money-credit-transcation-content {
    width: 75%;
  }
}
@media screen and (max-width: 1300px) {
  .money-credit-transcation-content {
    width: 85%;
  }
}
@media screen and (max-width: 1075px) {
  .money-credit-transcation-content {
    transform: translate(5%, 0);
    width: 90%;
  }
  .money-credit-transcation-header {
    text-align: right;
    margin-right: 25%;
  }
}
@media screen and (max-width: 1000px) {
  .money-credit-transcation-content {
    overflow-x: scroll;
    width: 80%;
  }
  .money-credit-transcation-top-part,
  .money-credit-transcation-bottom-part {
    width: 1000px;
  }
}
@media screen and (max-width: 800px) {
  .money-credit-transcation-header {
    margin-right: 20%;
  }
}

@media screen and (max-width: 600px) {
  .money-credit-transcation-header {
    text-align: center;
    margin-right: unset;
  }
  .money-credit-transcation-content {
    width: 95%;
    transform: unset;
  }
  .money-credit-transcation-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .money-credit-transcation-wrapper {
    padding-bottom: 100px;
  }
  .money-credit-transcation-body-row-wrapper {
    font-size: 12px;
    padding: 10px 0;
  }
  .money-credit-transaction-receipt-form-body {
    max-width: 98%;
  }
}
