html,
body {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}
* {
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&display=swap");
:root {
  --primary-text: 16px;
  --secondary-text: 14px;
  --primary-heading: 18px;
  --button-text: 12px;
  --border-color: #ededed;
  --primary-color: #a3c1f5;
  --secondary-color: #446fa2;
  --text-color: #51595e;
  --heading-color: #51595e;
  --lato: "Lato", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --accent-color: #0074d4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

p,
span,
label {
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* alternative to disable zoom */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
div#root {
  height: 100%;
}
.App {
  height: 100%;
  margin-bottom: 50px;
}
@-moz-document url-prefix() {
  .App {
    margin-bottom: 0px;
  }
}

/* video chat */
.peer-video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

/* scroll bar  */
::-webkit-scrollbar {
  width: 0px;
}

/* navigation card */
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #2e7d32 !important;
}
