.group-popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9 !important;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.group-popup-popup {
  width: 50%;
  margin: 0 auto;
  position: relative;
  background: white;
  padding: 35px 50px;
  border-radius: 5px;
}
.group-popup-popup form {
  display: flex;
  flex-direction: column;
}
.group-together {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.group-popup-cross {
  font-weight: bold;
  cursor: pointer;
  /* background: #446fa2; */
  padding: 5px 10px;
  color: black;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
}
.group-popup-popup span.title {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}
.group-popup-name {
  margin-bottom: 10px;
}
label.group-popup-image {
  margin-bottom: 15px;
}
input#group-image {
  margin-left: 15px;
}
.group-popup-name label span,
label.group-popup-image span {
  margin-right: 5px;
}
input#group-name {
  border-radius: 5px;
  border-color: #446fa2;
  outline: none;
  height: 30px;
  width: 65%;
}
form.group-popup-organization button {
  margin-top: 15px;
  width: fit-content;
  cursor: pointer;
  background: #446fa2;
}
.group-popup-checkbox {
  display: flex;
  align-items: center;
  margin: 2.5px 0;
  border: solid 1px #446fa2;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 0;
}
.group-popup-checkbox img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.group-popup-checkbox > * {
  margin: 0 7.5px;
}
.group-popup-list {
  height: 350px;
  overflow-y: scroll;
}
.group-popup-connected,
.group-popup-disconnected {
  overflow-y: scroll;
  max-height: 150px;
}
@media screen and (max-width: 1024px) {
  .group-popup-popup {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .group-popup-popup {
    width: 65%;
  }
}

@media screen and (max-width: 426px) {
  .group-popup-popup {
    width: 90%;
  }
}
