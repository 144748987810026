.stdetails-left .content.with-chat .chat-screen-inputsection label {
  display: none;
}

.stdetails-left .right-side-chat li div.nested-feature {
  position: absolute;
  bottom: -15px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.stdetails-left .right-side-chat li div.nested-feature > span {
  position: unset;
  color: #446fa2;
  font-size: 10px;
  cursor: pointer;
  font-weight: bold;
}
.stdetails-left .right-side-chat .chat-screen-inputsection {
  width: 650px;
  margin: 0 auto;
  display: flex;
  flex-direction: row !important;
  border-top: unset !important;
  align-items: flex-start;
  justify-content: flex-end !important;
}
.stdetails-left .left-side-chat .chat-screen-inputsection {
  width: 650px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  border-top: unset !important;
  align-items: flex-start;
  justify-content: flex-start;
}
.stdetails-left .left-side-chat .chat-screen-inputsection .chat-screen-input {
  width: 300px;
}
.stdetails-left .right-side-chat .chat-screen-inputsection .chat-screen-input {
  width: 300px;
}
.stdetails-left
  .left-side-chat
  .chat-screen-inputsection
  .chat-screen-input
  textarea {
  font-size: 14px;
}
.stdetails-left
  .right-side-chat
  .chat-screen-inputsection
  .chat-screen-input
  textarea {
  font-size: 14px;
}

.stdetails-left
  .right-side-chat
  li
  div.nested-feature
  .chat-screen-inputsection
  textarea#textarea-id {
  font-size: 12px;
}

.stdetails-left .left-side-chat li div.nested-feature {
  position: absolute;
  bottom: -15px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.stdetails-left .left-side-chat li div.nested-feature > span {
  position: unset;
  color: #446fa2;
  font-size: 10px;
  cursor: pointer;
  font-weight: bold;
}
.stdetails-left .left-side-chat li div.nested-feature > span:hover {
  font-weight: bold;
}
.stdetails-left
  .left-side-chat
  li
  div.nested-feature
  .chat-screen-inputsection {
  position: absolute;
  top: 15px;
  left: 0;
  height: fit-content !important;
  width: 300px;
  border: unset !important;
  z-index: 5;
}
.stdetails-left
  .left-side-chat
  li
  div.nested-feature
  .chat-screen-inputsection
  textarea#textarea-id {
  font-size: 12px;
}
.stdetails-left ul.chat-thread.nested {
  width: 650px;
  margin-top: unset;
}
.stdetails-left ul.chat-thread.nested li {
  max-width: 90%;
  min-width: 35%;
  margin-bottom: 5px;
  font-size: 12px;
}
.stdetails-left ul.chat-thread.nested .chat-avatar img {
  width: 50px;
  height: 50px;
}
.stdetails-left .chat-screen-chatsection > ul.chat-thread > .right-side-chat,
.stdetails-left .chat-screen-chatsection > ul.chat-thread > .left-side-chat {
  display: unset;
}
.stdetails-left .chat-screen-chatsection .right-side-chat > div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.stdetails-left .chat-screen-chatsection .left-side-chat > div {
  display: flex;
  justify-content: flex-start;
}
.stdetails-left .right-side-chat ul.chat-thread.nested {
  border-right: solid 1px red;
}
.stdetails-left .left-side-chat ul.chat-thread.nested {
  border-left: solid 1px blue;
}
.stdetails-left .chat-thread li {
  min-width: 25%;
}
.stdetails-left .content.with-chat .chat-screen-inputsection {
  width: 100%;
}

/* responsive  */

@media screen and (max-width: 1701px) {

}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {

}
@media screen and (max-width: 1024px) {
  
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
