.add-money-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 35%);
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.add-money-popup {
  margin: 0 auto;
  position: relative;
  background-color: white;
  border-radius: 5px;
  padding: 50px 35px;
}
.add-money-cross {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  color: black;
  border-radius: 5px;
}
.add-money-header {
  text-align: center;
  color: #707070;
  font-size: 22px;
  font-family: var(--montserrat);
  margin-bottom: 25px;
  font-weight: bold;
}
.add-money-content button,
.add-money-content input {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #4671a4;
  border-radius: 5px;
  background: white;
  padding: 12px 25px;
  color: #51595e;
  font-size: 22px;
  font-weight: bold;
  font-family: var(--montserrat);
  cursor: pointer;
  margin-right: 5px;
}
.add-money-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-wrap: wrap;
}
.add-money-content .active {
  background: #f0f0f0;
}
.add-money-submit {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
}
.add-money-submit button {
  min-width: 174px;
}
.add-money-bottom {
  text-align: center;
  font-size: 16px;
  font-family: var(--montserrat);
  color: #707070;
}
.add-money-bottom button {
  all: unset;
  color: #4671a4;
}
.add-money-bottom button:hover {
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .add-money-popup {
    width: 60%;
  }
  .add-money-content button,
  .add-money-content input {
    flex: 1;
    margin-right: 5px;
    margin-top: 5px;
    height: 50px;
  }
}

@media screen and (max-width: 820px) {
  .add-money-submit button {
    min-width: 134px;
  }
}

@media screen and (max-width: 768px) {
  .add-money-popup {
    width: 90%;
    max-width: 460px;
  }
}

@media screen and (max-width: 500px) {
  .add-money-submit {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 15px;
  }
  .add-money-submit button {
    max-width: max-content;
  }
}

@media screen and (max-width: 358px) {
  .add-money-content input {
    width: -webkit-fill-available;
  }
}
