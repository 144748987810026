.info-popup-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-popup-parent * {
  font-family: var(--montserrat);
}
.info-popup-body {
  width: 95%;
  max-width: 340px;
  background-color: white;
  padding: 5px 10px 20px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.info-popup-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.info-popup-close:hover {
  cursor: pointer;
}
.info-popup-heading {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
}
.info-popup-description {
  font-size: 16px;
  color: #51595e;
  font-weight: normal;
  margin: 20px 0 25px 0;
}
.info-popup-body button {
  border: unset;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  padding: 10px 50px;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
