.video-call {
  background-color: #262626;
  height: 100%;
  width: 100%;
}

.video-call header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  align-items: flex-end;
  height: 9%;
}

.video-form {
  max-width: 450px;
  margin: 30px auto;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 20px;
}

.video-call input {
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 2px solid transparent;
  height: 34px;
  padding: 5px;
  background: #37474f;
  color: white;
  font-family: inherit;
}

.video-call input::placeholder {
  color: #aaa;
}

.video-call .input-container {
  margin-bottom: 20px;
}

.video-call .btn-primary {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px 14px;
  background-color: #1565c0;
  color: white;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
}

.video-call .video-form h2 {
  margin-bottom: 20px;
}

.video-call .btn-danger {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: #a81212;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  font: normal normal normal 16px/16px Lato;
  letter-spacing: 0px;
  opacity: 1;
}

.video-call .btn-danger-bottom {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: #a81212;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  font: normal normal normal 16px/16px Lato;
  letter-spacing: 0px;
  opacity: 1;
  display: none;
}

.video-call .conference-section {
  width: 100%;
  margin-top: 15px;
  height: 88vh;
  padding: 0 3px;
}

.video-call-header {
  text-align: center;
  font-size: 32px;
  color: white;
  margin: unset;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: transparent;
}

.video-call .peers-container {
  /* display: grid;
  grid-template-columns: repeat(3, minmax(min-content, 1fr));
  place-items: center;
  grid-gap: 10px; */

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 2.5px;
}

.video-call .peers-container-screen-sharing {
  width: 100%;
  height: 225px;
  margin-bottom: 3px;
  position: relative;
  padding: 0 2px;
}

.peer-video-screen-sharing:hover + .peer-host-button-screen-sharing {
  display: block;
}
.peer-video-screen-sharing-mirror:hover + .peer-host-button-screen-sharing {
  display: block;
}
.video-call .host-video-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.peers-container > div {
  margin-bottom: 15px;
  width: 30%;
}

.video-call .peer-video {
  height: 500px;
  width: 100%;
  border-radius: 40%;
  object-fit: contain;
  margin-bottom: 10px;
  border: solid 1px white;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.video-call .peer-video-host {
  height: 500px;
  width: 100%;
  border-radius: 40%;
  object-fit: contain;
  margin-bottom: 10px;
  border: solid 1px orange;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.video-call .peer-video-host-full {
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  border: solid 1px white;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.video-call .peer-host-video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  border: solid 1px white;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.video-mirroring {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
.video-call .peer-video-screen-sharing {
  width: 100%;
  height: 225px;
  object-fit: contain;
  border: solid 1px white;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.video-call .peer-video-screen-sharing-mirror {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
  width: 100%;
  height: 225px;
  object-fit: contain;
  border: solid 1px white;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.video-call .peer-video-screen-sharing-host {
  width: 100%;
  height: 225px;
  object-fit: contain;
  border: solid 1px orangered;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.video-call .local.peer-video {
  transform: scaleX(-1);
}

.video-call .peer-name {
  font-size: 14px;
  text-align: center;
  color: white;
}

.video-call .peer-name-screen-sharing {
  font-size: 14px;
  color: white;
  top: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 15px;
}

.video-call .peer-host-button-screen-sharing {
  font-size: 14px;
  color: white;
  top: 0;
  right: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 15px;
  border-width: 0;
  display: none;
  z-index: 1;
}

.video-call .peer-host-button-screen-sharing:hover {
  display: block;
  cursor: pointer;
}

.video-call .peer-audio-video-status {
  font-size: 14px;
  color: white;
  bottom: 0;
  position: absolute;
  padding: 5px 10px;
  display: flex;
  gap: 13px;
  background-color: rgba(0, 0, 0, 0.6);
}

.video-call .peer-name-host {
  font-size: 14px;
  color: white;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 15px;
}

.video-call .peer-name-host-multiple-peer {
  font-size: 14px;
  color: white;
  top: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 15px;
  display: none;
}

/* .video-call .peer-container {
  padding: 10px;
  background: white;
} */

.video-call .control-bar {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  justify-content: center;
  z-index: 1;
  height: 10%;
  align-items: center;
  gap: 15px;
}

.video-call .control-bar > *:not(:first-child) {
  margin-left: 8px;
}

.video-call .btn-control {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 2px solid #37474f;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  background-color: #a3c1f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}
.video-call .hide {
  display: none;
}
img.video-call-logo {
  width: 45px;
}

.video-call-header-action-button {
  display: flex;
  column-gap: 15px;
}

.video-call-share-button {
  background-color: transparent;
  border-width: 0px;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  font: normal normal normal 16px/16px Lato;
  gap: 3px;
}

.video-call-share-button:hover {
  cursor: pointer;
}

.video-call-sharing-screen-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.video-call-sharing-screen-video-section {
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: hidden;
  object-fit: contain;
  max-height: 78vh;
  padding-left: 2px;
  background-color: #a3c1f5;
}

.video-call-sharing-screen-peers-section {
  height: 100%;
  width: 100%;
  flex-basis: 20%;
  display: flex;
  max-height: 79.6vh;
  flex-direction: column;
  overflow: scroll;
}

.video-call-single-user-Container {
  height: 78vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.host-video-container-single {
  height: 100%;
  width: 80%;
  position: relative;
}

.chat-close-button {
  height: max-content;
  width: max-content;
  padding: 5px;
  background-color: transparent;
  border-width: 0px;
  color: #afafaf;
  align-self: center;
}

.chat-close-button:hover {
  cursor: pointer;
}

.room-header-first-container {
  display: flex;
  gap: 20px;
}

.room-chat-dialog {
  width: 25%;
  height: 97.8%;
  background-color: transparent;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #afafaf;
  border: 1px solid #1f1f1f;
}

.time-icon {
  color: white;
  align-self: center;
  margin-left: 15px;
}

.peer-sharing {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
  width: 100%;
  height: 225px;
  object-fit: contain;
  border: solid 1px white;
  background: #a3c1f5;
  background-image: url(/src/Media/userImage.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.peer-sharing:hover + .peer-host-button-screen-sharing {
  display: block;
}
.peer-sharing {
  max-height: 100%;
  aspect-ratio: 1/1;
}
@media screen and (max-width: 912px) {
  .room-chat-dialog {
    width: 60%;
    right: 0;
    position: fixed;
    background-color: #262626;
    height: 78.2%;
  }
}
@media screen and (max-width: 820px) {
  .video-call-sharing-screen-container {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: 90.5%;
  }
  .video-call-single-user-Container {
    height: 72vh;
  }
  .video-call .conference-section {
    height: 81vh;
  }
  .video-call-sharing-screen-video-section {
    height: 80%;
    padding: 0px;
  }
  .video-call-sharing-screen-peers-section {
    flex-direction: row;
    width: 100%;
    height: 20%;
    gap: 4px;
  }
  .peers-container-screen-sharing {
    margin-left: -2.1px;
    max-height: 96%;
    max-width: 33vw;
    min-width: 33vw;
  }
  .peer-video-screen-sharing {
    max-height: 100%;
    aspect-ratio: 1/1;
  }
  .peer-video-screen-sharing-mirror {
    max-height: 100%;
    aspect-ratio: 1/1;
  }
  .video-call .peer-name-host {
    display: none;
  }
  .video-call .peer-name-host-multiple-peer {
    display: block;
  }
  .room-header-first-container {
    gap: 15px;
  }
  .room-header-first-container img {
    height: 50px;
    width: 40px;
    align-self: center;
  }
  .time-icon {
    margin-left: 0;
  }
  .video-call .btn-danger {
    display: none;
  }
  .video-call .btn-control {
    height: 50px;
    width: 50px;
  }
  .video-call .btn-danger-bottom {
    display: block;
  }
  .video-call .peer-audio-video-status {
    padding: 2px 5px;
    gap: 8px;
  }
  .video-call .peer-name-screen-sharing {
    font-size: 10px;
    padding: 2px 5px;
  }
  .host-video-container-single {
    width: 90%;
  }
  .video-call .peer-host-button-screen-sharing {
    font-size: 12px;
    padding: 3px 10px;
  }
  .room-chat-dialog {
    width: 100%;
    position: fixed;
    background-color: #262626;
    height: 78.3%;
  }
}
