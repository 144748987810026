.money-credit-transaction-receipt-pdf-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  top: 0;
  z-index: 5;
  overflow-y: scroll;
  background-color: white;
}
.money-credit-transaction-receipt-pdf-parent * {
  font-family: var(--montserrat);
}
.money-credit-transaction-receipt-pdf-main {
  width: 100%;
  margin: 0 auto;
  /* padding: 5% 0; */
  padding: unset;
}
.money-credit-transaction-receipt-pdf-form-body {
  max-width: 100%;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
}
.money-credit-transaction-receipt-pdf-wrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 25px;
}
.money-credit-transaction-receipt-pdf-heading {
  position: relative;
  /* border-bottom: solid 1px #c1c1c1; */
  text-align: center;
  padding: 5px 0;
}
.money-credit-transaction-receipt-pdf-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.money-credit-transaction-receipt-pdf-top h3 {
  color: #32325d;
  font-size: 24px;
  font-weight: normal;
  margin-top: 25px;
  margin-bottom: 10px;
}
.money-credit-transaction-receipt-pdf-top span {
  color: #8898aa;
  font-size: 15px;
}
.money-credit-transaction-receipt-pdf-mid {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.money-credit-transaction-receipt-pdf-mid-content span {
  color: #525f7f;
  font-size: 15px;
}
.money-credit-transaction-receipt-pdf-mid-content h6 {
  color: #8898aa;
  font-size: 12px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.money-credit-transaction-receipt-pdf-mid {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.money-credit-transaction-receipt-pdf-mid-content span {
  color: #525f7f;
  font-size: 15px;
}
.money-credit-transaction-receipt-pdf-mid-content h6 {
  color: #8898aa;
  font-size: 12px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.money-credit-transaction-receipt-pdf-bot {
  padding-bottom: 50px;
  border-bottom: solid 1px #c1c1c1;
}
.money-credit-transaction-receipt-pdf-img img {
  width: 100%;
}
.money-credit-transaction-receipt-pdf-bot h4 {
  color: #687385;
}
.money-credit-transaction-receipt-pdf-bot-box {
  background: #f6f9fc;
  height: 120px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
}
.money-credit-transaction-receipt-pdf-bot-box div:first-child {
  border-bottom: solid 1px #c1c1c1;
}
.money-credit-transaction-receipt-pdf-bot-box div:last-child {
  color: #525f7f;
  font-weight: bold;
}
.money-credit-transaction-receipt-pdf-bot-box div {
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.money-credit-transaction-receipt-pdf-email-box {
  padding-top: 50px;
  padding-bottom: 25px;
}
.money-credit-transaction-receipt-pdf-email-box span {
  color: #414552;
}
.money-credit-transaction-receipt-pdf-email-box a {
  color: #625afa;
  font-weight: bold;
  text-decoration: none;
  display: block;
}
/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */

@media screen and (max-width: 740px) {
}
@media screen and (max-width: 600px) {
  .money-credit-transaction-receipt-pdf-main {
    width: 100%;
    padding: 15% 0;
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
