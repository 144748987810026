.assign-workbook-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assign-workbook-parent * {
  font-family: var(--montserrat);
}
.assign-workbook-main {
  width: 90%;
  max-width: 450px;
  max-height: 300px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}
.assign-workbook-wrapper {
  max-width: 100%;
  max-height: 225px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.assign-workbook-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}
.assign-workbook-content {
  width: 95%;
  height: max-content;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  align-items: center;
}
.assign-workbook-content div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.assign-workbook-content img {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
}
.assign-workbook-fields label {
  font-size: 22px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}
.assign-workbook-content button {
  border: unset;
  border-radius: 5px;
  height: 27px;
  width: 83px;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
}
.assign-workbook-content button:is([disabled]) {
  background-color: #e6e6e6;
  color: #929292;
  cursor: default;
}
.assign-workbook-heading {
  position: relative;
}
.assign-workbook-heading .rename-cross {
  top: 5%;
  right: 1%;
}

@media screen and (max-width: 450px) {
  .assign-workbook-content button {
    height: 25px;
    width: 75px;
    font-size: 11px;
  }
}
@media screen and (max-width: 350px) {
  .assign-workbook-content button {
    height: 25px;
    width: 70px;
    font-size: 10px;
  }
}
