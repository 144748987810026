.page-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: max-content;
  text-align: center;
}
.page-not-found-container h1 {
  font-size: 50px;
  color: #c50000;
}
.page-not-found-container h2 {
  font-size: 40px;
  color: #446fa2;
  margin-top: -20px;
}
.page-not-found-container img {
  height: 100%;
  max-height: 400px;
  width: auto;
}
.error-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.error-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

/* responsive  */

@media screen and (max-width: 1200px) {
  .error-wrapper {
    margin-left: 5%;
  }
}

@media screen and (max-width: 930px) {
  .page-not-found-container img {
    max-height: 300px;
  }
}

@media screen and (max-width: 740px) {
  .page-not-found-container img {
    width: 80%;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .error-wrapper {
    margin-left: 0;
  }
}
