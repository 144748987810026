.purchase-storage-plan-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.purchase-storage-plan-parent * {
  font-family: var(--montserrat);
}
.purchase-storage-plan-main {
  width: 90%;
  max-width: 810px;
  height: max-content;
  max-height: 532px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}
.purchase-storage-plan-main p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}
.purchase-storage-plan-body {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin: -15px auto auto;
  overflow: scroll;
  padding-bottom: 15px;
}
.purchase-storage-plan-wrapper {
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  gap: 20px;
  padding-bottom: 8px;
}
.purchase-storage-plan-heading {
  position: relative;
  margin-top: -14px;
}
.purchase-storage-plan-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.purchase-storage-plan-wrapper-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 190px;
  gap: 5px;
}
.purchase-storage-plan-wrapper-content label {
  font-family: var(--lato);
  font-size: 16px;
  font-weight: bold;
  color: #51595e;
}
.purchase-storage-plan-wrapper-content-card {
  all: unset;
  display: flex;
  height: 68px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  padding-left: 5px;
  cursor: pointer;
}
.purchase-storage-plan-wrapper-content-card input {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.purchase-storage-plan-wrapper-content-card-labels {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.purchase-storage-plan-wrapper-content:first-child {
  width: 126px;
}
.purchase-storage-plan-wrapper-content-card label {
  font-family: var(--montserrat);
  font-size: 20px;
  font-weight: bold;
  color: #446fa2;
  letter-spacing: 1px;
}
.purchase-storage-plan-wrapper-content-card span {
  font-family: var(--montserrat);
  font-size: 16px;
  font-weight: bold;
  color: #51595e;
}
.upgrade-plan-proceed-button {
  all: unset;
  color: white;
  width: 176px;
  height: 39px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  background-color: rgb(92, 161, 39);
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
  .purchase-storage-plan-main p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */
@media screen and (max-width: 606px) {
  .purchase-storage-plan-main {
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
