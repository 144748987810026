.superadmin-wrapper {
  width: 95%;
  max-width: 1024px;
  margin: 0 auto;
  background: white;
  margin-top: 35px;
}
.superadmin-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.superadmin-upper {
  border-bottom: 2px solid var(--border-color);
  padding: 20px 30px;
}
.superadmin-head {
  color: #51595e;
  font-family: var(--montserrat);
  font-size: 18px;
  font-weight: 700;
}
.superadmin-lower {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  min-height: 350px;
  max-height: 550px;
  justify-content: space-between;
  overflow-y: scroll;
  padding: 0 30px;
}
.superadmin-lower > div {
  width: 100%;
}
.internal-head {
  color: #51595e;
  font-family: var(--montserrat);
  font-size: 14px;
  font-weight: 700;
  margin-top: 25px;
}
.internal-wrapper {
  margin-top: 10px;
  position: relative;
}
.internal-wrapper > input {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  color: #51595e;
  font-family: var(--lato);
  font-size: 13px;
  font-weight: 700;
  padding: 15px;
  width: 100%;
}
.superadmin-lower .organization-list {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.superadmin-lower .organization-list .data-list {
  background: white;
}
button.superadmin-delete {
  color: #446fa2 !important;
  background-color: white !important;
}
.internal-wrapper.hide-dropdown .organization-list {
  display: none;
}

@media screen and (max-width: 1230px) {
  .superadmin-wrapper {
    max-width: 900px;
  }
}

@media screen and (max-width: 1080px) {
  .superadmin-wrapper {
    max-width: 700px;
  }
}

@media screen and (max-width: 900px) {
  .superadmin-wrapper {
    max-width: 550px;
  }
}

@media screen and (max-width: 740px) {
  .superadmin-wrapper {
    max-width: 440px;
  }
}

@media screen and (max-width: 600px) {
  .superadmin-wrapper {
    max-width: unset;
  }
  .superadmin-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
}
