.reset-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255 254 254 / 80%);
  z-index: 1;
  top: 0;
  display: flex;
  align-items: center;
}
.reset-wrapper {
  width: 40%;
  margin: 0 auto;
  position: relative;
}
.reset-wrapper form > div {
  margin: 15px 0;
}
.reset-submit button {
  padding: 12.5px 0;
}

@media screen and (max-width: 1024px) {
  .reset-wrapper {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .reset-wrapper {
    width: 65%;
  }
}

@media screen and (max-width: 426px) {
  .reset-wrapper {
    width: 90%;
  }
}
