.refund-request-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  overflow-y: scroll;
}
.refund-request-parent * {
  font-family: var(--montserrat);
}
.refund-request-main {
  width: 100%;
  margin: 0 auto;
  padding: 5% 0;
}
.refund-request-form-body {
  max-width: 800px;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
}
.refund-request-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  border-bottom: solid 1px #e1e1e1;
}
.refund-request-wrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 25px;
}
.refund-request-fields {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.refund-request-fields select {
  width: 100%;
  height: 40px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  outline: none;
}
.refund-request-fields label {
  font-size: 14px;
  color: #51595e;
  font-weight: bold;
  flex-basis: 40%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.refund-credit-error {
  font-size: 14px;
  color: #ff0000;
  font-weight: normal;
  margin-left: 29.5%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: -15px;
}
.refund-request-fields input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
}
.refund-request-fields textarea {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  resize: none;
}
.refund-request-fields label span {
  color: #ff0000;
}
.refund-request-fields button {
  border: unset;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  padding: 10px 50px;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
.refund-request-heading {
  position: relative;
}
.refund-request-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.refund-request-radio-button {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  align-items: center;
}
.refund-request-radio-button input {
  height: 30px;
  width: 20px;
}
.refund-request-radio-button label {
  margin-left: -74%;
}
.refund-credit-other-text-box {
  margin-left: 29.5%;
  margin-top: -15px;
  margin-bottom: 20px;
}
.refund-credit-other-text-box textarea {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  resize: none;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .refund-request-form-body {
    max-width: 750px;
  }

  .refund-request-fields label {
    padding-left: 10px;
  }
  .refund-request-radio-button label {
    margin-left: -80%;
  }
}
/* ipad air  */

@media screen and (max-width: 740px) {
  .refund-request-fields {
    flex-direction: column;
    align-items: flex-start;
  }
  .refund-credit-error {
    margin-left: 0px;
  }
  .refund-credit-other-text-box {
    margin-left: 0px;
  }
  .refund-request-radio-button label {
    margin-left: -85%;
  }
  .refund-request-radio-button {
    grid-template-columns: repeat(2, 1fr);
  }
  .refund-request-radio-button label {
    padding: unset;
  }
}
@media screen and (max-width: 600px) {
  .refund-request-main {
    width: 100%;
    padding: 15% 0;
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 430px) {
  .refund-request-radio-button label {
    padding: 10px;
  }
}
