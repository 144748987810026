.card-logo {
  position: relative;
}
span.user-status {
  position: absolute;
  top: -2.5%;
  right: -2.5%;
}
span.user-status svg {
  font-size: 20px;
}
span.user-scope {
  position: absolute;
  bottom: 10%;
  right: 10%;
}
.card-board {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7.5px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  border-radius: 2.5px;
}
.card-text * {
  margin: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-logo img {
  border-radius: 50%;
  object-fit: cover;
}
.card-logo > img {
  width: 55px;
  height: 55px;
}
.card-text {
  width: 100%;
  padding-left: 15px;
  font-family: var(--montserrat);
}
.card-text h3 {
  color: var(--heading-color);
}
.card-text p {
  color: var(--text-color);
}
.card-options button {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 5px;
  color: #434343;
}
.card-options button svg {
  font-size: 17.5px;
}
.card-options {
  display: flex;
  justify-content: flex-end;
}
.card-options .delete-icon {
  color: #c50000;
  background-color: white;
}
.card-icons {
  display: flex;
  flex-direction: column;
}
.card-icons .chat-icon {
  color: #507dd6;
}
.card-icons .email-icon {
  color: #d1a02e;
}
.card-container {
  width: 47.5%;
}
.card-text {
  overflow: hidden;
}
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1024px) {
  .card-container {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .card-text h3 {
    font-size: 14px;
  }
}
