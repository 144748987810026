.edit-bank-details-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  overflow-y: scroll;
}
.edit-bank-details-parent * {
  font-family: var(--montserrat);
}
.edit-bank-details-main {
  width: 100%;
  margin: 0 auto;
  padding: 5% 0;
}
.edit-bank-details-form-body {
  width: 95%;
  max-width: 800px;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
}
.edit-bank-details-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  border-bottom: solid 1px #e1e1e1;
}
.edit-bank-details-wrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 25px;
}
.edit-bank-details-fields {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.edit-bank-details-fields label {
  font-size: 14px;
  color: #51595e;
  font-weight: bold;
  flex-basis: 40%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.edit-bank-details-fields input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
}
.edit-bank-details-fields label span {
  color: #ff0000;
}
.edit-bank-details-fields button {
  border: unset;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  padding: 10px 50px;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: -15px;
}
.edit-bank-details-heading {
  position: relative;
}
.edit-bank-details-heading .rename-cross {
  top: 5%;
  right: 1%;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .edit-bank-details-form-body {
    max-width: 750px;
  }

  .edit-bank-details-fields label {
    padding-left: 10px;
  }
}
/* ipad air  */

@media screen and (max-width: 740px) {
  .edit-bank-details-fields {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .edit-bank-details-main {
    width: 100%;
    padding: 15% 0;
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
