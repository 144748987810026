.stdetails-left
  .content.with-chat
  .chat-screen-input
  .example_mentions__A\+NgQ.example_mentions--multiLine__ygnJz {
  border: unset;
  width: 100%;
  background: #f5f5f5;
  font-family: var(--lato);
  outline: unset;
  resize: none;
  margin: unset;
}
.general-card-mention
  .chat-screen-input
  .example_mentions__A\+NgQ.example_mentions--multiLine__ygnJz
  li {
  display: list-item;
  padding: 5px 15px;
  margin: unset;
  font-size: unset;
  border-radius: unset;
  font-family: var(--lato);
  max-width: unset;
  min-width: unset;
  position: unset;
  word-break: unset;
}
.general-card-mention
  .chat-screen-input
  .example_mentions__A\+NgQ.example_mentions--multiLine__ygnJz
  li
  b {
  display: unset;
  font-size: 12px;
}
