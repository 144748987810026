.org-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.org-main {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.org-left {
  width: 35%;
  margin-right: 1%;
}
.org-right {
  width: 55%;
  margin-left: 1%;
}
.org-wrapper {
  width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 5px 40px #00000005;
}
.org-upper {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: solid 2px var(--border-color);
}
.org-head {
  font-family: var(--montserrat);
  font-weight: bold;
  color: #51595e;
  font-size: 18px;
}
.org-admin-details {
  padding: 25px 30px;
  display: flex;
}
.org-details {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  border-top: solid 2px var(--border-color);
}
.org-logo {
  display: flex;
  align-items: center;
}
.org-logo img {
  border-radius: 50%;
  object-fit: contain;
  width: 75px;
  height: 75px;
}
.org-with-card {
  width: 100%;
}
.org-banner {
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 5px 25px;
  display: flex;
  font-family: var(--montserrat);
  font-weight: bold;
  background-color: var(--secondary-color);
  font-size: var(--secondary-text);
}
.org-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.org-card-wrapper > div {
  margin-bottom: 15px;
}
.org-text h3 {
  margin-top: unset;
  margin-bottom: 5px;
  color: var(--secondary-color);
  font-family: var(--montserrat);
  font-size: var(--primary-heading);
  font-weight: bolder;
}
.org-url {
  background: var(--primary-color);
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  padding: 5px;
  border-radius: 15px;
  font-family: var(--montserrat);
  flex-wrap: wrap;
}
.org-url span {
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
}
.org-url a {
  color: #446fa2;
  text-decoration: none;
}
.org-url svg {
  font-size: 20px;
  margin: 0 5px;
}
.org-text p {
  margin: unset;
  margin-top: 5px;
  color: #51595e;
  font-family: var(--montserrat);
}
.org-bio {
  width: 100%;
  margin-left: 20px;
}
.org-lower .profile-details:first-child {
  padding-top: unset;
}
.org-lower {
  position: relative;
}
.org-admin-hover-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 30px;
  padding-bottom: 10px;
}
.org-left .org-upper {
  padding: 12.5px 30px;
}
.org-not-connected > div {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: var(--montserrat);
  color: #c23616;
  border: solid 2px #c23616;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
}
.org-not-connected > div svg {
  margin-left: 5px;
  font-size: 20px;
}
.org-not-connected + button {
  min-width: 45px;
  padding: 6px;
}
.org-view_all > div {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: var(--montserrat);
  color: #5ca127;
  border: solid 2px #5ca127;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
}
.org-view_all > div svg {
  margin-left: 5px;
  font-size: 20px;
}
.org-container
  .profile-details:last-child
  .profile-with-card
  .profile-card-wrapper {
  max-height: 475px;
}
.org-main p {
  font-size: 14px;
}
.org-main button {
  font-family: var(--montserrat);
}
.org-card-container {
  width: 47.5%;
}
.org-status-action-wrapper {
  display: flex;
  align-items: normal;
  gap: 15px;
}
.org-card-board {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7.5px 10px;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  border-radius: 2.5px;
  height: 100px;
  margin-top: 28px;
  animation: 1.5s shine linear infinite;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
@media screen and (max-width: 1600px) {
  .org-url span {
    font-size: 14px;
  }
  .org-main {
    width: 97%;
    justify-content: flex-end;
  }
  .org-main button {
    font-size: 12px;
  }
}
@media screen and (max-width: 1462px) {
}
@media screen and (max-width: 1400px) {
  .org-main p {
    font-size: 12px;
  }
  .org-admin-hover-wrapper {
    gap: 15px;
  }
}
@media screen and (max-width: 1391px) {
  .org-left .org-upper,
  .org-status-action-wrapper {
    align-items: normal;
  }
}
@media screen and (max-width: 1321px) {
  .org-admin-hover-wrapper button {
    font-size: 11px;
  }
}
@media screen and (max-width: 1249px) {
  .org-admin-hover-wrapper button {
    font-size: 10px;
  }
}
@media screen and (max-width: 1120px) {
  .org-head {
    font-size: 16px;
  }
  .org-left .org-upper {
    align-items: center;
  }
}
@media screen and (max-width: 1177px) {
  .org-request-btn {
    height: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .org-head {
    font-size: 18px;
  }
  .org-left .org-upper {
    justify-content: space-around;
    align-items: center;
  }
  .org-upper,
  .org-right .org-upper,
  .org-admin-details,
  .org-details {
    padding-left: 15px;
    padding-right: 15px;
  }
  .org-main {
    flex-wrap: wrap;
  }
  .org-left,
  .org-right {
    width: 87.5%;
    margin: unset;
  }
  .org-admin-details {
    flex-wrap: wrap;
    justify-content: center;
  }
  .org-bio {
    margin: unset;
    text-align: center;
    margin-top: 15px;
  }
  .org-url {
    margin: 0 auto;
  }

  .org-request-btn {
    height: unset;
  }
  .org-admin-hover-wrapper button {
    font-size: 11px;
  }
}
@media screen and (max-width: 769px) {
  .org-main {
    width: 100%;
  }
  .profile-details {
    padding: 15px;
  }
  .org-card-container {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .org-container {
    height: 100%;
  }
  .org-header {
    height: 65px;
  }
  .org-navigation {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 60px;
    width: 100%;
  }
  .org-main {
    width: 100%;
    justify-content: center;
    overflow-y: scroll;
    height: calc(100% - 130px);
    margin: unset;
  }
  .org-main > div {
    width: 100%;
  }
  .navigation-wrapper {
    width: 100%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 496px) {
  .org-left .org-upper {
    gap: 12px;
  }
}
@media screen and (max-width: 424px) {
  .org-admin-hover-wrapper button {
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
  .org-head {
    font-size: 18px;
    text-align: center;
  }
  .org-left .org-upper,
  .org-status-action-wrapper {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .org-admin-hover-wrapper {
    gap: 10px;
  }
  .org-admin-hover-wrapper button {
    font-size: 9px;
  }
}
@media screen and (max-width: 365px) {
  .org-left .org-upper,
  .org-status-action-wrapper {
    align-items: center;
  }
  .org-request-btn,
  .org-not-connected > div,
  .org-view_all > div {
    height: 40px;
  }
  .org-admin-hover-wrapper {
    padding: 12.5px 15px;
  }
}
@media screen and (max-width: 330px) {
  .org-main p {
    font-size: 9px;
  }
}
