.appoint-upper {
  padding: 20px 30px;
  border-bottom: solid 2px var(--border-color);
}
.appoint-wrapper {
  width: 100%;
  background: white;
  border-radius: 5px;
  height: fit-content;
  margin-top: 25px;
}
.appoint-head {
  color: #51595e;
  font-size: 18px;
}
.appoint-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.appointment-message {
  font-family: var(--montserrat);
  text-align: center;
  margin-top: 50px;
}
