.admin-rates-and-subscription-wrapper {
  width: 80%;
  max-width: 530px;
  margin: 0 auto;
  margin-top: 50px;
}
.admin-rates-and-subscription-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
}
.admin-rates-and-subscription-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  cursor: pointer;
  justify-content: center;
  gap: 10px;
  height: 110px;
  width: 175px;
}
div.active {
  background-color: #efefef;
}
.admin-rates-and-subscription-lower-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: white;
  cursor: pointer;
  height: 92px;
  width: 100%;
  max-width: 424px;
  margin-bottom: 20px;
}
.admin-rates-and-subscription-lower-tile-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}
.admin-rates-and-subscription-content {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  justify-content: center;
}
.admin-rates-and-subscription-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.admin-rates-and-subscription-tile > span {
  display: flex;
  flex-direction: column;
}
.admin-rates-and-subscription-tile span {
  color: #446fa2;
  font-weight: bold;
}
.admin-rates-and-subscription-tile span b {
  font-size: 22px;
}
.admin-rates-and-subscription-lower-tile-header-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
  width: 100%;
  max-width: 424px;
}
.admin-rates-and-subscription-lower-tile-header-container span {
  color: #446fa2;
  font-weight: bold;
}
.admin-rates-and-subscription-lower-tile span {
  color: #446fa2;
  font-weight: bold;
  padding-left: 15px;
}
.admin-rates-and-subscription-lower-tile label {
  font-size: 14px;
  letter-spacing: 0px;
  color: #446fa2;
  padding-left: 15px;
}
.admin-rates-and-subscription-lower-tile-button {
  all: unset;
  background-color: #5ca127;
  color: white;
  font-size: 10px;
  padding: 8px 20px 8px 20px;
  border-radius: 7px;
  text-align: center;
  margin-left: 5px;
}

@media screen and (max-width: 1080px) {
  .admin-rates-and-subscription-wrapper {
    margin-left: 25%;
  }
}

@media screen and (max-width: 900px) {
  .admin-rates-and-subscription-wrapper {
    margin-left: 21%;
  }
}

@media screen and (max-width: 730px) {
  .admin-rates-and-subscription-wrapper {
    margin-left: 18%;
  }
  .admin-rates-and-subscription-lower-tile-container {
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .admin-rates-and-subscription-wrapper {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
  }
  .admin-rates-and-subscription-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .admin-rates-and-subscription-content {
    margin-top: 25px;
  }
  .admin-rates-and-subscription-lower-tile-container {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 463px) {
  .admin-rates-and-subscription-content {
    flex-direction: column;
  }
  .admin-rates-and-subscription-tile {
    width: 100%;
  }
}

@media screen and (max-width: 387px) {
  .admin-rates-and-subscription-wrapper {
    width: 90%;
  }
  .admin-rates-and-subscription-wrapper {
    margin-left: 5%;
  }
}
