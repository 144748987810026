.create-storage-plan-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.create-storage-plan-parent * {
  font-family: var(--montserrat);
}
.create-storage-plan-main {
  width: 90%;
  max-width: 500px;
  /* height: 85%;
  max-height: 200px; */
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}
.create-storage-plan-main p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}
.create-storage-plan-wrapper {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 0 20px 20px;
}
.create-storage-plan-wrapper-content {
  display: flex;
  gap: 10px;
}
.create-storage-plan-wrapper-content div {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.create-storage-plan-wrapper input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  height: 40px;
  padding: 0 10px;
  font-family: var(--montserrat);
}
.create-storage-plan-wrapper label {
  font-family: var(--montserrat);
  font-size: 16px;
}
.create-storage-plan-wrapper span {
  color: #bc002d;
}
.create-storage-plan-heading {
  position: relative;
  margin-top: -14px;
}
.create-storage-plan-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.create-storage-plan-create-button {
  all: unset;
  color: white;
  margin-top: 5px;
  padding: 8px 20px;
  width: max-content;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  background-color: rgb(92, 161, 39);
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
  .create-storage-plan-main p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
