.stappointmentpopup-main {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  overflow-y: scroll;
  padding: 7% 0;
}
.stappointmentpopup-wrapper {
  width: 45%;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
}
.stappointmentpopup-wrapper .cross-button {
  position: absolute;
  /* background: #446fa2; */
  color: black;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1%;
  top: 1%;
  cursor: pointer;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .stappointmentpopup-wrapper {
    width: 55%;
  }
  .appointment-form-upload.stcreate-fields {
    flex-direction: unset;
    align-items: unset;
  }
}

@media screen and (max-width: 1400px) {
  .stappointmentpopup-wrapper {
    width: 65%;
  }
}

@media screen and (max-width: 1024px) {
  .stappointmentpopup-wrapper {
    width: 80%;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .stappointmentpopup-wrapper {
    width: 90%;
    margin-top: 45px;
  }
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .stappointmentpopup-wrapper {
    width: 98%;
  }
}
@media screen and (max-width: 600px) {
  .stappointmentpopup-wrapper {
    margin-top: 90px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
