.notification-type {
  background: #5cbb55;
  color: white;
  font-family: var(--lato);
  font-weight: 500;
  padding: 3px 7.5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
}
.notification-data h3 {
  margin-bottom: unset;
  margin-top: 5px;
  font-family: var(--lato);
  font-weight: bold;
  font-size: 16px;
  color: #51595e;
}
.notification-data p {
  font-family: var(--lato);
  margin-top: unset;
  color: #647589;
  font-size: var(--secondary-text);
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  width: 90%;
  /* overflow: hidden; */
}
.notif-wrapper {
  width: 100%;
  background: white;
  border-radius: 5px;
  height: fit-content;
}
.notif-lower .footer-para {
  font-family: var(--montserrat);
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
}
.notif-flex {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.notif-head {
  color: #51595e;
  font-weight: bold;
  font-size: 18px;
}
.notif-upper {
  padding: 20px 30px;
  border-bottom: solid 2px var(--border-color);
}
.notif-logo {
  border-radius: 50%;
  max-width: 60px;
  height: 60px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.notif-logo img {
  width: 100%;
  object-fit: contain;
}
.notif-data h3 {
  margin-bottom: unset;
  margin-top: 5px;
  font-family: var(--lato);
  font-weight: bold;
  font-size: 16px;
  color: #51595e;
}
.notif-data p {
  font-family: var(--lato);
  margin-top: unset;
  color: #647589;
  font-size: var(--secondary-text);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96%;
  overflow: hidden;
}
.notif-card-wrapper {
  border-bottom: solid 2px var(--border-color);
  padding: 5px 15px;
  padding-top: 15px;
  cursor: pointer;
  position: relative;
  align-items: center;
}
.notif-card-wrapper .delete-notification-ui {
  /* position: unset; */
}
.notif-lower {
  height: 325px;
  overflow-y: scroll;
}
.notif-accept {
  margin-left: 10px;
}
.notif-options > div {
  cursor: pointer;
}
.notif-card-info {
  flex: 0 0 70%;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.notif-options {
  flex: 0 0 15%;
  justify-content: center;
}
.notif-card-info .notif-logo {
  flex: 0 0 20%;
}
.notif-card-info .notif-content {
  flex: 0 0 80%;
  width: 100%;
  overflow: hidden;
}
.notification-content {
  width: 100%;
}
.notif-data {
  width: 100%;
  overflow: hidden;
}
