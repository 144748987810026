.add-user-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.location-list-container {
  position: absolute;
  width: 100%;
  z-index: 2;
}
.location-list-wrapper {
  background: #a3c1f5;
  border: solid 1px #c4c4c4;
  padding: 10px;
  color: white;
  cursor: pointer;
}
.add-profile-category,
.add-user-location {
  position: relative;
}
.add-profile-category.hide-dropdown .category-list-container {
  display: none;
}
.add-user-location.hide-dropdown .location-list-container {
  display: none;
}
.add-user-container > div {
  width: 55%;
  margin: 0 auto;
  height: fit-content;
  max-height: 80%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  padding: 25px 50px;
  overflow-y: scroll;
}
.add-user-scope label {
  display: block;
}
.add-user-head {
  font-family: var(--montserrat);
  font-weight: 700;
}
.add-user-top-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-user-form label,
.add-user-form input {
  font-size: 14px;
}
button.form-button {
  margin-top: 25px;
}
.add-user-office {
  display: flex;
  justify-content: space-between;
}
.add-user-office > div {
  width: 74%;
}
.add-user-office > div:last-child {
  width: 24%;
}
.add-user-scope select {
  width: 100%;
  border: solid 1px #c4c4c4;
  height: 50px;
  border-radius: 5px;
  padding: 0 10px;
  color: #828282;
  font-size: 14px;
  outline: unset;
  background-color: #fff;
}
.add-user-location-inner-wrapper,
.add-profile-category-inner-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 45px;
}
.add-user-location button,
.add-profile-category-inner-wrapper button {
  font-size: 38px !important;
  color: #fff;
  font-weight: bold;
  background-color: rgb(92, 161, 39);
  border: none;
  border-radius: 4px;
}
.add-user-data-modal-main {
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  position: fixed;
  inset: 0;
  z-index: 5;
}
.add-user-data-modal-wrapper {
  padding: 45px 16px 16px 16px;
  position: relative;
  max-width: 625px;
  width: 96%;
  border-radius: 5px;
  background-color: #fff;
}

.add-user-data-modal-wrapper .save-button {
  margin-top: 12px;
  width: fit-content;
  display: block;
  margin-inline: auto;
}
.add-user-data-modal-wrapper .closebutton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #828282;
  padding: 0;
}
.add-user-name {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1300px) {
  .add-user-container > div {
    width: 75%;
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .add-user-container > div {
    width: 95%;
  }
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .add-user-office {
    flex-direction: column;
  }
  .add-user-office > div {
    width: 100%;
  }
  .add-user-form input {
    padding: 12.5px 10px;
  }
  .add-user-form label {
    top: -3px;
  }
}
@media screen and (max-width: 400px) {
}
