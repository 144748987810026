.mc-management-wrapper {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}
.mc-management-heading {
  text-align: center;
  color: #707070;
  font-family: var(--montserrat);
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
}
.mc-management-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  cursor: pointer;
  position: relative;
}
.mc-management-lower-tile {
  border: 1px solid #4671a4;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  cursor: pointer;
  height: 110px;
  width: 50%;
  margin: 20px auto;
}
.mc-management-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 30px;
  margin-top: 40px;
}
.mc-management-tile-internal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.mc-management-tile-internal-wrapper > div {
  border: 1px solid #4671a4;
  height: 100%;
  width: 47.5%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}
.mc-management-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.mc-management-tile > span {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.mc-management-tile span {
  color: #446fa2;
  font-weight: bold;
}
.mc-management-tile span b {
  font-size: 22px;
}
.mc-management-lower-tile > span {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.mc-management-lower-tile span {
  color: #446fa2;
  font-weight: bold;
}
.mc-management-lower-tile span b {
  font-size: 22px;
}
.mc-management-tile-internal-wrapper > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
}
.moneyCreditManagement-info {
  position: absolute;
  top: 3px;
  right: 5px;
  background: #446fa2;
  border-radius: 50%;
  color: #fff;
  font-size: 18px !important;
  cursor: pointer;
}
.moneyCredit-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.moneyCredit-management-tile {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1080px) {
  .mc-management-wrapper {
    margin-left: 15%;
  }
}

@media screen and (max-width: 780px) {
  .mc-management-tile img {
    width: 70px !important;
    height: 70px !important;
  }
  .mc-management-lower-tile img {
    width: 65px !important;
    height: 65px !important;
  }
  .mc-management-tile:nth-child(2) img {
    width: 45px !important;
    height: 45px !important;
  }
  .mc-management-tile:first-child img {
    width: 40px !important;
    height: 40px !important;
  }
  .mc-management-tile span {
    font-size: 12px;
  }
  .mc-management-lower-tile span {
    font-size: 12px;
  }
  .mc-management-tile {
    height: 90px !important;
  }
  .mc-management-lower-tile {
    height: 90px !important;
  }
  .mc-management-tile:first-child .moneyCreditManagement-info {
    width: 20px !important;
    height: 20px !important;
  }
  .mc-management-tile:nth-child(2) .moneyCreditManagement-info {
    width: 20px !important;
    height: 20px !important;
  }
  .mc-management-tile .moneyCreditManagement-info {
    width: 20px !important;
    height: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .mc-management-wrapper {
    margin-left: 5%;
    width: 90%;
    padding-bottom: 70px;
  }
  .mc-management-heading {
    margin-top: 20px;
  }
  .mc-management-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
  }
  .mc-management-content {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    justify-items: unset;
    transform: unset;
    width: 100%;
    margin-top: 25px;
  }
  .mc-management-tile span {
    font-size: 18px;
  }
  .mc-management-lower-tile span {
    font-size: 18px;
  }
  .mc-management-lower-tile {
    width: 100%;
    margin-top: 15px;
  }
}

@media screen and (max-width: 355px) {
  .mc-management-tile:nth-child(1) {
    height: max-content !important;
    padding: 10px 30px 10px 30px;
  }
}
