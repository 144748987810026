.request-transfer-out-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 5;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}
.request-transfer-out-parent * {
  font-family: var(--montserrat);
}
.request-transfer-out-form-body {
  width: 95%;
  max-width: 400px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.request-transfer-out-heading p {
  font-size: 20px;
  color: #51595e;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: solid 1px #e1e1e1;
}
.request-transfer-out-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 25px;
  gap: 15px;
}
.request-transfer-out-content input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  text-align: left;
}
::placeholder::first-letter {
  color: red;
  opacity: 1;
}
/* :-moz-placeholder {
  color: red;
  opacity: 1;
}
:-moz-placeholder:last-child {
  color: red;
  opacity: 1;
}
::-webkit-input-placeholder:last-child {
  color: red;
  opacity: 1;
}
:-ms-input-placeholder:last-child {
  color: red;
  opacity: 1;
} */
.request-transfer-out-content textarea {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  resize: none;
}
.request-transfer-out-content button {
  border: unset;
  border-radius: 5px;
  background: #5ca127;
  color: white;
  padding: 10px 50px;
  width: fit-content;
  cursor: pointer;
  align-self: center;
}
.request-transfer-out-content label {
  font-size: 14px;
  color: #51595e;
  font-weight: bold;
  margin-bottom: -10px;
}
.request-transfer-out-heading {
  position: relative;
}
.request-transfer-out-heading .rename-cross {
  top: 5%;
  right: 1%;
}
.request-transfer-out-credit-error {
  font-size: 14px;
  color: #ff0000;
  font-weight: normal;
  margin-top: -12px;
  padding: 0 5px;
}
.request-mandatory-filed {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  color: #51595e;
  font-size: 14px;
}
/* responsive  */

@media screen and (max-width: 320px) {
  .request-transfer-out-content {
    padding: 0 15px;
  }
}
