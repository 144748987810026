.mentions {
  /* margin: 1em 0; */
  margin: unset;
}
.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}
.mentions--multiLine .mentions__control {
  font-family: var(--lato);
  font-size: 12pt;
  height: 100%;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  /* padding: 9px; */
  padding: unset;
  /* min-height: 63px; */
  font-family: var(--lato);
  font-size: 14px !important;
  z-index: 0;
}
.mentions--multiLine .mentions__highlighter span {
  visibility: visible !important;
  color: #51595e;
  font-family: var(--lato);
  word-break: break-word;
}
.mentions--multiLine .mentions__input {
  /* padding: unset;
  font-family: var(--lato) !important;
  font-size: 14px !important;
  border: unset !important;
  pointer-events: none;
  outline: none; */
  display: none !important;
}
.example_mentions__control__Kol3a + div {
  z-index: 100 !important;
}
.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}
.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}
.mentions__mention {
  position: relative;
  z-index: 1;
  color: #bf0000;
  font-family: var(--lato);
  font-weight: bold !important;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  /* text-decoration: underline; */
  pointer-events: none;
}
