.notification-type {
  background: #5cbb55;
  color: white;
  font-family: var(--lato);
  font-weight: 500;
  padding: 3px 7.5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
}
.notification-card-info-icon {
  background-color: transparent;
  border: none;
  position: absolute;
  padding: 0;
  top: 2px;
  left: 2px;
  transition: opacity 250ms ease;
}
.notification-card-info-icon:hover {
  opacity: 0.75;
}
.notification-card-info-icon svg {
  color: #446fa2;
}

@media screen and (max-width: 370px) {
  .notification-type {
    max-width: 65%;
  }
}

.notification-body-top
{
  display: flex;
  flex-direction: column;
}