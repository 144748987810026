.verification-upper {
  height: 350px;
}
.verification-button {
  margin-top: 25px;
}
.verification-button button {
  background: #446fa2;
  font-family: var(--montserrat);
}
.verification-logo {
  background: #a3c1f5;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  height: 300px;
  position: relative;
}
.verification-logo img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 15%);
}
.verification-below {
  width: 32.5%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.verification-logo img {
  width: 250px;
}
.verification-message {
  color: #446fa2;
  margin-top: 15%;
  font-family: var(--montserrat);
  font-size: 24px;
  font-weight: bold;
}
/* responsive  */

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1300px) {
  .verification-below {
    width: 50%;
  }
  .verification-logo img {
    width: 200px;
  }
  .verification-logo {
    height: 240px;
  }
  .verification-upper {
    height: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .verification-logo img {
    width: 165px;
  }
  .verification-upper {
    height: 250px;
  }
  .verification-logo {
    height: 200px;
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
  }
}

@media screen and (max-width: 768px) {
  .verification-below {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .verification-below {
    width: 90%;
  }
  .verification-logo {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .verification-message {
    font-size: 20px;
  }
}
