.loader {
    border: 0 solid #E3ECFC; /* Light blue */
    border-top: 0 solid #446FA2; /* Blue */
    border-radius: 50%;
    animation: spin 1.3s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }