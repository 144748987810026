.stdetails-parent {
  position: relative;
  height: 100%;
}
.stdetails-parent * {
  font-family: var(--montserrat);
}
.stdetails-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.stdetails-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.stdetails-wrapper {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}
.stdetails-left .content .fields select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.stdetails-bottom {
  width: 100%;
  margin: 0 auto;
  display: flex;
  background: #5ca127;
  padding: 10px 22px;
  justify-content: space-between;
}
.stdetails-bottom > div {
  display: flex;
  color: white;
  align-items: center;
  border-left: solid 1px white;
  padding: 5px 15px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
}
.stdetails-bottom > div:first-child {
  border: unset;
}
/* .stdetails-bottom > div:hover {
} */
.stdetails-bottom > div img {
  width: 21px;
  height: 21px;
  margin-right: 7px;
  object-fit: contain;
}
.stdetails-bottom > div svg {
  margin-right: 7px;
}
.stdetails-bottom > div p {
  font-family: var(--lato);
  font-size: 12px;
  margin: unset;
}
.stdetails-bottom .hide-dropdown select {
  display: none;
}

/* left side */
.stdetails-left {
  width: 60%;
  background: white;
  margin-right: 50px;
  border-radius: 5px;
  height: fit-content;
  margin-bottom: 75px;
}
.stdetails-left .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7.5px 22px;
  border-bottom: solid 1px #e1e1e1;
}
.stdetails-left .heading p {
  color: #51595e;
  font-size: 18px;
  font-weight: bold;
}
.stdetails-left .heading span {
  color: #5ca127;
  font-size: 12px;
  font-weight: bold;
}
.stdetails-left .content {
  padding: 15px 22px;
}
.stdetails-left .content.with-chat {
  padding: 0 22px;
}
.stdetails-left .content.with-chat .chat-screen-chatsection {
  border: unset;
}

.stdetails-left .content .fields {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.stdetails-left .content .fields label {
  font-size: 14px;
  color: #51595e;
  font-weight: bold;
  flex-basis: 30%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.fields-additional-notes {
  width: 100%;
  position: relative;
}
.fields-additional-notes span {
  position: absolute;
  right: 3px;
  top: 1px;
  font-size: 12px;
}
.stdetails-left .content .fields input,
.stdetails-left .content .fields select {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  outline: none;
  background-color: #f8f8f8;
}
.stdetails-left .content .fields:first-child input {
  color: #446fa2;
  font-weight: bold;
}
.stdetails-left .content .fields .fields-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.stdetails-left .content .fields .fields-wrapper img {
  width: 45px;
  height: 34px;
  cursor: pointer;
}
.stdetails-left .content .fields .fields-wrapper img {
  margin-left: 15px;
}
.stdetails-left .content .fields textarea {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
  resize: none;
  outline: none;
  background-color: #f8f8f8;
}
textarea#additional-notes {
  background-color: unset;
  font-size: 14px;
  outline: none;
  font-style: italic;
  padding: 5px;
  padding-top: 10px;
}
.files-wrappper {
  width: 100%;
  max-height: 70px;
  overflow-y: scroll;
  cursor: pointer;
}
.files-wrappper > a {
  display: flex;
  font-size: 14px;
  color: #446fa2;
  font-family: var(--lato);
  margin-bottom: 5px;
  align-items: center;
  text-decoration: none;
}

.files-wrappper > a img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-right: 7px;
}

/* right side */
.stdetails-right {
  width: 35%;
}
.stdetails-right .content {
  max-width: 375px;
  border-top: solid 1px #e1e1e1;
}
.stdetails-right .content .heading p {
  margin-top: unset;
  background: #446fa2;
  width: fit-content;
  color: white;
  font-size: 14px;
  padding: 3px 20px;
  border-radius: 5px;
}
.stdetails-right .content .card {
  display: flex;
  width: 375px;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  background: white;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
}
.stdetails-right .content .card .card-logo img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: contain;
  background-color: white;
  border: 1px solid #ececec;
}
.stdetails-right .content .card .card-icon {
  display: flex;
  flex-direction: column;
}
.stdetails-right .content .card .card-icon button {
  width: 40px;
  height: 40px;
}
.stdetails-right .content .card .card-text * {
  font-size: 14px;
}
.stdetails-right .content .card .card-text h3 {
  font-size: 16px;
}
.stdetails-right .content .card .card-icon img {
  width: 22px;
}
.stdetails-right .email-popup-container {
  background: rgba(0, 0, 0, 0.1);
}

.stdetails-right .content .two-card {
  display: flex;
}
.stdetails-right .content .two-card > div {
  width: 140px;
  height: 110px;
  background: white;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.stdetails-right .content .two-card img {
  width: 54px;
  height: 54px;
  object-fit: contain;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #ececec;
}
.stdetails-right .content .two-card span {
  color: #51595e;
  font-size: 12px;
  font-weight: bold;
}
.stdetails-right .content .two-card svg {
  position: absolute;
  bottom: 0;
  right: -10px;
  font-size: 22.5px;
}
textarea {
  padding: 5px;
}
.stdetails-main .profile-url a {
  color: #446fa2;
  text-decoration: none;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
.stdetails-left .content.with-chat .chat-screen-input {
  display: unset;
  padding: unset;
  /* height: 50px; */
}
.stdetails-left .content.with-chat .chat-screen-inputsection {
  height: 75px;
  border-top: 1px solid #0000001a;
}
.stdetails-left .content.with-chat .chat-screen-send img {
  width: 15px;
  height: 15px;
}
.tickets-tag-list {
  position: absolute;
  bottom: 50px;
  background: #446fa2;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  max-height: 150px;
  overflow-y: scroll;
}
.tickets-tag-list-item {
  padding: 5px 20px;
  border: solid 1px white;
  cursor: pointer;
}
.tickets-tag-list-item:hover {
  background-color: gray;
}
/* additional notes */
.notes-content-area {
  width: 100%;
  max-height: 200px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-color: #f8f8f8;
  overflow-y: scroll;
  padding: 5px 10px;
}
.notes-content-area p.notes-head {
  margin: unset;
  padding: unset;
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.notes-content-area p.notes-content {
  margin: unset;
  padding-bottom: 15px;
  font-size: 13px;
  /* font-style: italic; */
}

#imageUpload {
  background-color: #fff;
  border: none;
  height: 20%;
  padding: 2px !important;
  margin-top: 2px;
}
.file-upload {
  display: flex;
  padding-bottom: 6px;
}
.file-upload-link {
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #446fa2;
}
.file-upload-image {
  width: 22px;
  padding-right: 4px;
}

.cancelIcon {
  color: #446fa2;
  padding-left: 2px;
}
.files-wrappper::-webkit-scrollbar {
  width: 2px;
}

.files-wrappper::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}

.files-wrappper::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 50px;
}
/* responsive  */

@media screen and (max-width: 1701px) {
  .left-side-chat ul.chat-thread.nested {
    margin-left: 50px;
  }
  .right-side-chat ul.chat-thread.nested {
    margin-right: 50px;
  }
  .stdetails-left ul.chat-thread.nested {
    width: fit-content;
  }
}

/* laptop */
@media screen and (max-width: 1600px) {
  .stdetails-main {
    width: 97%;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1400px) {
  .stdetails-left .content.with-chat .chat-screen-inputsection {
    width: 100%;
  }
  .stdetails-wrapper {
    flex-direction: column-reverse;
  }
  .stdetails-right,
  .stdetails-left {
    width: 100%;
  }
  .stdetails-right .fields {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .stdetails-right .fields::-webkit-scrollbar {
    height: 5px;
  }
  .stdetails-right .fields::-webkit-scrollbar-thumb {
    /* background: #446fa2; */
    background-color: gray;
  }
  .stdetails-right .fields .content {
    margin-top: unset !important;
    margin-left: 25px;
    border: unset;
  }
  /* .stdetails-right .fields .content:last-child {
    min-width: 180px;
  } */
  .stdetails-right .fields .content:first-child {
    margin-left: unset;
  }
  .stdetails-right .content .card,
  .stdetails-right .content .two-card > div {
    height: 120px;
  }
  .stdetails-right .content .heading p {
    width: 100%;
    text-align: center;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1024px) {
  .stdetails-main {
    width: 100%;
    display: flex;
    justify-items: flex-end;
  }
  .stdetails-wrapper {
    width: 85%;
    margin: unset;
    margin-right: 25px;
  }
  .stdetails-left ul.chat-thread.nested {
    width: unset !important;
  }
  .stdetails-right .content .two-card span {
    font-size: 10px;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .stdetails-left .content .fields label {
    white-space: unset;
  }
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .stdetails-wrapper {
    width: 87%;
    margin-right: unset;
  }
}
@media screen and (max-width: 600px) {
  .fields-additional-notes button {
    font-size: 10px;
  }
  .stdetails-left .content .fields {
    flex-direction: column;
    align-items: flex-start;
  }
  .stdetails-header {
    height: 65px;
  }
  .stdetails-main {
    width: 100%;
    justify-content: center;
    /* margin-top: 0; */
    overflow-y: scroll;
  }
  .stdetails-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .stdetails-wrapper {
    width: 98%;
    margin-right: unset;
    /* overflow-y: scroll; */
  }
  .stdetails-left .content.with-chat {
    padding: unset;
  }
  .stdetails-left .content.with-chat ul.chat-thread {
    padding: 0 5px;
  }
  .stdetails-left .chat-thread li {
    min-width: 33%;
  }
  .stdetails-left .chat-screen-chatsection {
    height: 360px !important;
  }
  .stdetails-left .content {
    padding: 15px 5px;
    /* overflow-y: scroll;
    height: 360px; */
  }
  .stdetails-left .content.with-chat {
    height: 415px;
  }
  .stdetails-bottom {
    padding: 5px;
  }
  .stdetails-bottom > div p {
    font-size: 10px;
  }
  .stdetails-left .content .fields label,
  .stdetails-left .content .fields input,
  .stdetails-left .content .fields select,
  .files-wrappper,
  .stdetails-left .content .fields textarea {
    font-size: 12px;
    padding-left: 10px;
  }
  .stdetails-left .heading {
    padding: 7.5px 5px;
  }
  .stdetails-bottom > div {
    padding: 5px 2px;
  }
  .stdetails-bottom > div img {
    width: 15px;
    height: 15px;
  }
  .stdetails-right .content .card {
    width: 300px;
  }
  ul.chat-thread.nested li {
    min-width: 25% !important;
    max-width: 50% !important;
  }
  .stdetails-left .chat-thread li {
    min-width: 60%;
    max-width: 70%;
  }
}
@media screen and (max-width: 376px) {
  .stdetails-left .heading {
    padding: 7.5px 5px;
  }
  .stdetails-left .heading p {
    margin: 15px 0;
  }
}
@media screen and (max-width: 330px) {
  .stdetails-bottom > div p {
    font-size: 8px;
  }
  .stdetails-bottom > div {
    padding: 5px 0px;
  }
}
