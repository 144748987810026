.appointment-schedule-container {
  width: 100%;
  background-color: white;
  border-radius: 5px;
}
.appointment-schedule-container span.rs__cell.rs__header {
  text-align: center;
}
.appointment-schedule-container p,
.appointment-schedule-container span,
.appointment-schedule-container label {
  overflow: unset;
}

/* hiding the option icons */
.rs__popper_actions div:last-child {
  display: none !important;
}

/* full width of event popup */
.css-v1j55f {
  max-width: 100% !important;
}

/* disabling tiles */
.css-16em9np .rs__cell > button,
.css-sp60bg .rs__cell > button,
.css-fpqwey .rs__cell > button {
  pointer-events: none;
}

/* font icons of time appointment */
span.rs__cell.rs__header span {
  font-size: 10px;
}

/* font size should be lower */
.css-v1j55f p {
  font-size: 12px;
  margin: 5px 0;
}
.rs__popper_actions + p {
  font-size: 18px;
}

/* some changes */
.css-v1j55f p > span {
  margin-left: 15px;
  display: block;
}
.css-v1j55f p > b {
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 5px;
}
.css-v1j55f span > svg {
  margin-right: 2.5px;
}
p.folder-div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
p.folder-div b {
  white-space: nowrap;
  margin-bottom: unset;
}
span.folder-detail {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: unset !important;
}
.folder-detail span span:last-child {
  font-style: italic;
  color: #a3c1f5;
}
.folder-detail button {
  padding: unset;
}

.rbc-agenda-event-cell {
  cursor: pointer;
}
