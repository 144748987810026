.appoint-card-wrapper {
  padding: 0 30px;
}
.appoint-meet-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.appoint-person * {
  margin: unset;
}
.appoint-person {
  color: var(--heading-color);
}
.appoint-time {
  margin-right: 20px;
  color: #647589;
  display: flex;
}
.appoint-tp-wrapper {
  display: flex;
}
.appoint-tp-wrapper * {
  font-family: var(--lato);
}
.appoint-profile {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.appoint-profile span {
  padding-left: 10px;
  color: #647589;
}
.app-duration {
  padding-top: 10px;
}
.appoint-profile img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.appoint-option button {
  padding: 2.5px;
  background-color: var(--secondary-color);
  border-radius: 5px;
  color: white;
}
.appoint-option button:hover {
  background-color: white;
  color: var(--secondary-color);
}
.appoint-card-wrapper > h4 {
  color: #446fa2;
  font-family: var(--montserrat);
  font-weight: bold;
}
.app-border {
  background: #446fa2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 2px;
  margin-right: 5px;
}
.appoint-person h4 {
  font-weight: bold;
}
