.i-frame-parent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.i-frame-button-container {
  width: 95%;
  max-width: 1200px;
  height: max-content;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  gap: 5px;
}
.i-frame-button-container button,
.i-frame-button-container a {
  all: unset;
  background-color: white;
  border: 2px solid #5ca127;
  color: #5ca127;
  font: normal normal 600 11px Montserrat;
  padding: 5px 22px;
  border-radius: 4px;
}
.i-frame-button-container button:hover,
.i-frame-button-container a:hover {
  cursor: pointer;
}
.i-frame-main {
  width: 95%;
  max-width: 1200px;
  aspect-ratio: 2/1;
}
