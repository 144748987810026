.edit-organization-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-organization-container > div {
  width: 55%;
  margin: 0 auto;
  height: fit-content;
  max-height: 80%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  padding: 25px 50px;
  overflow-y: scroll;
}
.edit-organization-head {
  font-family: var(--montserrat);
  font-weight: 700;
}
.edit-organization-top-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-organization-logo {
  display: flex;
  flex-direction: column;
}
.edit-organization-logo label {
  margin-bottom: 15px;
  margin-top: 10px;
}
button.form-button {
  margin-top: 25px;
}
.edit-organization-timezone {
  margin-top: 25px;
}
.edit-organization-timezone .timezone-container label {
  position: unset;
  background: white;
  color: #252525;
}
.edit-organization-timezone select.timezone-selector {
  background-color: white;
}
input#update-organization-logo {
  width: fit-content;
}

.edit-organization-address {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1300px) {
  .edit-organization-container > div {
    width: 75%;
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .edit-organization-container > div {
    width: 95%;
  }
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .edit-organization-form input {
    padding: 12.5px 10px;
    font-family: var(--montserrat);
    color: black;
  }
  .edit-organization-form label {
    top: -3px;
    font-family: var(--lato);
    color: black;
    font-size: 18px;
  }
  .edit-organization-timezone select.timezone-selector {
    padding: 7.5px;
  }
  .edit-organization-timezone {
    margin-top: unset;
  }
  .edit-organization-logo label {
    margin-bottom: unset;
  }
  select.timezone-selector {
    font-size: 12px;
  }
  .timezone-container {
    margin-top: unset;
  }
}

@media screen and (max-width: 500px) {
  .edit-organization-address {
    flex-direction: column;
    gap: 20px;
  }
  .edit-organization-address div {
    width: 100% !important;
  }
}
