.portfolio-parent {
  position: relative;
  height: 100%;
}
.portfolio-parent * {
  font-family: var(--montserrat);
}
.portfolio-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.portfolio-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}
.portfolio-label {
  width: 90%;
  max-width: 670px;
  white-space: nowrap;
  font: normal normal normal 15px/21px Lato;
  letter-spacing: 0px;
  text-transform: capitalize;
  padding: 5px 0;
  display: flex;
  gap: 7px;
  overflow: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.portfolio-label::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.portfolio-label button {
  all: unset;
  font-weight: bold;
  color: #51595e;
}
.portfolio-label button:last-child {
  font-weight: normal;
}
.portfolio-label button:not(:last-child):hover {
  cursor: pointer;
  color: #2ba5f2;
}
.portfolio-wrapper {
  width: 90%;
  max-width: 700px;
  padding: 15px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.portfolio-wrapper-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.portfolio-wrapper-header-left-side {
  display: flex;
  align-items: center;
  gap: 15px;
}
.portfolio-wrapper-header-left-side img {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  object-fit: contain;
}
.portfolio-wrapper-header-left-side span {
  font-size: 16px;
  letter-spacing: 0px;
  color: #51595e;
  text-transform: capitalize;
  font-weight: bold;
}
.loading-image {
  height: 68px;
  width: 68px;
  border-radius: 50%;
}
.loading-span {
  width: 150px;
  height: 28px;
}
.portfolio-wrapper-header-right-side {
  display: flex;
  align-items: center;
  gap: 20px;
}
.portfolio-wrapper-header-right-side button {
  font-size: 12px;
  width: 178px;
  height: 35px;
  box-shadow: none;
  border-radius: 5px;
}
.portfolio-bottom-wrapper {
  width: 90%;
  height: 500px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  padding: 0;
  border-radius: 0px 0px 5px 5px;
}
.portfolio-content {
  height: 90%;
  width: 100%;
  display: grid;
  align-content: flex-start;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 5px 0;
  padding: 15px;
  gap: 10px;
  overflow-y: scroll;
}
.portfolio-no-data {
  text-align: center;
  font-size: 18px;
  width: 200%;
}
.portfolio-assign-workbook-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.portfolio-assign-workbook-content header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}
.portfolio-assign-workbook-content button {
  width: 90px;
  height: 30px;
  font-size: 11px;
  font-weight: bold;
  font-family: Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  gap: 5px;
  border-radius: 5px;
}
.portfolio-assign-workbook-content section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.portfolio-assign-workbook-content-search-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.portfolio-assign-workbook-content-search-section input {
  width: 70%;
  max-width: 470px;
  height: 40px;
  padding: 0 10px;
  font: italic normal normal 15px Lato;
  letter-spacing: 0px;
  color: #51595e;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
}
.portfolio-assign-workbook-content button:is([disabled]) {
  background-color: #e6e6e6 !important;
  color: #929292 !important;
}
.portfolio-assign-workbook-content-table {
  width: 100%;
}
.portfolio-assign-workbook-content-table-row-wrapper {
  display: flex;
  background: #446fa2;
  color: white;
}
.portfolio-assign-workbook-content-table-row-wrapper
  .portfolio-assign-workbook-content-table-head-row {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  border-right: solid 0.5px #e1e1e1;
  border-left: solid 0.5px #e1e1e1;
  padding: 2.5px 0;
}
.portfolio-assign-workbook-content-table-bottom {
  overflow-y: scroll;
  height: 350px;
}
.portfolio-assign-workbook-content-table-msg {
  text-align: center;
  margin-top: 15px;
}
.portfolio-assign-workbook-table-content-wrapper {
  display: flex;
}
.portfolio-assign-workbook-table-content-wrapper
  .portfolio-assign-workbook-table-content-row {
  font-size: 14px;
  border-bottom: solid 1px #e1e1e1;
  padding: 10px 7px;
  color: #51595e;
  font-family: var(--lato);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  /* overflow: hidden; */
  text-align: center;
}
.portfolio-assign-workbook-table-content-row button {
  all: unset;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.portfolio-content-item {
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: max-content;
  border: 1.5px solid #4671a4;
  border-radius: 5px;
  padding: 0 6px;
  min-height: 82px;
}
.disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.portfolio-content-item div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.portfolio-content-item:hover {
  cursor: pointer;
}
.portfolio-content-item img {
  height: 80px;
  width: 80px;
}
.portfolio-file-image {
  height: 65px !important;
  width: 65px !important;
  margin: 0 7.5px;
  object-fit: contain;
}
.loading-content-image {
  height: 50px;
  width: 67px;
  margin-left: 6px;
}
.portfolio-content-item span {
  font: normal normal bold 16px Montserrat;
  letter-spacing: 0px;
  color: #446fa2;
}
.portfolio-dropdown {
  flex-direction: column;
  background-color: white;
  width: 125px;
  position: absolute;
  right: 10px;
  top: 35px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  z-index: 1;
}
.portfolio-divider {
  width: 100%;
  height: 2px;
  margin: -10px;
  background-color: #00000029;
}
.portfolio-dropdown button {
  all: unset;
  width: 83%;
  font: normal normal normal 13px Lato;
  letter-spacing: 0px;
  color: #51595e;
  padding: 5px 10px;
}
.portfolio-dropdown button:hover {
  font-weight: bold;
}
.loading-content-button {
  height: 20px;
  width: 5px;
  margin-right: 18px;
}
.portfolio-external-icon {
  height: 23px;
  width: 23px;
  position: absolute;
  border-radius: 50%;
  margin-top: 50px;
  background-color: white;
  justify-content: center;
}
.portfolio-lock-icon {
  position: absolute;
  background-color: #4671a4;
  border-radius: 50%;
  padding: 2px;
  top: -10px;
  right: 10px;
}
.portfolio-actions {
  background-color: #5ca127;
  height: 56px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  border-radius: 0px 0px 5px 5px;
  align-items: center;
}
.portfolio-actions button {
  all: unset;
  border-width: 0 2px 0 0;
  border-style: solid;
  border-color: white;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  gap: 9px;
}
.portfolio-actions-other-org {
  display: flex;
  justify-content: center;
  background-color: #5ca127;
  height: 56px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  align-items: center;
}
.portfolio-actions-other-org button {
  all: unset;
  border-width: 0 2px 0 2px;
  border-style: solid;
  border-color: white;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  gap: 10px;
}
.portfolio-actions-other-org button:hover {
  cursor: pointer;
}
.portfolio-more-action-buttons {
  position: absolute;
  bottom: calc(100% + 7px);
  display: flex;
  flex-direction: column;
  background-color: #5ca127;
  width: 100%;
}
.portfolio-more-action-buttons button {
  border-width: 2px 0 0 0;
  padding: 7px 0 0 0;
}
.portfolio-more-action-buttons {
  border-radius: 5px 5px 0 0;
}
.portfolio-more-action-buttons button:first-child {
  border-width: 0;
}
.portfolio-actions button:last-child {
  border-right-width: 0;
}
.portfolio-actions button:hover {
  cursor: pointer;
}
.portfolio-actions p {
  font: normal normal bold 11px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  padding: 0 1px;
}
.animation {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  animation: 1.5s shine linear infinite;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 980px) {
  .portfolio-main {
    margin-left: 3%;
  }
}

@media screen and (max-width: 880px) {
  .portfolio-main {
    margin-left: 5%;
  }
}
@media screen and (max-width: 835px) {
  .portfolio-main {
    width: 90%;
    margin-left: 10%;
    padding-bottom: 30px;
  }
  .portfolio-wrapper-header-right-side {
    flex-direction: column;
  }
  .portfolio-content {
    grid-template-columns: repeat(1, 1fr);
  }
  .portfolio-actions button {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    gap: 0;
  }
  .portfolio-actions img {
    height: 17px !important;
    width: 17px !important;
  }
  .portfolio-actions p {
    font-size: 9px;
  }
  .portfolio-assign-workbook-content button {
    width: 85px;
    font-size: 10px;
  }
  .portfolio-assign-workbook-content-table-row-wrapper
    .portfolio-assign-workbook-content-table-head-row {
    font-size: 10px;
  }
  .portfolio-assign-workbook-table-content-wrapper
    .portfolio-assign-workbook-table-content-row {
    font-size: 13px;
  }
}

@media screen and (max-width: 700px) {
  .portfolio-wrapper-header {
    flex-direction: column;
    gap: 15px;
  }
  .portfolio-wrapper-header-right-side {
    flex-direction: row;
    gap: 30px;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .portfolio-wrapper-header {
    flex-direction: row;
    gap: 0px;
  }
  .portfolio-wrapper-header-right-side {
    flex-direction: column;
    gap: 20px;
  }
  .portfolio-main {
    width: 100%;
    margin-left: 0;
    padding-bottom: 65px;
  }
  .portfolio-no-data {
    width: 100%;
  }
  .portfolio-assign-workbook-content button {
    width: 80px;
    font-size: 9px;
  }
  .portfolio-assign-workbook-content-table-row-wrapper
    .portfolio-assign-workbook-content-table-head-row {
    font-size: 9px;
  }
  .portfolio-assign-workbook-table-content-wrapper
    .portfolio-assign-workbook-table-content-row {
    font-size: 12px;
  }
}
@media screen and (max-width: 540px) {
  .portfolio-wrapper {
    width: 95%;
  }
  .portfolio-label {
    width: 95%;
  }
  .portfolio-bottom-wrapper {
    width: 95%;
  }
  .portfolio-wrapper-header {
    flex-direction: column;
    gap: 15px;
  }
  .portfolio-wrapper-header-right-side {
    flex-direction: row;
    gap: 30px;
    justify-content: center;
  }
  .portfolio-wrapper-header-right-side button {
    font-size: 10px;
    width: 160px;
  }
  .portfolio-assign-workbook-table-content-wrapper
    .portfolio-assign-workbook-table-content-row {
    font-size: 11px;
  }
}
@media screen and (max-width: 450px) {
  .portfolio-wrapper-header-right-side {
    gap: 20px;
  }
}
@media screen and (max-width: 370px) {
  .portfolio-wrapper-header-right-side button {
    font-size: 9px;
    width: 145px;
  }
}
