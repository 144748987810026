/* outline overlap issue  */
form.account-form label {
  background: whitesmoke;
}
/* outline overlap issue  */

form.account-form p.error {
  margin: unset;
  font-size: 12px;
  color: #d32f2f;
  margin-top: 5px;
}
.time-zone-wrapper {
  display: flex;
  background: #f5f5f5;
  border: solid 2px #d1d1d1;
  border-radius: 5px;
  position: relative;
}
.time-zone-wrapper:focus {
  outline-color: #4671a4;
}
.time-zone {
  background: #f5f5f5;
  outline: unset;
  border: unset;
  width: 100%;
}
span.time-zone-span {
  position: absolute;
  font-size: 14px;
  top: -10px;
  left: 15px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.6);
}
.create-lower {
  font-size: 24px;
}
.account-form label {
  font-family: var(--montserrat);
  font-size: 20px;
}
.account-form {
  width: 50%;
  max-width: 800px;
  margin: 0 auto;
}
.account-form h6 {
  margin: unset;
  margin-bottom: 15px;
  margin-top: 20px;
  text-align: left;
  text-transform: uppercase;
  font-family: var(--montserrat);
}
.org-flex {
  display: flex;
  flex-direction: column;
}
.org-flex input {
  padding: 15px 5px;
}
.create-logo {
  background: #a3c1f5;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
}
.create-header {
  display: flex;
  align-items: flex-end;
  color: #4671a4;
  text-transform: uppercase;
  width: fit-content;
  height: 75px;
}
.create-header h2 {
  font-size: 30px;
  font-family: var(--montserrat);
}
.account-form svg,
.account-form div {
  color: black;
}
.account-form svg {
  font-size: 30px;
}
.create-img img {
  position: absolute;
  left: 17.5%;
  top: 10%;
  width: 175px;
}
.create-help {
  position: absolute;
  top: 1%;
  z-index: 5;
  right: 1%;
}
.create-help button svg {
  color: #446fa2;
  font-size: 50px;
}
.create-btn button {
  padding: 15px 0;
  font-family: var(--montserrat);
}
.create-btn {
  margin-bottom: 50px;
}
.admin-offic-phone {
  display: flex;
  justify-content: space-between;
}
.admin-offic-phone > div:first-child {
  width: 74%;
}
.admin-offic-phone > div:last-child {
  width: 24%;
}
.timezone-container label {
  font-size: 16px;
}
/* responsive  */

@media screen and (max-width: 1600px) {
  .create-help button svg {
    font-size: 40px;
  }
  .create-img img {
    left: 15%;
    top: 22.5%;
    width: 150px;
  }
  .org-flex input {
    padding: 12.5px 5px;
  }
  .account-form svg {
    font-size: 25px;
  }
  .create-btn button {
    padding: 10px 0;
  }
}

@media screen and (max-width: 1300px) {
  .account-form label {
    font-size: 18px;
  }
  .account-form {
    width: 55%;
  }
  .create-logo {
    height: 165px;
    margin-bottom: 35px;
  }
  .create-img img {
    left: 12.5%;
    width: 125px;
  }
  .create-header h2 {
    font-size: 25px;
  }
  .create-lower {
    font-size: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .create-img img {
    left: 10.5%;
    width: 115px;
    top: 27.5%;
  }
  .account-form {
    width: 65%;
  }
  .org-flex input {
    padding: 10px 5px;
  }
  .account-form svg {
    font-size: 20px;
  }
  .account-form label {
    font-size: 16px;
  }
  .create-lower {
    font-size: 20px;
  }
  span.time-zone-span {
    font-size: 11px;
  }
  .timezone-container label {
    font-size: 12px;
  }
}

@media screen and (max-width: 830px) {
  .create-img img {
    left: 7.5%;
  }
}

@media screen and (max-width: 768px) {
  .create-logo {
    height: 145px;
    margin-bottom: 25px;
  }
  .create-img img {
    width: 100px;
  }
  .account-form {
    width: 75%;
  }
}

@media screen and (max-width: 720px) {
  .create-img img {
    left: 5.5%;
  }
}

@media screen and (max-width: 600px) {
  .create-img img {
    width: 75px;
    top: 45%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .create-header h2 {
    font-size: 18px;
  }
  .create-logo {
    border-bottom-left-radius: 75px;
    border-bottom-right-radius: 75px;
  }
  .account-form {
    width: 90%;
  }
  .create-help button svg {
    font-size: 25px;
  }
}

@media screen and (max-width: 300px) {
  .create-header h2 {
    font-size: 14px;
  }
}
