form#stripe-payment-form button {
  background-color: var(--accent-color);
}

form#stripe-payment-form button {
  background: var(--accent-color);
  border-radius: 5px;
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
form#stripe-payment-form button:hover {
  filter: contrast(115%);
}
form#stripe-payment-form button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
form#stripe-payment-form button:disabled {
  opacity: 0.5;
  cursor: none;
}
.stripe-credit-wrapper {
  width: 55%;
  margin: 0 auto;
  margin-top: 75px;
  background: white;
  text-align: center;
  border-radius: 5px;
  padding: 0 100px;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}
form#stripe-payment-form {
  padding-top: 25px;
  padding-bottom: 50px;
}
.stripe-credit-wrapper h1 {
  padding-top: 50px;
}

@media screen and (max-width: 950px) {
  .stripe-credit-wrapper {
    padding: 0 35px;
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .stripe-credit-wrapper {
    padding: 0 35px;
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .stripe-credit-wrapper {
    padding: 0 35px;
    width: 97%;
  }
}
